<template>
  <div :class="className" class="p-0 pointer mt-1 ">
    <div class=" rounded-right   " data-aos="fade-right">
      <!-- <img class="productimg" style="border-top-right-radius:5px !important; border-top-left-radius:5px !important;" :src="product.url == null || product.url == 'null' ? '../images/img2.png' : product.url" alt=""> -->
      <div class=" bg-white card-size card_prod">
        <div class="py-2 px-1" style="">
          <!-- <p class="trn" style="font-size:11px;white-space: normal !important;line-height:1.1;"><b>{{'#'+product.id + '-'+ product.nombre.toUpperCase().substring(0,22) }}</b></p> -->
          <span class="trn text-center py-5 text-black" style="font-size:14px;white-space: normal !important;line-height:1.1;"
            ><b class="">{{ product.nombre.toUpperCase() }}</b></span
          >
          <!-- <span v-if="product.descripcion" style="white-space: normal !important;"><b>{{product.descripcion}}</b></span><br> -->
        </div>
        <!-- <div class="text-left">
          <span v-if="product.precio_venta" class="mt-2 h5 text-primary" style="white-space: normal !important;"
            ><b>${{ product.precio_venta.toFixed(2) }}</b></span
          ><br />
          <span v-if="product.existencia" class="mt-2" style="white-space: normal !important;"
            ><b>Stock: {{ product.existencia }}</b></span>
        </div> -->
        <div class="d-flex ">
          <div class="text-left pb-0 " >
            <span v-if="product.precio_venta" class="mt-2 bg-primary text-white p-1" style="white-space: normal !important;">
            <b class="text-left">${{ product.precio_venta.toFixed(2) }}</b></span>
          </div>
          <!-- <div class="text-right pb-0 " >
            <span v-if="product.precio_venta" class="mt-2 bg-primary text-white p-1" style="white-space: normal !important;">
            <b class="text-left">{{ product.existencia }}</b></span>
          </div> -->
        </div>
        
          <!-- <br /> -->
      </div>
    </div>

    <div class="movil px-0">
      <div class="row">
        <div class="col-12 ">
          <div class="">
            <p class="trn text-center bg-rgb-primary py-2 mb-0">
              <b>{{  product.nombre.toUpperCase().substring(0, 22) }}</b>
            </p>
            <div class="">
            <div class="text-center">
              <span v-if="product.precio_venta" class="mt-2 h3 text-primary"
                ><b>${{ product.precio_venta.toFixed(2) }}</b></span
                ><br />
                <span v-if="product.existencia" class="mt-2">
                  <!-- <b>Stock: {{ product.existencia }}</b> -->
                </span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-6">
          <img class="productimg" :src="product.url == null || product.url == 'null' ? '../images/img2.png' : product.url" alt="" />
        </div> -->
        
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["product", "theme", "type"],
  data: () => ({
    className: "product",
    user: null,
  }),
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user")) || false; //this.$store.state.user!=null ? true : false;
    if (this.theme) {
      this.className = this.theme;
    }
  },
  methods: {
    goto(rutaWeb) {
      console.log("goto ruta: ", rutaWeb);
      this.$router.push(rutaWeb);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: rgba(255, 0, 119, 1);
.hover-dark{

}
.hover-dark:hover{
  background-color: rgb(173, 0, 0);
}
.trn {
  position: relative;
  line-height: 18px;
  // height: 36px;
  overflow: hidden;
  
}
// .trn::after {
//   font-weight: bold;
//   position: absolute;
//   bottom: 0;
//   right: 0;
//   // padding: 0 20px 1px 45px;
// }
.shadow_card {
  background: transparent;
  box-shadow: 0 0.185rem 0.3rem rgba(0, 0, 0, 0.452) !important;
}

h4 {
  color: $primary;
}

.product-alt {
  background: transparent;
  border: 1px solid $primary;
  border-radius: 20px;
  margin: 1rem;
  padding: 1rem;
  color: #fff;
    border-color: blue;

}
.productimg {
  height: 5rem;
  width: 100%;
  display: block;
  //   margin-left:auto;
  //  margin-right: auto;
  object-fit: cover;
  // border-radius: 15px; padding:10px
}

@media screen and (max-width: 750px) {
  .productimg {
    height: 10rem;
    
    display: block;
    //   margin-left:auto;
    //  margin-right: auto;
    object-fit: cover;
    // border-radius: 15px; padding:10px
  }
  .card-div-prod {
    height: auto !important;
   
  }
}
.card-div-prod {
  // height: 11vw !important;
  
  
}

.card_prod {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 0px !important;
  text-align: center;
  margin: 1px !important;
  width: 11vw;
  
 
 
}
// .card-size{
//   height: 70px;
// }
.card_prod:hover {
  background-color:rgb(248, 248, 248) !important;
}
</style>
