<template>
  <div class="modal-body bg-gray">
    <b-modal id="modalClientes" tabindex="-1" class="modal-lg" centered title="Seleccionar cliente" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form>
          <div class="row">
            <div class="col-md-12 mb-2">
              <b-table hover responsive :items="listaClientes" :fields="fieldsCustomer">
                <template #cell(action)="data">
                  <span
                    ><button @click.prevent="selCustomer(data.item)" class="btn btn-primary ml-1"><i class="fa fa-check"></i></button>
                  </span>
                </template>
              </b-table>
            </div>
          </div>
          <div class="text-right">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('modalClientes')">Cancelar</b-button>
          </div>
        </form>
      </div>
    </b-modal>

    <div class="full">
      <form @submit.prevent="saveData">
        <div class="row">
          <div class="col-8 text-left">
            <div class="row border py-3">
              <div class="col-md-6">
                <label for="categoria" class="">Fecha de la Venta</label>
                <b-form-input required v-model="row.fecha_i" type="date"></b-form-input>
              </div>
              <div class="col-md-6">
                <label for="categoria" class="">Cliente</label>
                <b-input-group>
                  <b-form-input required v-model="nombreCliente" placeholder="Cliente..." type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button @click.prevent="gotoClientes" class="btn btn-primary" variant="outline-secondary"><i class="fa fa-search" aria-hidden="true"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <form @submit.prevent="addProductToCart">
                <div class="row">
                  <div class="col-6 mt-3">
                    <label for="categoria" class="">Descripción</label>
                    <b-form-input required v-model="dataTmp.descripcion" placeholder="" type="text"></b-form-input>
                  </div>
                  <div class="col-3 mt-3">
                    <label for="categoria" class="">Cantidad</label>
                    <b-form-input required v-model="dataTmp.cantidad" placeholder="" type="number"></b-form-input>
                  </div>
                  <div class="col-3 mt-3">
                    <label for="categoria" class="">Precio</label>
                    <b-form-input required v-model="dataTmp.precio" step="0.01" placeholder="" type="number"></b-form-input>
                  </div>
                  <div class="pt-4 pb-2 text-center">
                    <b-button type="submit" class="btn btn-primary w-50">Agregar Item</b-button>
                  </div>
                </div>
              </form>
            </div>

            <div class="row">
              <div class="col-12 p-0 mt-3">
                <div class="card p-4 mb-4">
                  <b-table hover responsive :items="cart" :fields="fields2">
                    <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
                    <template #cell(precio)="data">
                      <span>{{ config.moneda + data.item.precio }}</span>
                    </template>

                    <template #cell(action)="data">
                      <span
                        ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1"><i class="fa fa-trash-o"></i></button>
                      </span>
                    </template>

                    <template #row-details="row">
                      <b-card>
                        <ul>
                          <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                        </ul>
                      </b-card>
                    </template>
                  </b-table>
                  <!-- <div>
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" hide-goto-end-buttons hide-ellipsis prev-text="Prev" next-text="Next" align="right"></b-pagination>
                  </div> -->
                </div>
              </div>
            </div>
          </div>

          <!-- panel derecho -->
          <div class="">
            <div class="col-sm-12 col-md-4 pr-0 span6 cel_big pull-right text-center">
              <div class="col-md-12 mb-2">
                <label for="estado" class="">Mesa/puesto</label>
                <b-form-select required class="form-control select  " v-model="row.puesto_id" :options="listaPuestos" value-field="id" text-field="nombre">
                </b-form-select>
              </div>
              <div class="bg-primary py-1">
                <h4 class="mt-1 text-light">Resumen</h4>
              </div>
              <div class="border">
                <!-- <b-list-group>
                  <div v-for="it of cart" :key="it.id" button>
                    <div class=" px-3  hover">
                      <div class="border-top border-bottom row py-3 hover">
                        <div @click="deleteProduct(it)" class="col-1 text-right pointer"><i class="fa fa-trash-o text-red" /></div>
                        <div class="col-7 text-left">{{ it.nombre }}</div>
                        <div class="col-2 text-left">${{ (it.precio_venta * it.cantidad).toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </b-list-group> -->
                <div class="row mt-3 px-4">
                  <div class="col-md-12 mb-2 mt-2 text-left">
                    <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0">
                      <span for="observaciones" class="h6">Iva</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 text-left mb-1"><span>sub-total:</span></div>
                  <div class="col-6 text-left mb-1">{{ config.moneda + subtotal.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1">iva:</div>
                  <div class="col-6 text-left mb-1">{{ config.moneda + iva.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px"><b>TOTAL:</b></div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px">
                    <b>{{ config.moneda + total.toFixed(2) }}</b>
                  </div>
                  <hr class="hr" />
                </div>
                <div class="row">
                  <div class="col-12 text-left mt-0">
                    <label for="categoria" class="">Cuenta origen</label>
                    <b-form-select required class="form-control select  " v-model="cuentaOrigen" :options="accountsList" value-field="cuenta" text-field="titulo">
                    </b-form-select>
                  </div>
                  <div class="col-12 text-left mt-2">
                    <label for="categoria" class="">Cuenta destino</label>
                    <b-form-select required class="form-control select  " v-model="cuentaDestino" :options="accountsList" value-field="cuenta" text-field="titulo">
                    </b-form-select>
                  </div>
                </div>
                <label class="text-left">Selecciona el método de pago</label>
                <div class="row px-4">
                  <div class="col-6 px-1 mt-2">
                    <div :class="buttons == 1 ? 'sqare_check_MPago p-3 text-center pointer' : 'sqare_1_MPago p-3 text-center pointer'" @click="boton(1)">
                      <div v-if="buttons == 1" style="position: absolute" class="row">
                        <div class="col-12 my-0 py-0 h4"><i class="fa fa-check text-MPago-check" aria-hidden="true"></i></div>
                      </div>
                      <img class="mx-auto d-block" style="width: 30%" src="images/banco.png" alt="" />
                      <span :class="buttons == 1 ? 'text-MPago-check mt-3 bold' : 'text-MPago mt-3'">Transferencia</span>
                    </div>
                  </div>
                  <div class="col-6 px-1 mt-2">
                    <div :class="buttons == 2 ? 'sqare_check_MPago p-3 text-center pointer' : 'sqare_1_MPago p-3 text-center pointer'" @click="boton(2)">
                      <div v-if="buttons == 2" style="position: absolute" class="row">
                        <div class="col-3 h4"><i class="fa fa-check text-MPago-check" aria-hidden="true"></i></div>
                      </div>
                      <img class="mx-auto d-block" style="width: 30%" src="/images/efectivo.png" alt="" />
                      <span :class="buttons == 2 ? 'text-MPago-check mt-3 bold' : 'text-MPago mt-3'">Efectivo</span>
                    </div>
                  </div>
                  <div class="col-6 px-1 mt-2">
                    <div :class="buttons == 3 ? 'sqare_check_MPago p-3 text-center pointer' : 'sqare_1_MPago p-3 text-center pointer'" @click="boton(3)">
                      <div v-if="buttons == 3" style="position: absolute" class="row">
                        <div class="col-3 h4"><i class="fa fa-check text-MPago-check" aria-hidden="true"></i></div>
                      </div>
                      <img class="mx-auto d-block" style="width: 30%" src="/images/tajeta.png" alt="" />
                      <span :class="buttons == 3 ? 'text-MPago-check mt-3 bold' : 'text-MPago mt-3'">Tarjeta</span>
                    </div>
                  </div>
                  <div class="col-6 px-1 mt-2">
                    <div :class="buttons == 4 ? 'sqare_check_MPago p-3 text-center pointer' : 'sqare_1_MPago p-3 text-center pointer'" @click="boton(4)">
                      <div v-if="buttons == 4" style="position: absolute" class="row">
                        <div class="col-3 h4"><i class="fa fa-check text-MPago-check" aria-hidden="true"></i></div>
                      </div>
                      <img class="mx-auto d-block" style="width: 30%" src="/images/otros.png" alt="" />
                      <span :class="buttons == 4 ? 'text-MPago-check mt-3 bold' : 'text-MPago mt-3'">otros</span>
                    </div>
                  </div>
                </div>
                <hr class="hr mt-4" />
                <div class="row my-3 px-3">
                  <div class="col-6">
                    <b-button @click="cleanSale()" class="btn btn-dark w-100">Cancelar</b-button>
                  </div>
                  <div class="col-6">
                    <b-button type="submit" class="btn btn-primary w-100">Guardar</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="movil mb-5">
      <form>
        <div class="row">
          <div class="col-12 text-left">
            <div class="row border py-3">
              <div class="col-md-6">
                <label for="categoria" class="">Fecha de la Venta</label>
                <b-form-input type="date"></b-form-input>
              </div>
              <div class="col-md-6">
                <label for="categoria" class="">Cliente</label>
                <b-input-group>
                  <b-form-input placeholder="Cliente..." type="text"></b-form-input>
                  <b-input-group-append>
                    <b-button class="btn btn-primary" variant="outline-secondary"><i class="fa fa-search" aria-hidden="true"></i></b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div class="col-md-6 mt-3">
                <label for="categoria" class="">Descripción</label>
                <b-form-input placeholder="Descripción..." type="text"></b-form-input>
              </div>
              <div class="col-md-3 mt-3">
                <label for="categoria" class="">Cantidad</label>
                <b-form-input placeholder="Cantidad..." type="text"></b-form-input>
              </div>
              <div class="col-md-3 mt-3">
                <label for="categoria" class="">Precio</label>
                <b-form-input placeholder="Precio..." type="text"></b-form-input>
              </div>
              <div class="pt-4 pb-2 text-center">
                <b-button class="btn btn-primary w-50">Agregar Item</b-button>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-4 mt-2 px-0 span6 cel_big pull-right text-center mb-5">
            <div class="ml-1">
              <div class="col-md-12 mb-2">
                <label for="estado" class="">Mesa/puesto</label>
                <b-form-select required class="form-control select  " v-model="row.puesto_id" :options="listaPuestos" value-field="id" text-field="nombre">
                </b-form-select>
              </div>
              <div class="bg-primary py-1">
                <h4 class="mt-1 text-light">Resumen</h4>
              </div>
              <div class="border">
                <b-list-group>
                  <div v-for="it of cart" :key="it.id" button>
                    <div class="px-3 hover">
                      <div class="border-top border-bottom row py-3 hover">
                        <div @click="deleteProduct(it)" class="col-1 text-right"><i class="fa fa-trash-o text-red" /></div>
                        <div class="col-6 text-left">{{ it.nombre + "/$" + it.precio_venta }}</div>
                        <div class="col-1">{{ it.cantidad }}</div>
                        <div class="col-2 text-left">${{ (it.precio_venta * it.cantidad).toFixed(2) }}</div>
                      </div>
                    </div>
                  </div>
                </b-list-group>
                <div class="row mt-3 px-3">
                  <div class="col-md-12 mb-2 mt-2 text-left">
                    <b-form-checkbox @change="calculateTotal()" size="lg" id="checkbox-1" v-model="con_iva" name="checkbox-1" value="1" unchecked-value="0">
                      <span for="observaciones" class="h6">Iva</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-6 text-left mb-1"><span>sub-total:</span></div>
                  <div class="col-6 text-left mb-1">${{ subtotal.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1">iva:</div>
                  <div class="col-6 text-left mb-1">${{ iva.toFixed(2) }}</div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px"><b>TOTAL:</b></div>
                  <div class="col-6 text-left mb-1" style="font-size: 16px">
                    <b>${{ total.toFixed(2) }}</b>
                  </div>
                  <hr class="hr" />
                </div>
                <div class="text-left ml-3">
                  <label>Selecciona el método de pago</label>
                </div>
                <div class="row px-4">
                  <div class="col-6 px-1 mt-2">
                    <div :class="buttons == 1 ? 'sqare_check_MPago p-3 text-center pointer' : 'sqare_1_MPago p-3 text-center pointer'" @click="boton(1)">
                      <div v-if="buttons == 1" style="position: absolute" class="row">
                        <div class="col-12 my-0 py-0 h4"><i class="fa fa-check text-MPago-check" aria-hidden="true"></i></div>
                      </div>
                      <img class="mx-auto d-block" style="width: 30%" src="images/banco.png" alt="" />
                      <h6 :class="buttons == 1 ? 'text-MPago-check mt-3 bold' : 'text-MPago mt-3'">Transferencia</h6>
                    </div>
                  </div>
                  <div class="col-6 px-1 mt-2">
                    <div :class="buttons == 2 ? 'sqare_check_MPago p-3 text-center pointer' : 'sqare_1_MPago p-3 text-center pointer'" @click="boton(2)">
                      <div v-if="buttons == 2" style="position: absolute" class="row">
                        <div class="col-3 h4"><i class="fa fa-check text-MPago-check" aria-hidden="true"></i></div>
                      </div>
                      <img class="mx-auto d-block" style="width: 30%" src="/images/efectivo.png" alt="" />
                      <h6 :class="buttons == 2 ? 'text-MPago-check mt-3 bold' : 'text-MPago mt-3'">Efectivo</h6>
                    </div>
                  </div>
                  <div class="col-6 px-1 mt-2">
                    <div :class="buttons == 3 ? 'sqare_check_MPago p-3 text-center pointer' : 'sqare_1_MPago p-3 text-center pointer'" @click="boton(3)">
                      <div v-if="buttons == 3" style="position: absolute" class="row">
                        <div class="col-3 h4"><i class="fa fa-check text-MPago-check" aria-hidden="true"></i></div>
                      </div>
                      <img class="mx-auto d-block" style="width: 30%" src="/images/tajeta.png" alt="" />
                      <h6 :class="buttons == 3 ? 'text-MPago-check mt-3 bold' : 'text-MPago mt-3'">Tarjeta</h6>
                    </div>
                  </div>
                  <div class="col-6 px-1 mt-2">
                    <div :class="buttons == 4 ? 'sqare_check_MPago p-3 text-center pointer' : 'sqare_1_MPago p-3 text-center pointer'" @click="boton(4)">
                      <div v-if="buttons == 4" style="position: absolute" class="row">
                        <div class="col-3 h4"><i class="fa fa-check text-MPago-check" aria-hidden="true"></i></div>
                      </div>
                      <img class="mx-auto d-block" style="width: 30%" src="/images/otros.png" alt="" />
                      <h6 :class="buttons == 4 ? 'text-MPago-check mt-3 bold' : 'text-MPago mt-3'">otros</h6>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 mb-5 px-3">
                  <div class="col-6">
                    <b-button @click="cleanSale()" class="btn btn-dark w-100">Cancelar</b-button>
                  </div>
                  <div class="col-6">
                    <b-button @click="saveData()" class="btn btn-primary w-100">Guardar</b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
// import ProductVue from "./Product.vue";
// import CategoriesVue from "./Category.vue";

export default {
  components: {},
  data() {
    return {
      buttons: 0,
      fields2: [
        { key: "descripcion", label: "Descripción ", sortable: true, class: "text-center" },
        { key: "cantidad", label: "Cantidad", sortable: true, class: "text-center" },
        { key: "precio", label: "Precio", sortable: true, class: "text-center" },

        { key: "action", label: "acción", tdClass: "dt-body-right" },
        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      items: [],
      row: { puesto_id: null, fecha_i: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD") },
      card_home: [],
      table: "meca_categorias",
      company_id: 0,
      userId: 0,
      rol: 0,
      productList: [],
      cart: [],
      formasDePago: [{ nombre: "Efectivo" }, { nombre: "Tarjeta de crédito" }, { nombre: "Crédito" }],
      formaPago: "Efectivo",
      subtotal: 0,
      total: 0,
      iva: 0,
      cliente_id: 0,
      nombreCliente: "Cliente Genérico",
      con_iva: 0,
      listaClientes: [],
      fieldsCustomer: [
        { key: "nombre", label: "Cliente", sortable: true, class: "text-center" },
        { key: "action", label: "", tdClass: "dt-body-right" },
      ],
      descripcion: "--",
      dataTmp: {},
      accountsList: [],
      cuentaOrigen: "400001",
      cuentaDestino: "100001",
      config: {},
      listaPuestos: [],
    };
  },
  async mounted() {
    try {
      this.$isLoading(true);
      this.config = JSON.parse(localStorage.getItem("config"));
      this.cuentaOrigen = this.config.cuenta_cliente;
      this.cuentaDestino = this.config.cuenta_ventas;
      this.userId = localStorage.getItem("user_id");
      this.company_id = localStorage.getItem("company_id");
      this.rol = localStorage.getItem("rol");
      await this.getCategories();
      await this.getClientes();
      await this.getAccounts();
      await this.getPositions();
      this.$isLoading(false);
    } catch (error) {
      this.$isLoading(false);
    }
  },
  methods: {
    async getPositions() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "/meca_puestos/getAllByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.listaPuestos = res;
        } else {
          this.listaPuestos = [];
        }
      } catch (error) {
        this.listaPuestos = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async getAccounts() {
      try {
        let res = await this.$store.dispatch("get", {
          path: "meca_cuentas/getAllByCompany/" + this.company_id,
        });
        console.log(res);
        if (res.length > 0) {
          this.accountsList = res;
        } else {
          this.accountsList = [];
        }
      } catch (error) {
        this.accountsList = [];
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      //   const { isConfirmed } = await this.$swal({
      //     title: "Confirmar",
      //     text: "¿Desea realmente eliminar el registro?",
      //     icon: "question",
      //     showCancelButton: true,
      //     cancelButtonText: "Cancelar",
      //     confirmButtonText: "Confirmar",
      //     reverseButtons: true,
      //   });
      //   if (isConfirmed) {
      let id = this.cart.indexOf(item);
      console.log(id);
      this.cart.splice(id, 1);
      this.calculateTotal();
    },
    addProductToCart() {
      if (this.dataTmp.descripcion.len) console.log("add");
      this.cart.push(this.dataTmp);
      this.dataTmp = {};
      this.calculateTotal();
      this.$bvModal.hide("exampleModalCenter");
    },
    calculateTotal() {
      this.subtotal = this.cart.map((item) => item.cantidad * item.precio).reduce((p, c) => p + c, 0);
      if (this.con_iva == 1) {
        this.iva = this.subtotal * 0.15;
      } else {
        this.iva = 0;
      }
      this.total = this.subtotal + this.iva;
    },
    boton(item) {
      this.buttons = item;
    },

    //////////////
    selCustomer(item) {
      this.cliente_id = item.id;
      this.nombreCliente = item.nombre;
      this.$bvModal.hide("modalClientes");
    },
    async getClientes() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_clientes/getAllByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.listaClientes = res;
        }
      } catch (error) {
        this.listaClientes = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    cleanSale() {
      this.cart = [];
      this.productList = [];
      this.calculateTotal();
    },
    gotoClientes() {
      this.$bvModal.show("modalClientes");
    },
    deleteProduct(item) {
      let tt = this.cart.indexOf(item);
      console.log(tt);
      this.cart.splice(tt, 1);
      this.calculateTotal();
      //alert()
    },

    showModal(item) {
      this.row = item;
      this.row.cantidad = 1;
      this.$bvModal.show("exampleModalCenter");
    },
    async getProductByCategory(item) {
      console.log("getProductByCategory");
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_productos/getAllVisibleByCategoryCompany/" + this.company_id + "/" + item });
        this.$isLoading(false);
        if (res.length > 0) {
          console.log(this.productList);
          this.productList = res;
        } else {
          this.productList = [];
        }
      } catch (error) {
        this.productList = [];
        console.error("error", error);
      }
    },
    async getCategories() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: this.table + "/getAllVisibleByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.card_home = res;
        }
      } catch (error) {
        this.card_home = [];
        console.error("error", error);
      }
    },

    async saveData() {
      try {
        console.log("save");
        //let data = Object.assign({} , this.row)
        //data.empresa_id = this.company_id
        let res;
        this.$isLoading(true);
        let data = {
          invoice: {
            cliente_id: this.cliente_id,
            nombre: this.nombreCliente,
            iva: this.iva,
            subtotal: this.subtotal,
            total_venta: this.total,
            empresa_id: this.company_id,
            usuario_id: this.userId,
            estado: "Pagada",
            descripcion: this.descripcion,
            por_iva: this.por_iva,
            fecha_i: this.$moment.tz(this.row.fecha_i, "America/Costa_Rica").format("YYYY-MM-DD"),
            fecha_f: this.$moment.tz(this.row.fecha_i, "America/Costa_Rica").format("YYYY-MM-DD"),
            metodo_pago: this.buttons == 1 ? "Transferencia" : this.buttons == 2 ? "Efectivo" : this.buttons == 3 ? "Tarjeta de Crédito" : "Otros",
            total_costos: this.cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0),
            margen: this.total - this.iva - this.cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0),
            puesto_id: this.row.puesto_id,
          },
          detail: this.cart,
          accounts: {
            cuentaOrigen: this.cuentaOrigen,
            cuentaDestino: this.cuentaDestino,
          },
        };
        res = await this.$store.dispatch("post", { path: "meca_ventas/newFreeSale", data: data });
        this.$isLoading(false);

        if (res.error) {
          this.$isLoading(false);
          this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
        } else {
          this.cleanSale();
          this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.$router.push("/purchasesSales/purchasesSales-Sales");
          //this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
  },
};
</script>

<style>
.border-table {
  border: 1px solid rgb(216, 216, 216) !important;
  border-radius: 5px;
  /* padding:5px; */
  text-align: center !important;
}

/* carusel */
.inner {
  max-width: 100%;
  margin: auto;
  padding-left: 15px;
  padding-right: 15px;
}
.scroll-container {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 98%;

  scroll-snap-type: mandatory;
  scroll-behavior: smooth;

  word-wrap: break-word !important;
  margin-bottom: 50px;
}

.scroll-container .scroll-section {
  display: inline-block;
  vertical-align: middle;

  /* white-space: nowrap; */
}
/*Slider X (different sized images)*/

.scroll-container.diff-size {
  scroll-snap-destination: 50% 0%;
  padding: 20px 0px;
  margin: 1%;
}

.scroll-container.diff-size .scroll-section {
  width: auto;
  height: 100%;
  margin-right: 15px;
  scroll-snap-coordinate: 50% 0%;
}

.scroll-container.diff-size .scroll-section:first-child {
  scroll-snap-coordinate: 0% 0%, 50% 0%;
  margin-left: 15px;
}

.scroll-container.diff-size .scroll-section:last-child {
  scroll-snap-coordinate: 50% 0%, 100% 0%;
}

.searchdiv {
  /* background-color:#fd306e !important; */
  /* background-color: black !important; */
  /* padding: 30px;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.25);
  border-radius: 20px; */
}
.buttonsearch {
  background-color: #fd306e !important;
  color: white;
}
.inputSearch {
  border-color: #fd306e !important;
}

.carousel-item img {
  /* height:100vh!important ; */
}

.cel_big {
  right: 0px;
  /* border:1px solid red; */
  /* //height: 100% !important; */
  /* background-color: rgb(231, 231, 231); */
  position: absolute;
  height: 800px;
  bottom: 20px;
  top: 15px;
  min-height: 600px;
}
.hr {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
.text-red {
  color: red;
}
@media screen and (max-width: 720px) {
  .cel_big {
    position: static;
    min-height: 0px !important;
    height: 0px !important;
  }
}
</style>
