<template>
    <div class="container ">
        <!-- <div class="page-header text-blue-d2">
            <h1 class="page-title text-secondary-d1">
                Invoice
                <small class="page-info">
                    <i class="fa fa-angle-double-right text-80"></i>
                    ID: #111-222
                </small>
            </h1>

            <div class="page-tools">
                <div class="action-buttons">
                    <a class="btn bg-white btn-light mx-1px text-95" href="#" data-title="Print">
                        <i class="mr-1 fa fa-print text-primary-m1 text-120 w-2"></i>
                        Print
                    </a>
                    <a class="btn bg-white btn-light mx-1px text-95" href="#" data-title="PDF">
                        <i class="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                        Export
                    </a>
                </div>
            </div>
        </div> -->

        <div class=" px-0">
            <div class="row justify-content-center">
                <div class="col-12 col-lg-7 card px-5 pt-2">
                    <div class="row">
                        <div class="col-12">
                            <div class="text-center text-150">
                                <i class="fa fa-book fa-2x text-success-m2 mr-1"></i>
                                <span class="text-default-d3">Bootdey.com</span>
                            </div>
                        </div>
                    </div>
                    <!-- .row -->

                    <hr class="row brc-default-l1 mx-n1 mb-4" />

                    <div class="row">
                        <div class="col-sm-6">
                            <div>
                                <span class="text-sm text-grey-m2 align-middle">To:</span>
                                <span class="text-600 text-110 text-primary align-middle pl-2">Alex Doe</span>
                            </div>
                            <div class="text-grey-m2">
                                <div class="my-1">
                                    Street, City
                                </div>
                                <div class="my-1">
                                    State, Country
                                </div>
                                <div class="my-1"><i class="fa fa-phone fa-flip-horizontal text-primary"></i> <b class="text-600">111-111-111</b></div>
                            </div>
                        </div>
                        <!-- /.col -->

                        <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                            <hr class="d-sm-none" />
                            <div class="text-grey-m2">
                                <div class="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                                    Invoice
                                </div>

                                <div class="my-2"><i class="fa fa-circle text-primary text-xs mr-1"></i> <span class="text-600 text-90">ID:</span> #111-222</div>

                                <div class="my-2"><i class="fa fa-circle text-primary text-xs mr-1"></i> <span class="text-600 text-90">Issue Date:</span> Oct 12, 2019</div>

                                <div class="my-2"><i class="fa fa-circle text-primary text-xs mr-1"></i> <span class="text-600 text-90">Status:</span> <span class="badge badge-warning badge-pill px-25">Unpaid</span></div>
                            </div>
                        </div>
                        <!-- /.col -->
                    </div>

                    <div class="mt-4">
                        <div class="row  bg-dark py-25">
                            <div class="d-none d-sm-block col-1">#</div>
                            <div class="col-9 col-sm-5">Description</div>
                            <div class="d-none d-sm-block col-4 col-sm-2">Qty</div>
                            <div class="d-none d-sm-block col-sm-2">Unit Price</div>
                            <div class="col-2">Amount</div>
                        </div>

                        <div class="text-95 text-secondary-d3">
                            <div class="row mb-2 mb-sm-0 py-25">
                                <div class="d-none d-sm-block col-1">1</div>
                                <div class="col-9 col-sm-5">Domain registration</div>
                                <div class="d-none d-sm-block col-2">2</div>
                                <div class="d-none d-sm-block col-2 text-95">$10</div>
                                <div class="col-2 text-secondary-d2">$20</div>
                            </div>

                            <div class="row mb-2 mb-sm-0 py-25">
                                <div class="d-none d-sm-block col-1">2</div>
                                <div class="col-9 col-sm-5">Web hosting</div>
                                <div class="d-none d-sm-block col-2">1</div>
                                <div class="d-none d-sm-block col-2 text-95">$15</div>
                                <div class="col-2 text-secondary-d2">$15</div>
                            </div>

                            <div class="row mb-2 mb-sm-0 py-25">
                                <div class="d-none d-sm-block col-1">3</div>
                                <div class="col-9 col-sm-5">Software development</div>
                                <div class="d-none d-sm-block col-2">--</div>
                                <div class="d-none d-sm-block col-2 text-95">$1,000</div>
                                <div class="col-2 text-secondary-d2">$1,000</div>
                            </div>

                            <div class="row mb-2 mb-sm-0 py-25 ">
                                <div class="d-none d-sm-block col-1">4</div>
                                <div class="col-9 col-sm-5">Consulting</div>
                                <div class="d-none d-sm-block col-2">1 Year</div>
                                <div class="d-none d-sm-block col-2 text-95">$500</div>
                                <div class="col-2 text-secondary-d2">$500</div>
                            </div>
                        </div>

                        <div class="row border-b-2 brc-default-l2"></div>

                        <!-- or use a table instead -->
                        <!--
                <div class="table-responsive">
                    <table class="table table-striped table-borderless border-0 border-b-2 brc-default-l1">
                        <thead class="bg-none bgc-default-tp1">
                            <tr class="text-white">
                                <th class="opacity-2">#</th>
                                <th>Description</th>
                                <th>Qty</th>
                                <th>Unit Price</th>
                                <th width="140">Amount</th>
                            </tr>
                        </thead>

                        <tbody class="text-95 text-secondary-d3">
                            <tr></tr>
                            <tr>
                                <td>1</td>
                                <td>Domain registration</td>
                                <td>2</td>
                                <td class="text-95">$10</td>
                                <td class="text-secondary-d2">$20</td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
                -->

                        <div class="row mt-3">
                            <div class="col-12 col-sm-5 text-grey-d2 text-95 mt-2 mt-lg-0">
                                Extra note such as company or payment information...
                            </div>

                            <div class="col-12 col-sm-7 text-grey text-90 order-first order-sm-last">
                                <div class="row my-2">
                                    <div class="offset-2 col-5 text-right">
                                        SubTotal
                                    </div>
                                    <div class="col-5 text-right">
                                        <span class="text-120 text-secondary-d1">$2,250</span>
                                    </div>
                                </div>

                                <div class="row my-2">
                                    <div class="offset-2 col-5 text-right">
                                        Tax (10%)
                                    </div>
                                    <div class="col-5 text-right">
                                        <span class="text-110 text-secondary-d1">$225</span>
                                    </div>
                                </div>

                                <div class="row my-2 align-items-center bgc-primary-l3 ">
                                    <div class="offset-2 col-5 text-right">
                                        Total Amount
                                    </div>
                                    <div class="col-5 text-right">
                                        <span class="text-150 text-success-d3 opacity-2">$2,475</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr />

                        <div class="pb-4">
                            <span class="text-primary text-105">Thank you for your business</span>
                            <a href="#" class="btn btn-primary  float-right mt-3 mt-lg-0">Pay Now</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
    
    },
    methods: {
      
    },
  };
  </script>
  <style scoped lang="scss" >
  body{
 
}
.text-secondary-d1 {
 
}
.page-header {
    margin: 0 0 1rem;
    padding-bottom: 1rem;
    padding-top: .5rem;
    border-bottom: 1px dotted #e2e2e2;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center;
}
.page-title {
    padding: 0;
    margin: 0;
    font-size: 1.75rem;
    font-weight: 300;
}
.brc-default-l1 {
    border-color: #dce9f0!important;
}

.ml-n1, .mx-n1 {
    margin-left: -.25rem!important;
}
.mr-n1, .mx-n1 {
    margin-right: -.25rem!important;
}
.mb-4, .my-4 {
    margin-bottom: 1.5rem!important;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0,0,0,.1);
}

.text-grey-m2 {
 
}

.text-success-m2 {
 
}

.font-bolder, .text-600 {
    font-weight: 600!important;
}

.text-110 {
    font-size: 110%!important;
}

.pb-25, .py-25 {
    padding-bottom: .75rem!important;
}

.pt-25, .py-25 {
    padding-top: .75rem!important;
}

.bgc-default-l4, .bgc-h-default-l4:hover {
    background-color: var(--primary-opacity-color-rgba)!important;
}
.page-header .page-tools {
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.btn-light {
    
    background-color: var(--primary-opacity-color-rgba)!important;
    border-color: #dddfe4;
}
.w-2 {
    width: 1rem;
}

.text-120 {
    font-size: 120%!important;
}


.text-150 {
    font-size: 150%!important;
}
.text-60 {
    font-size: 60%!important;
}
.text-grey-m1 {
   
}
.align-bottom {
    vertical-align: bottom!important;
}

//   body{margin-top:20px;
// background:#87CEFA;
// }

// .card-footer-btn {
//     display: flex;
//     align-items: center;
//     border-top-left-radius: 0!important;
//     border-top-right-radius: 0!important;
// }
// .text-uppercase-bold-sm {
//     text-transform: uppercase!important;
//     font-weight: 500!important;
//     letter-spacing: 2px!important;
//     font-size: .85rem!important;
// }
// .hover-lift-light {
//     transition: box-shadow .25s ease,transform .25s ease,color .25s ease,background-color .15s ease-in;
// }
// .justify-content-center {
//     justify-content: center!important;
// }
// .btn-group-lg>.btn, .btn-lg {
//     padding: 0.8rem 1.85rem;
//     font-size: 1.1rem;
//     border-radius: 0.3rem;
// }
// .btn-dark {
//     color: #fff;
//     background-color: #1e2e50;
//     border-color: #1e2e50;
// }

// .card {
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     min-width: 0;
//     word-wrap: break-word;
//     background-color: #fff;
//     background-clip: border-box;
//     border: 1px solid rgba(30,46,80,.09);
//     border-radius: 0.25rem;
//     box-shadow: 0 20px 27px 0 rgb(0 0 0 / 5%);
// }

// .p-5 {
//     padding: 3rem!important;
// }
// .card-body {
//     flex: 1 1 auto;
//     padding: 1.5rem 1.5rem;
// }

// tbody, td, tfoot, th, thead, tr {
//     border-color: inherit;
//     border-style: solid;
//     border-width: 0;
// }

// .table td, .table th {
//     border-bottom: 0;
//     border-top: 1px solid #edf2f9;
// }
// .table>:not(caption)>*>* {
//     padding: 1rem 1rem;
//     background-color: var(--bs-table-bg);
//     border-bottom-width: 1px;
//     box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
// }
// .px-0 {
//     padding-right: 0!important;
//     padding-left: 0!important;
// }
// .table thead th, tbody td, tbody th {
//     vertical-align: middle;
// }
// tbody, td, tfoot, th, thead, tr {
//     border-color: inherit;
//     border-style: solid;
//     border-width: 0;
// }

// .mt-5 {
//     margin-top: 3rem!important;
// }

// .icon-circle[class*=text-] [fill]:not([fill=none]), .icon-circle[class*=text-] svg:not([fill=none]), .svg-icon[class*=text-] [fill]:not([fill=none]), .svg-icon[class*=text-] svg:not([fill=none]) {
//     fill: currentColor!important;
// }
// .svg-icon>svg {
//     width: 1.45rem;
//     height: 1.45rem;
// }
  </style>