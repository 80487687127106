<template>
  <h4></h4>
</template>

<script>
export default {
data(){
    return{
        breadcrumbNameLocalStorage:'Cotizaciones'
    }
},
mounted(){
    localStorage.setItem('breadcrumbNameLocalStorage', this.breadcrumbNameLocalStorage)
}
}
</script>

<style>

</style>