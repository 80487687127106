<template>
    <!-- main body area -->
    <div class="main" >

        <!-- <HeaderComponent v-show="currentLayout == 'layout-h'"/> -->
        <!-- <BodyHeaderComponent v-if="!['chat'].includes($route.name)"/> -->
        <div class="body d-flex" >
           <div >
                <slot></slot>
            </div>
        </div>
    </div>
</template>
<script>
import { EventBus } from '@/js/event-bus.js';
export default {
    name:'MyDefaultLayout',
    components: {

    },data (){
      return {
        currentLayoutStyle:'style-2',
        currentLayout:'layout-b',
        isFooter: this.$route.meta.isFooter,
      }
    },mounted() {
        EventBus.$on('activeLayout', (activeLayout) => {
          this.currentLayout = activeLayout.currentLayout
          this.currentLayoutStyle = activeLayout.currentLayoutStyle
        })
    },beforeCreate() {
        let layout = 'layout-a'
        let style = 'style-1'
        EventBus.$emit('currentLayout',{layout,style})
	},computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },watch:{
        $route (){
            this.isFooter = this.$route.meta.isFooter;
        }
    },beforeDestroy(){
        EventBus.$off('activeLayout')
    }
}
</script>
<style scoped>

</style>