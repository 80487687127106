<template>
  <div class="container-fluid canvan-bg canvan mt-0 mb-0 pt-0 pb-0">
    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-md-12 mt-0 mx-0 px-0">
            <div class="input-group p-1 desktop card">
              <!-- AL INPUT ABRIR MODAL -->
              <b-input-group>
                <!-- <b-form-input v-model="text" class="ml-2" placeholder="Vendedor"></b-form-input> -->
                <b-form-select required class="form-control select" v-model="carritoVenta.usuario_id" :options="listaVendedores" value-field="id" text-field="nombreusuario">
                </b-form-select>
                <b-form-select class="form-control select ml-2" v-model="carritoVenta.puesto_id" :options="listaPuestos" value-field="id" text-field="nombre"> </b-form-select>
                <b-form-input class="form-control" type="date" v-model="carritoVenta.fecha" name="" id="vf" />
                <b-input-group-prepend class="ml-2 pointer" is-text @click="$bvModal.show('modalClientes')">
                  <i class="fa-solid fa-magnifying-glass"></i>
                </b-input-group-prepend>
                <input hidden class="form-control" type="text" :value="clienteSel" name="" id="" />
                <input
                  @click="$bvModal.show('modalClientes')"
                  placeholder="Buscar cliente"
                  required
                  readonly
                  class="form-control"
                  type="text"
                  :value="carritoVenta.nombreCliente"
                  name=""
                  id=""
                />
                <button @click="sidebarVisible = true" class="btn btn-dark ml-2"><i class="fa-solid fa-bars mr-1"></i>Categorias</button>
              </b-input-group>
              <!-- sidebar------------------------------>

              <b-sidebar
                @hidden="oculto()"
                class="bg-danger"
                id="categorias"
                aria-labelledby="sidebar-no-header-title"
                :bg-variant="cardColor"
                backdrop
                backdrop-variant="transparent"
                no-header
                right
                :visible="sidebarVisible"
              >
                <div class="py-1 mt-2 px-4">
                  <div class="row row-deck">
                    <div v-for="itemCategorias of listCategorias" :key="itemCategorias.id" class="col-6 px-0 mx-0">
                      <b-card
                        @click="
                          getProductos(itemCategorias);
                          sidebarVisible = false;
                        "
                        class="m-1 text-center border-0 bg-primary zoom"
                      >
                        <span>{{ itemCategorias.nombre }}</span>
                      </b-card>
                    </div>
                  </div>
                </div>
              </b-sidebar>
            </div>
          </div>
          <div class="col-md-6 pl-0 ml-0 mr-0 pr-0">
            <div class="movil mt-2">
              <b-button class="border border-light mt-md-1 mx-0" variant="primary" style="width: 25%; height: 25%"><i class="fa-solid fa-utensils"></i></b-button>
              <b-button class="border border-light mt-md-1 mx-0" variant="warning" style="width: 25%; height: 25%"><i class="fa-solid fa-clipboard-list"></i></b-button>
              <b-button class="border border-light mt-md-1 mx-0" variant="danger" style="width: 25%; height: 25%"><i class="fa-solid fa-list-ol"></i></b-button>
              <b-button class="border border-light mt-md-1 mx-0" variant="success" style="width: 25%; height: 25%"><i class="fa-solid fa-cart-shopping"></i></b-button>
            </div>

            <!-- fin desktop -->
            <!-- movil -->
            <div class="row mt-2 movil">
              <div class="col-md-3 mr-0 pr-3">
                <label for="" class="mt-2 text-left">Codigo cliente</label>
              </div>
              <div class="col-md-5 col-xl-6 pr-3 pr-md-0">
                <b-form-input v-model="text" placeholder="Enter your name"></b-form-input>
              </div>
              <div class="col-md-4 col-xl-6 pr-3 pr-md-0 mt-2 mt-md-0">
                <b-form-input class="border border-danger text-danger" style="" v-model="text" placeholder="TOTAL"></b-form-input>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="active" class="row mt-2 px-0 mx-0">
        <div class="col-md-6 pl-1 ml-0 h-60vh">
          <b-table sticky-header class="px-0 pt-0 mt-0 tabla" hover :items="carritoVenta.cart" :fields="fieldsTable">
            <template class="" #cell(cantidad)="data">
              <div class="row" style="width: 150px">
                <div class="col-12 mt-2">
                  <div class="input-group" style="padding-left: 10px !important; padding-right: 10px">
                    <div class="input-group-prepend">
                      <button @click="updateQuantity(data.item, 1)" min="1" style="width: 1rem; background: orange" class="btn btn-decrement btn-minus p-0" type="button">
                        <strong>−</strong>
                      </button>
                    </div>

                    <!-- {{ data }} -->
                    <span type="text" style="text-align: center; width: 25px !important; border: 1px solid gray">{{ data.item.cantidad }} </span>
                    <div class="input-group-append">
                      <button @click="updateQuantity(data.item, 2)" style="width: 1rem; background: orange" class="btn btn-increment btn-plus p-0" type="button">
                        <strong>+</strong>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template #cell(precio_venta)="data">
              <span type="text" class="text-center"> C$ {{ data.item.precio_venta }} </span>
            </template>
            <template #cell(total)="data">
              <span type="text" class="text-center"> C$ {{ data.item.total }} </span>
            </template>
            <template #cell(acciones)="data">
              <button @click="eliminaItem(data)" class="border-top btn-danger btn p-1"><i class="fa fa-trash-o"></i></button>
            </template>
          </b-table>
        </div>

        <div class="col-md-6 h-60vh mx-0" style="overflow-y: scroll; overflow-x: hidden !important; display: inline-block">
          <div class="row pt-1" style="max-height: 100px !important">
            <div
              class="col-3 px-0 mx-0"
              id="popover-button-click"
              v-for="item in productList"
              :key="item.id"
              @mousedown="startHold"
              @mouseup="endHold"
              @click="seleccionaProducto(item)"
            >
              <product-vue responsive="true" :product="item" class="zoom" />
            </div>
          </div>
        </div>
      </div>
      <div v-else class="h-60vh row px-0">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <i class="fa-solid fa-earth-americas" style="font-size: 80px"></i>
        </div>
      </div>
      <div class="col-12 card mt-2 p-2 mx-0">
        <div class="d-flex justify-content-between">
          <div class=" ">
            <button style="font-size: 18px" class="btn btn-danger px-3 mx-1"><i class="fa-solid fa-xmark"></i></button>
            <button style="font-size: 18px" class="btn btn-dark px-3 mx-1"><i class="fa-solid fa-print"></i></button>
            <button style="font-size: 18px" class="btn btn-success px-3 mx-1"><i class="fa-solid fa-lock"></i></button>
            <button style="font-size: 18px" v-b-toggle.money class="btn btn-warning px-3 mx-1"><i class="fa-solid fa-circle-dollar-to-slot"></i></button>
          </div>
          <!-- <div class="bg-title-card" style="w">
           
          </div> -->
          <div class="d-flex">
            <div style="" class="text-left py-2">
              <span class="text-primary px-3 py-1 mt-1 mx-1">Sub-total: {{ config.moneda + carritoVenta.subtotal.toFixed(2) }}</span>

              <span
                @click="
                  carritoVenta.con_iva == 0 ? (carritoVenta.con_iva = 1) : (carritoVenta.con_iva = 0);
                  calculateTotal();
                "
                class="text-primary px-3 py-1 mt-1 mx-1 pointer"
              >
                Iva: {{ config.moneda + carritoVenta.iva.toFixed(2) }}
              </span>
              <span class="text-primary px-3 py-1 mt-1 mx-1">Total: {{ config.moneda + carritoVenta.total.toFixed(2) }}</span>
            </div>
            <button class="btn btn-dark px-3 mx-1"><i class="fa-solid fa-earth-americas"></i></button>
            <button class="btn btn-success px-3 mx-1"><i class="fa-solid fa-tags"></i></button>
            <button class="btn btn-warning px-3 mx-1"><i class="fa-solid fa-star"></i></button>
          </div>
        </div>
        <b-sidebar id="money" bg-variant="card" aria-labelledby="sidebar-no-header-title" backdrop backdrop-variant="transparent" no-header right>
          <template #default="{ hide }">
            <div class="py-1 mt-2 px-4">
              <div class="row bg-dark py-2 my-2 rounded">
                <div class="col-6">
                  <span>Sub-total:</span>
                </div>
                <div class="col-6 text-right">{{ config.moneda + carritoVenta.subtotal.toFixed(2) }}</div>
              </div>
              <div class="row bg-dark py-2 my-2 rounded">
                <div class="col-6">
                  <span>Iva</span>
                </div>
                <div class="col-6 text-right">{{ config.moneda + carritoVenta.iva.toFixed(2) }}</div>
              </div>
              <div class="row bg-primary py-2 my-2 rounded">
                <div class="col-6">
                  <b><span>TOTAL:</span></b>
                </div>
                <div class="col-6 text-right">
                  <b> {{ config.moneda + carritoVenta.total.toFixed(2) }}</b>
                </div>
              </div>
            </div>
            <b-form-checkbox
              @change="calculateTotal($event)"
              size="lg"
              id="checkbox-1"
              v-model="carritoVenta.con_iva"
              name="checkbox-1"
              value="1"
              unchecked-value="0"
              class="pt-1 mx-3"
            >
              <span for="observaciones" class="h6">Iva</span>
            </b-form-checkbox>
            <hr class="" />
            <div class="px-3">
              <span>Forma de pago</span>
              <b-form-select required class="form-control select" v-model="carritoVenta.formaPago" :options="formasDePago" value-field="nombre" text-field="nombre">
              </b-form-select>
            </div>
            <div class="px-3 pt-2">
              <span>Cliente</span>
              <input
                @click="$bvModal.show('modalClientes')"
                placeholder="Buscar cliente"
                required
                readonly
                class="form-control"
                type="text"
                :value="carritoVenta.nombreCliente"
                name=""
                id=""
              />
            </div>
            <div class="px-0 pt-2">
              <div class="col-12 text-left mt-2">
                <span>Cuenta origen</span>
                <b-form-select required class="form-control select" v-model="carritoVenta.cuentaOrigen" :options="accountsList" value-field="cuenta" text-field="titulo">
                </b-form-select>
              </div>
            </div>
            <div class="px-3 pt-2">
              <span>Cuenta destino</span>
              <b-form-select required class="form-control select" v-model="carritoVenta.cuentaDestino" :options="accountsList" value-field="cuenta" text-field="titulo">
              </b-form-select>
            </div>
            <div class="px-3 pt-3">
              <button @click.prevent="saveData()" class="btn btn-primary btn-block"><i class="pr-2 fa-solid fa-floppy-disk"></i>Guardar</button>
              <button
                @click="
                  hide;
                  cleanSale();
                "
                class="btn btn-danger btn-block"
              >
                <i class="pr-2 fa-solid fa-xmark"></i>Cancelar
              </button>
            </div>
          </template>
        </b-sidebar>
      </div>
    </div>
    <b-modal id="modalClientes" tabindex="-1" class="modal-lg" size="xl" centered title="Seleccionar cliente" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <customers></customers>
      </div>
    </b-modal>
  </div>
</template>
<script>
// import { EventBus } from "@/js/event-bus.js";
import { eventBus } from "../../../../main";
import { EventBus } from "@/js/event-bus.js";
import ProductVue from "./Product-asiav2.vue";
import Customers from "./Customers.vue";

// import { BPopover } from 'bootstrap-vue';

export default {
  components: { ProductVue, Customers },
  data() {
    return {
      cardColor: 'card',
      fieldsTable: [
        { key: "nombre", label: "Producto" },
        { key: "cantidad", label: "cantidad" },
        { key: "precio_venta", label: "Precio unit" },
        { key: "total", label: "Total" },
        { key: "acciones", label: "Acciones" },
      ],
      fecha: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
      listaVendedores: [],
      venta: { fecha: this.fecha },

      items: [],
      row: {},
      card_home: [],
      table: "meca_categorias",
      company_id: 0,
      userId: 0,
      rol: 0,
      productList: [],
      productListBk: [],
      cart: [],
      formasDePago: [{ nombre: "Efectivo" }, { nombre: "Tarjeta de crédito" }, { nombre: "Crédito" }],
      formaPago: "Efectivo",
      subtotal: 0,
      total: 0,
      iva: 0,
      cliente_id: 0,
      nombreCliente: null,
      con_iva: 1,
      listaClientes: [],
      fieldsCustomer: [
        {
          key: "nombre",
          label: "Cliente",
          sortable: true,
          class: "text-center",
        },
        { key: "action", label: "", tdClass: "dt-body-right" },
      ],
      descripcion: "--",
      currentPage: 0,
      perPage: 0,
      filter: "",
      filterOn: "",
      sortBy: "",
      sortDesc: "",
      sortDirection: "",
      onFiltered: "",
      textoABuscar: "",
      category_id: 0,
      accountsList: [],
      cuentaOrigen: "400001",
      cuentaDestino: "100001",
      config: {},
      listaPuestos: [],
      puesto_id: null,
      allProducts: [],
      carritoVenta: [],
      plantillaVenta: {
        id: 0,
        puesto_id: null,
        fecha: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        cart: [],
        con_iva: 1,
        subtotal: 0,
        iva: 0,
        total: 0,
        formaPago: "Efectivo",
        nombreCliente: null,
        descripcion: null,
        cuentaOrigen: "400001",
        cuentaDestino: "100001",
        row: {},
      },
      tabIndexs: 0,
      itemSel: 0,
      ////
      sidebarVisible: false,
      active: true,
      showPopover: false,
      popoverTarget: "popover-button-click",
      holdTimeout: null,
      mostrar: false,
      product_asia: [],
      listCategorias: [],
      carrito: [],
      otros: 0,
      popoverShow: false,
      text: "",
    };
  },
  destroyed() {
    eventBus.$emit("isToogle", false);
  },
  computed: {
    clienteSel() {
      let resulta = eventBus.$on("clientesel", (data) => {
        console.log(data);
        this.carritoVenta.cliente_id = data.id;
        this.carritoVenta.nombreCliente = data.nombre;
        this.$forceUpdate();
        return data.id;
      });
      console.log("resulta", resulta);
      return resulta;
    },
  },
  async mounted() {
    document.addEventListener("click", this.closePopoverOnClickOutside);
    EventBus.$emit("isToogle", true);

    this.company_id = localStorage.getItem("company_id");

    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");
    this.config = JSON.parse(localStorage.getItem("config"));
    this.venta.usuario_id = this.userId;
    await this.getDataCategorias();
    // await this.getCategories();
    // await this.getClientes();
    await this.getAccounts();
    await this.getPositions();
    await this.getallProducts();
    await this.getUsuarios();
    await this.agregarVenta();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.closePopoverOnClickOutside);
  },
  methods: {
    async cleanSale() {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Seguro eliminar venta?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          this.agregarVenta();
          this.productList = [];
          this.productListBk = this.productList;
          // this.calculateTotal();
        } catch (error) {
          this.$isLoading(false);
        }
      }
    },
    async saveData() {
      console.log("", this.total_venta);

      try {
        if (this.carritoVenta.nombreCliente == null) {
          this.$swal({
            title: "Aviso",
            text: "debes seleccionar cliente",
            icon: "error",
          });
          return;
        }
        if (this.carritoVenta.cart.length == 0) {
          this.$swal({
            title: "Aviso",
            text: "debes agregar productos",
            icon: "error",
          });
          return;
        }
        if (this.carritoVenta.total == 0) {
          this.$swal({
            title: "Aviso",
            text: "No puedes ingresar venta en 0",
            icon: "error",
          });
          return;
        }
        console.log("save");
        //let data = Object.assign({} , this.row)
        //data.empresa_id = this.company_id
        let res;
        this.$isLoading(true);
        let data = {
          invoice: {
            cliente_id: this.carritoVenta.cliente_id,
            nombre: this.carritoVenta.nombreCliente,
            iva: this.carritoVenta.iva,
            subtotal: this.carritoVenta.subtotal,
            total_venta: this.carritoVenta.total,
            empresa_id: this.company_id,
            usuario_id: this.userId,
            estado: "Pagada",
            descripcion: this.carritoVenta.descripcion,
            por_iva: 15,
            fecha_i: this.carritoVenta.fecha,
            fecha_f: this.carritoVenta.fecha,
            total_costos: this.carritoVenta.cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0),
            margen: this.carritoVenta.total - this.carritoVenta.iva - this.carritoVenta.cart.map((item) => item.cantidad * item.precio_compra).reduce((p, c) => p + c, 0),
            metodo_pago: this.carritoVenta.formaPago,
            puesto_id: this.carritoVenta.puesto_id,
          },
          detail: this.carritoVenta.cart,
          accounts: {
            cuentaOrigen: this.carritoVenta.cuentaOrigen,
            cuentaDestino: this.carritoVenta.cuentaDestino,
          },
        };
        res = await this.$store.dispatch("post", {
          path: "meca_ventas/new",
          data: data,
        });
        this.$isLoading(false);

        if (res.error) {
          this.$isLoading(false);
          this.$swal({
            title: "Aviso",
            text: "Error: " + res.error,
            icon: "error",
          });
        } else {
          this.cleanSale();
          this.$swal({
            title: "Aviso",
            text: "Datos almacenados correctamente",
            icon: "success",
          });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    oculto() {
      console.log("oculto");
      this.sidebarVisible = false;
    },
    calculateTotal(e) {
      console.log("eeeee iva", e);
      try {
        if (e != undefined) {
          this.carritoVenta.con_iva = e;
        }

        this.carritoVenta.subtotal = this.carritoVenta.cart.map((item) => item.cantidad * item.precio_venta).reduce((p, c) => p + c, 0);
        if (this.carritoVenta.con_iva == 1) {
          console.log("1");
          this.carritoVenta.iva = this.carritoVenta.subtotal * 0.15;
        } else {
          console.log("0");
          this.carritoVenta.iva = 0;
        }
        this.carritoVenta.total = this.carritoVenta.subtotal + this.carritoVenta.iva;
        this.$forceUpdate();
      } catch (error) {
        console.log("error", error);
      }
    },
    async agregarVenta() {
      let index = this.carritoVenta.length;
      let cv = {
        id: index + 1,
        puesto_id: null,
        fecha: this.fecha, // this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        cart: [],
        con_iva: 1,
        subtotal: 0,
        iva: 0,
        total: 0,
        formaPago: "Efectivo",
        nombreCliente: null,
        descripcion: null,
        cuentaOrigen: "400001",
        cuentaDestino: "100001",
        row: {},
        usuario_id: this.userId,
      };
      this.carritoVenta = cv;
    },
    async getallProducts() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "meca_productos/getAll/" + this.company_id,
        });
        this.$isLoading(false);
        console.log("---", res);
        if (res.length > 0) {
          this.allProducts = res;
        } else {
          this.allProducts = [];
        }
      } catch (error) {
        this.allProducts = [];
        console.error("error", error);
      }
    },

    async getPositions() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "/meca_puestos/getAllByCompany/" + this.company_id });
        this.$isLoading(false);
        if (res.length > 0) {
          this.listaPuestos = res;
        } else {
          this.listaPuestos = [];
        }
      } catch (error) {
        this.listaPuestos = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async getAccounts() {
      try {
        let res = await this.$store.dispatch("get", {
          path: "meca_cuentas/getAllByCompany/" + this.company_id,
        });
        console.log(res);
        if (res.length > 0) {
          this.accountsList = res;
        } else {
          this.accountsList = [];
        }
      } catch (error) {
        this.accountsList = [];
        console.error("error", error);
      }
    },
    async getUsuarios() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", {
          path: "usuarios/getAllByCompany/" + this.company_id,
        });
        this.$isLoading(false);
        console.log("---", res);
        if (res.length > 0) {
          this.listaVendedores = res;
        } else {
          this.listaVendedores = [];
        }
      } catch (error) {
        this.listaVendedores = [];
        console.error("error", error);
      }
    },
    onClose() {
      this.popoverShow = false;
    },
    startHold() {
      this.holdTimeout = setTimeout(() => {
        this.showPopover = true;
      }, 500); // 2000 ms = 2 segundos
    },
    endHold() {
      clearTimeout(this.holdTimeout);
      if (!this.showPopover) {
        // Si el popover no se mostró ya, entonces cancelamos el tiempo de espera
        this.showPopover = false;
      }
    },
    popoverHidden() {
      // Este método se ejecuta cuando el popover se oculta
      // Puedes agregar lógica adicional aquí si es necesario
    },
    closePopoverOnClickOutside(event) {
      if (this.showPopover && !this.$el.contains(event.target)) {
        // Si el popover está activo y el clic ocurrió fuera de él, lo ocultamos
        this.showPopover = false;
      }
    },
    mostrarAlerta() {
      this.mostrar = true;
    },
    updateQuantity(item, tipo) {
      console.log("updaequantity ", item);
      // let itemIndex = this.carritoVenta.cart.indexOf(item);
      let itemIndex = this.carritoVenta.cart.findIndex((x) => x.id == item.id);
      //itemIndex = this.carritoVenta.cart[]
      console.log("updaequantity ", itemIndex);
      if (tipo == 1) {
        if (this.carritoVenta.cart[itemIndex].cantidad > 1) {
          this.carritoVenta.cart[itemIndex].cantidad--;
        }
      }
      if (tipo == 2) {
        this.carritoVenta.cart[itemIndex].cantidad++;
      }
      this.carritoVenta.cart[itemIndex].total = this.carritoVenta.cart[itemIndex].precio_venta * this.carritoVenta.cart[itemIndex].cantidad;
      this.carrito[itemIndex] = this.carritoVenta.cart[itemIndex];
      this.calculateTotal();
    },
    eliminaItem(item) {
      console.log(item);
      let tt = this.carritoVenta.cart.findIndex((x) => x.id == item.item.id);
      this.carritoVenta.cart.splice(tt, 1);
      this.calculateTotal();
    },
    seleccionaProducto(item) {
      try {
        console.log("-------->", item);
        this.row = item;
        this.row.cantidad = this.row.cantidad == null ? 1 : this.row.cantidad;
        this.row.indexVenta = 1;

        // this.carritoVenta.cart.push(this.row);
        let itemIndex = 0;
        if (this.carritoVenta.cart && this.carritoVenta.cart.length > 0) {
          console.log("verificando si existe prod ", item.id);
          itemIndex = this.carritoVenta.cart.findIndex((x) => x.id == item.id);
          console.log("itemindex 0", itemIndex);
        } else {
          itemIndex = -1;
          this.carritoVenta.cart = [];
        }
        console.log("itemindex ", itemIndex);
        if (itemIndex > -1) {
          this.updateQuantity(item, 2);
        } else {
          //2 si ya existe entonces solo agregar cantidad ++

          // this.carritoVenta.cart.push({
          //   Id: item.id,
          //   nombre: item.nombre,
          //   cantidad: 1,
          //   precio_unitario: item.precio_venta,
          //   acciones: null,
          //   total: item.precio_venta,
          // });
          item.total = item.precio_venta;
          this.carritoVenta.cart.push(item);
        }

        this.row = {};
        this.calculateTotal();
        this.$forceUpdate();

        this.$bvModal.hide("exampleModalCenter");
        console.log("carrito venta---->", this.carritoVenta);
        this.carrito = this.carritoVenta.cart;
        // console.log(item);
        // //1 verificar si el producto ya existe en this.carrito
      } catch (error) {
        console.log("error en seleccionaproducto", error);
      }
    },
    async getDataCategorias() {
      try {
        // this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_categorias/getAllByCompany/" + this.company_id });
        // this.$isLoading(false);
        if (res.length > 0) {
          this.listCategorias = res;
          //   this.totalRows = this.items.length;
        } else {
          this.listCategorias = [];
          //   this.totalRows = 0;
        }
      } catch (error) {
        this.listCategorias = [];
        // this.totalRows = 0;
        // this.$isLoading(false);
        console.error("error", error);
      }
    },
    async getProductos(item) {
      this.category_id = item.id;
      try {
        let res = await this.$store.dispatch("get", {
          path: "meca_productos/getAllVisibleByCategoryCompany/" + this.company_id + "/" + this.category_id,
        });
        if (res.length > 0) {
          console.log(this.productList);
          this.productList = res;
          this.productListBk = this.productList;
        } else {
          this.productList = [];
          this.productListBk = this.productList;
        }
      } catch (error) {
        this.productList = [];
        this.productListBk = this.productList;
        console.error("error", error);
      }
    },
  },
};
</script>
<style scoped>
.bg-title-card {
  background-color: var(--primary-opacity-color-rgba) !important;
  color: white !important;
}
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f100;
}

::-webkit-scrollbar-thumb {
  background: #8888884b;
  border-radius: 2px;
}

.scroll-box {
  height: 70vh;
  overflow-y: scroll !important;
}

.scroll-content {
  height: 70vh;
  width: 100% !important;
}
.btn-circle-middle {
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 0px !important;
  border-top-color: rgb(255, 255, 255) !important ;
  transition: 0.5s !important;
}
.btn-circle-middle:hover {
  margin-left: -15px !important;
  transition: 0.5s !important;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 100%;
}
.btn-asia {
  width: 8.7vw;
  height: 8.5vh;
  font-size: 10.5px;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-asia-calculadora {
  height: 5vh;
  font-size: 15px;
}
.movil {
  display: none;
}
.desktop {
  display: flex;
}
.h-60vh {
  height: 60vh;
}
.desktop-block {
  display: block !important;
}
.scroll-container {
  width: 300px; /* Ancho del contenedor */
  white-space: nowrap; /* Evita el salto de línea del contenido */
  overflow-x: scroll; /* Habilita la barra de desplazamiento horizontal */
}
.scroll-content {
  width: 800px; /* Ancho del contenido que excede el del contenedor */
}
/* @media screen and (max-width: 719px) {
  .movil {
    display: flex;
  }
  .movil-block {
    display: block;
  }
  .h-60vh {
  }
  .desktop {
    display: none !important;
  }
  .desktop-block {
    display: none !important;
  }
} */
</style>
