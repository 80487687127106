<template>
    <div class="row">
        <div class="col-12">
            <b-card class="mb-1" body-class="p-4">
                    <div class="d-flex align-items-center flex-column flex-md-row">
                        <img src="@/assets/images/profile_av.png" alt="" class="rounded-circle">
                        <div class="media-body ms-md-5 m-0 mt-4 mt-md-0 text-md-start text-center">
                            <h5 class="font-weight-bold">{{user_name}}</h5>
                            <div class="text-muted mb-4">{{user_bio}}</div>
                            <a href="javascript:void(0)" class="d-inline-block text-primary"> <strong>{{followers}}</strong> <span class="text-muted">followers</span> </a>
                            <a href="javascript:void(0)" class="d-inline-block text-primary ms-3"> <strong>{{following}}</strong> <span class="text-muted">following</span> </a>
                        </div>
                    </div>
            </b-card> 
            <b-tabs content-class="mt-4 mb-4" nav-class="tab-card  bg-secondary ps-3 ps-3">
                <b-tab title="Post"> 
                    <div class="row">
                        <div class="col-lg-8 col-md-12">
                            <div class="card mb-2">
                                <div class="card-body">
                                    <div class="post">
                                        <textarea class="form-control" placeholder="Post" rows="4"></textarea>
                                        <div class="py-3">
                                            <a href="#" class="px-3"><i class="fa fa-camera"></i></a>
                                            <a href="#" class="px-3"><i class="fa fa-video-camera"></i></a>
                                            <a href="#" class="px-3"><i class="fa fa-music"></i></a>
                                            <button class="btn btn-outline-primary float-right">Post</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ul class="list-unstyled">
                                <li class="card mb-2 post-card"  v-for="(post, index) in post_detal" :key="index">
                                    <div class="card-body p-lg-5 p-3">
                                        <div class="d-flex mb-3 pb-3 border-bottom">
                                            <img class="avatar rounded-circle" :src="post.img_src" alt="">
                                            <div class="flex-fill ms-3 text-truncate">
                                                <h6 class="mb-0"><span class="author">{{ post.name }}</span> <span class="text-muted small">{{ post.status }}</span></h6>
                                                <span class="text-muted">{{ post.time }}</span>
                                            </div>
                                        </div>
                                        <div class="timeline-item-post">
                                            <h6>{{post.content_heading}}</h6>
                                            <p>{{post.content}}</p>
                                            <div class="mb-2 mt-4">
                                                <a class="me-lg-4 me-2 text-primary" href="#"><i class="fa fa-thumbs-up"></i> Like ({{post.like}})</a>
                                                <a class="me-lg-4 me-2 text-primary" href="#"><i class="fa fa-comment"></i> Comment ({{post.comments}})</a>
                                                <a class="me-lg-4 me-2 text-primary" href="#"><i class="fa fa-share"></i> Share ({{post.share}})</a>
                                            </div>
                                            <div>
                                                <div class="d-flex mt-3 pt-3 border-top" v-for="(cmment, index) in post.comment" :key="index">
                                                    <img class="avatar rounded-circle" :src="`${cmment.img}`" alt="">
                                                    <div class="flex-fill ms-3 text-truncate">
                                                        <p class="mb-0"><span class="author">{{cmment.name}}</span> <small class="msg-time">{{cmment.time}}</small></p>
                                                        <span class="text-muted">{{cmment.content}}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <textarea class="form-control" placeholder="Replay"></textarea>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <div class="card mb-2">
                                <div class="card-body">
                                    <h6 class="card-title mb-3">{{categories.title}}</h6>
                                    <ul class="list-group list-group-custom">
                                        <li class="list-group-item"  v-for="(name, index) in categories.categories_name" :key="index">
                                            <a class="color-600" href="#">{{name}}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="card mb-2">
                                <div class="card-body">
                                    <h6 class="card-title mb-3">{{latest_news.title}}</h6>
                                    <ul class="list-group list-group-custom">
                                        <li class="list-group-item"  v-for="(news, index) in latest_news.news_detals" :key="index">
                                            <a href="javascript:void(0);" class="d-flex">
                                                <img class="avatar rounded-circle" :src="`${news.img}`" alt="">
                                                <div class="flex-fill ms-3">
                                                    <span class="text-primary">{{news.text}}</span>
                                                    <div><small class="text-muted">{{news.time}}</small></div>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Groups">
                    <div class="row g-1 row-deck">
                        <div class="col-xl-2 col-lg-3 col-sm-4 col-6" v-for="(group, index) in groups" :key="index">
                            <div class="card text-center">
                                <div class="btn-group position-absolute top-0 end-0">
                                    <chat-action></chat-action>
                                </div>                                            
                                <div class="card-body d-flex align-items-center justify-content-between flex-column">
                                    <div class="me-auto ms-auto py-4">
                                        <img class="avatar rounded m-1 lift" v-for="(image, index) in group.images" :key="index" :src="`${image}`" data-bs-toggle="tooltip" data-placement="top" title="Avatar Name" alt="">
                                    </div>
                                    <div class="mt-2">
                                        <h6 class="mb-0">{{group.name}}</h6>
                                        <small class="text-muted">{{group.conract}} Contacts</small>
                                    </div>
                                </div>
                            </div> <!-- .Card End -->
                        </div>
                    </div>
                </b-tab>
                <b-tab title="Projects">
                    <div class="row g-1 row-deck">
                        <div class="col-lg-4 col-md-6 col-sm-12" v-for="(project, index) in projects" :key="index">
                            <div class="card">
                                <div class="card-body p-md-4">
                                    <p class="text-muted text-uppercase mb-0 small">{{project.title}}</p>
                                    <h5 class="mt-0 mb-3"><a href="#" class="text-primary">{{project.sub_title}}</a></h5>
                                    <p>{{project.content}}<a href="#" class="font-600 text-muted">view more</a></p>
                                    
                                    <ul class="list-inline">
                                        <li class="list-inline-item pe-lg-4" v-for="(item, index) in project.items" :key="index">
                                            <h4 class="mb-0">{{item.attachments}}</h4>
                                            <p class="text-muted">{{item.title}}</p>
                                        </li>
                                    </ul>

                                    <div class="project-members mb-4">
                                        <label class="me-3">Team :</label>
                                        <a href="#" title="" v-for="(team, index) in project.teams" :key="index">
                                            <img class="avatar sm rounded" :src="`${team}`" alt="friend">
                                        </a>
                                    </div>

                                    <label class="small d-flex justify-content-between">Task completed: <span class="text-custom">{{project.task_completed.start_end}}</span></label>
                                    <div class="progress mt-1" style="height: 7px;">
                                        <div class="progress-bar" :class="`${project.task_completed.class}`" role="progressbar" :aria-valuenow="`${project.task_completed.width}`" aria-valuemin="0" aria-valuemax="100" v-bind:style="{ width: project.task_completed.width+'%'}"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <b-dropdown variant="transparent"  menu-class="border-0 shadow" toggle-class="nav-link" text="Settings" toggle-tag="a">
                                <b-dropdown-item href="#">Action</b-dropdown-item>
                                <b-dropdown-item href="#">Another action</b-dropdown-item>
                                <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item href="#">Separated link</b-dropdown-item>
                        </b-dropdown>
                    </template>
                </b-tab>
            </b-tabs>
        </div>
    </div> <!-- Row end  -->
</template>
<script>
import ChatAction from '@/components/core/ChatAction.vue'
export default {
    name:'ProfileComponent',
    components: {
        'chat-action':ChatAction,
    },data() {
        return {
            user_name:'Nellie Maxwell',
            user_bio:'Lorem ipsum dolor sit amet, nibh suavitate qualisque ut nam. Ad harum primis electram duo, porro principes ei has.',
            followers:'234',
            following:'111',
            post_detal:
            [
                {
                    img_src: require(`@/assets/images/xs/avatar1.jpg`),
                    name: "Nellie Maxwell",
                    status: 'posted a status',
                    time:"3 hours ago",
                    content_heading:'Hampden-Sydney College in Virginia, looked up one of the more obscure',
                    content:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
                    like:'105',
                    comments:'2',
                    share:'6',
                    comment:[
                        {
                            img: require(`@/assets/images/xs/avatar2.jpg`),
                            name: "Rose Rivera",
                            time:"1 hour ago",
                            content:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    
                        },
                        {
                            img: require(`@/assets/images/xs/avatar3.jpg`),
                            name: "Robert Hammer",
                            time:"1 hour ago",
                            content:'If you are going to use a passage of Lorem Ipsum, you need to be sure there is not anything embarrassing',
    
                        },
                    ]
                },
                {
                    img_src: require(`@/assets/images/xs/avatar1.jpg`),
                    name: "Nellie Maxwell",
                    status: 'posted a status',
                    time:"3 hours ago",
                    content_heading:'Hampden-Sydney College in Virginia, looked up one of the more obscure',
                    content:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
                    like:'26',
                    comments:'1',
                    share:'12',
                    comment:[
                        {
                            img: require(`@/assets/images/xs/avatar2.jpg`),
                            name: "Rose Rivera",
                            time:"1 hour ago",
                            content:'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.',
    
                        },
                    ]
                },
                {
                    img_src: require(`@/assets/images/xs/avatar1.jpg`),
                    name: "Nellie Maxwell",
                    status: 'posted a status',
                    time:"3 hours ago",
                    content_heading:'Hampden-Sydney College in Virginia, looked up one of the more obscure',
                    content:'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source.',
                    like:'0',
                    comments:'0',
                    share:'0',
                },
            ],
            groups:
            [
                {
                    name:'Out Sourcing',
                    conract:'16',
                    images:[
                       require(`@/assets/images/xs/avatar1.jpg`),
                       require(`@/assets/images/xs/avatar2.jpg`),
                       require(`@/assets/images/xs/avatar3.jpg`),
                       require(`@/assets/images/xs/avatar4.jpg`),
                       require(`@/assets/images/xs/avatar5.jpg`),
                       require(`@/assets/images/xs/avatar6.jpg`),
                    ]
                },
                {
                    name:'Management',
                    conract:'11',
                    images:[
                       require(`@/assets/images/xs/avatar6.jpg`),
                       require(`@/assets/images/xs/avatar7.jpg`),
                       require(`@/assets/images/xs/avatar8.jpg`),
                    ]
                },
                {
                    name:'Sold Properties',
                    conract:'106',
                    images:[
                        require(`@/assets/images/xs/avatar10.jpg`),
                        require(`@/assets/images/xs/avatar9.jpg`),
                        require(`@/assets/images/xs/avatar3.jpg`),
                        require(`@/assets/images/xs/avatar2.jpg`),
                        require(`@/assets/images/xs/avatar5.jpg`),
                    ]
                },
                {
                    name:'San Fransisco',
                    conract:'126',
                    images:[
                        require(`@/assets/images/xs/avatar1.jpg`),
                    ]
                },
                {
                    name:'Los Angeles',
                    conract:'84',
                    images:[
                        require(`@/assets/images/xs/avatar6.jpg`),
                        require(`@/assets/images/xs/avatar7.jpg`),
                        require(`@/assets/images/xs/avatar8.jpg`),
                        require(`@/assets/images/xs/avatar1.jpg`),
                    ]
                },
                {
                    name:'Colleagues',
                    conract:'245',
                    images:[
                        require(`@/assets/images/xs/avatar10.jpg`),
                        require(`@/assets/images/xs/avatar9.jpg`),
                        require(`@/assets/images/xs/avatar2.jpg`),
                        require(`@/assets/images/xs/avatar5.jpg`),
                    ]
                },
                {
                    name:'San Fransisco',
                    conract:'126',
                    images:[
                       require(`@/assets/images/xs/avatar1.jpg`),
                       require(`@/assets/images/xs/avatar6.jpg`),
                       require(`@/assets/images/xs/avatar7.jpg`),
                       require(`@/assets/images/xs/avatar8.jpg`),
                       require(`@/assets/images/xs/avatar1.jpg`),
                    ]
                },
                 {
                    name:'Los Angeles',
                    conract:'84',
                    images:[
                       require(`@/assets/images/xs/avatar1.jpg`),
                       require(`@/assets/images/xs/avatar6.jpg`),
                    ]
                },
            ],
            projects:
            [
                {
                    title:'Orange Limited',
                    sub_title:'New Admin Design',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                    items:[
                        {
                            title:'Attachments',
                            attachments:'124',
                        },
                        {
                            title:'Comments',
                            attachments:'452',
                        },
                        {
                            title:'Tasks',
                            attachments:'85'
                        }
                    ],
                    teams:[
                         require(`@/assets/images/xs/avatar3.jpg`),
                         require(`@/assets/images/xs/avatar1.jpg`),
                         require(`@/assets/images/xs/avatar7.jpg`),
                         require(`@/assets/images/xs/avatar9.jpg`),
                    ],
                    task_completed:
                    {
                        start_end:'55/85',
                        class:'bg-info',
                        width:'80'
                    }
                },
                {
                    title:'Orange Limited',
                    sub_title:'OnePage Landing Page HTML',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                    items:[
                        {
                            title:'Attachments',
                            attachments:'23',
                        },
                        {
                            title:'Comments',
                            attachments:'105',
                        },
                        {
                            title:'Tasks',
                            attachments:'37'
                        }
                    ],
                    teams:[
                        require(`@/assets/images/xs/avatar6.jpg`),
                        require(`@/assets/images/xs/avatar7.jpg`)
                    ],
                    task_completed:
                    {
                        start_end:'19/37',
                        class:'bg-warning',
                        width:'55'
                    }
                },
                {
                    title:'Orange Limited',
                    sub_title:'New iOS Food App Design',
                    content:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. When an unknown printer took a galley of type and scrambled it...',
                        items:[
                            {
                                title:'Attachments',
                                attachments:'45',
                            },
                            {
                                title:'Comments',
                                attachments:'210',
                            },
                            {
                                title:'Tasks',
                                attachments:'47'
                            }
                        ],
                        teams:[
                            require(`@/assets/images/xs/avatar2.jpg`),
                            require(`@/assets/images/xs/avatar4.jpg`),
                            require(`@/assets/images/xs/avatar9.jpg`),
                        ],
                        task_completed:
                        {
                            start_end:'12/47',
                            class:'bg-danger',
                            width:'23'
                        }
                    }
            ],
            categories:
            {
                title:'Categories',
                categories_name:[
                    'Rent',
                    'Sale',
                    'Top Rating',
                    'Trending',
                    'Newest Properties',
                ]
            },
            latest_news:
            {
                title:'Latest News',
                news_detals:[
                    {
                        img: require(`@/assets/images/xs/avatar7.jpg`),
                        text:'There are many variations of passages',
                        time:'10:11 AM',
                    },
                    {
                        img: require(`@/assets/images/xs/avatar3.jpg`),
                        text:'Contrary to popular belief, Lorem Ipsum is not simply random text',
                        time:'10:11 AM',
                    },
                    {
                        img: require(`@/assets/images/xs/avatar1.jpg`),
                        text:'There are many variations of passages',
                        time:'10:11 AM',
                    },
                    {
                        img: require(`@/assets/images/xs/avatar2.jpg`),
                        text:'Contrary to popular belief, Lorem Ipsum is not simply random text',
                        time:'10:11 AM',
                    }
                ]
            }
        }
    },methods:{
       
    },mounted() {
        this.$nextTick(() => {
            const list = document.getElementsByClassName("tab-card")[0];
            const detailsSection  = list.getElementsByTagName("li")[3]
            const link  = detailsSection.getElementsByTagName("a")[0]
            link.classList.remove('nav-link')
        })
    }
}
</script>
