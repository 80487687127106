<template>
    <div class="col-md-12 pt-0 mt-0">   
        <div class="row pt-0 mt-0 justify-content-center">
            
            <div class="col-12 col-lg-7 pt-0 mt-0 card px-5 receipt-main card container">
                <div class="row">
                    
                        <div class="col-md-6">
                            <div class="receipt-left">
                                <img class="img-responsive" alt="iamgurdeeposahan" src="https://bootdey.com/img/Content/avatar/avatar6.png" style="width: 71px; border-radius: 43px;">
                            </div>
                        </div>
                        <div class=" col-md-6 text-right">
                            <div class="receipt-right">
                                <h5>Company Name.</h5>
                                <p>+1 3649-6589 <i class="fa fa-phone"></i></p>
                                <p>company@gmail.com <i class="fa fa-envelope-o"></i></p>
                                <p>USA <i class="fa fa-location-arrow"></i></p>
                            </div>
                        </div>
                   
                </div>
                
                <div class="row">
                    
                        <div class=" col-md-6 text-left">
                            <div class="receipt-right">
                                <h5>Customer Name </h5>
                                <p><b>Mobile :</b> +1 12345-4569</p>
                                <p><b>Email :</b> customer@gmail.com</p>
                                <p><b>Address :</b> New York, USA</p>
                            </div>
                        </div>
                        <div class="col-md-6 mt-3 text-right">
                            
                                <h3>INVOICE # 102</h3>
                          
                        </div>
                
                </div>
                
                <div>
                    <table class="table table-bordered mt-2">
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="col-md-9">Payment for August 2016</td>
                                <td class="col-md-3">  15,000/-</td>
                            </tr>
                            <tr>
                                <td class="col-md-9">Payment for June 2016</td>
                                <td class="col-md-3">  6,00/-</td>
                            </tr>
                            <tr>
                                <td class="col-md-9">Payment for May 2016</td>
                                <td class="col-md-3">  35,00/-</td>
                            </tr>
                            <tr>
                                <td class="text-right">
                                <p>
                                    <span>Total Amount: </span>
                                </p>
                                <p>
                                    <span>Late Fees: </span>
                                </p>
                                <p>
                                    <span>Payable Amount: </span>
                                </p>
                                <p>
                                    <span>Balance Due: </span>
                                </p>
                                </td>
                                <td>
                                <p>
                                    <span>  65,500/-</span>
                                </p>
                                <p>
                                    <span>  500/-</span>
                                </p>
                                <p>
                                    <span>  1300/-</span>
                                </p>
                                <p>
                                    <span>  9500/-</span>
                                </p>
                                </td>
                            </tr>
                            <tr>
                            
                                <td class="text-right"><h2><span>Total: </span></h2></td>
                                <td class="text-left text-primary"><h2><span>  31.566/-</span></h2></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                
                <div class="row">
                    
                        <div class="col-xs-8 col-sm-8 col-md-8 text-left">
                            <div class="receipt-right">
                                <p><b>Date :</b> 15 Aug 2016</p>
                                <h5 style="color: rgb(140, 140, 140);">Thanks for shopping.!</h5>
                            </div>
                        </div>
                        <div class="col-xs-4 col-sm-4 col-md-4">
                            <div class="receipt-left">
                                <h5>Stamp</h5>
                            </div>
                        </div>
               
                </div>
                
            </div>    
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
    
    },
    methods: {
      
    },
  };
  </script>
  <style scoped lang="scss" >
  body{
    // background:#eee;
    margin-top:20px;
}
.text-danger  {
    // color: #9f181c;
}
.receipt-main {
    // background: #ffffff none repeat scroll 0 0;
    border-bottom: 12px solid ;
    border-top: 12px solid ;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 40px 30px !important;
    position: relative;
    // box-shadow: 0 1px 21px #acacac;
    // color: #333333;
    font-family: open sans;
}
.receipt-main p {
    // color: #333333;
    font-family: open sans;
    line-height: 1.42857;
}
.receipt-footer h1 {
    font-size: 15px;
    font-weight: 400 !important;
    margin: 0 !important;
}
.receipt-main::after {
    // background: #414143 none repeat scroll 0 0;
    content: "";
    height: 5px;
    left: 0;
    position: absolute;
    right: 0;
    top: -13px;
}
.receipt-main thead {
    background: var(--dark-color) none repeat scroll 0 0;
}
.receipt-main thead th {
    color:#fff;
}
.receipt-right h5 {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 7px 0;
}
.receipt-right p {
    font-size: 12px;
    margin: 0px;
}
.receipt-right p i {
    text-align: center;
    width: 18px;
}
.receipt-main td {
    padding: 9px 20px !important;
}
.receipt-main th {
    padding: 13px 20px !important;
}
.receipt-main td {
    font-size: 13px;
    font-weight: initial !important;
}
.receipt-main td p:last-child {
    margin: 0;
    padding: 0;
}	
.receipt-main td h2 {
    font-size: 20px;
    font-weight: 900;
    margin: 0;
    text-transform: uppercase;
}
.receipt-header-mid .receipt-left h1 {
    font-weight: 100;
    margin: 34px 0 0;
    text-align: right;
    text-transform: uppercase;
}
.receipt-header-mid {
    margin: 24px 0;
    overflow: hidden;
}

#container {
    // background-color: #dcdcdc;
}
  </style>