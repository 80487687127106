var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isSidebarVisible)?_c('div',{staticClass:"sidebar px-4 py-2 py-md-3 bg-sidebar",class:[
    _vm.arrowToggle ? 'sidebar-mini' : '',
    _vm.isGradient || _vm.currentLayoutStyle == 'style-13' ? '' : '',
    _vm.currentLayoutStyle == 'style-1' ? '' : '',
    _vm.currentLayoutStyle == 'style-2' ? 'sidebar-mini' : '',
    _vm.currentLayoutStyle == 'style-3' ? 'rounded no-scrollbar bg-dark my-3' : '',
    _vm.currentLayoutStyle == 'style-11' ? 'no-scrollbar' : '',
    _vm.currentLayoutStyle == 'style-12' || _vm.currentLayoutStyle == 'style-13' ? 'scrollbar-secondary' : '',
    _vm.isSidebarToggle ? 'open' : '' ]},[_c('div',{staticClass:"d-flex flex-column h-100"},[_c('h4',{staticClass:"sidebar-title text-center",class:{
        'mt-2': _vm.currentLayoutStyle !== 'style-4' && _vm.currentLayoutStyle !== 'style-13',
        'mb-1': _vm.currentLayoutStyle !== 'style-3' && _vm.currentLayoutStyle !== 'style-4' && _vm.currentLayoutStyle !== 'style-13',
        'my-3 mb-2 rounded': _vm.currentLayoutStyle == 'style-3',
        'mb-1 pb-3 mt-1 text-center': _vm.currentLayoutStyle === 'style-4' || _vm.currentLayoutStyle === 'style-13',
      }},[(!_vm.arrowToggle)?_c('img',{staticClass:"img-fluid",staticStyle:{"margin-left":"auto !important","margin-right":"auto !important","max-height":"90px"},attrs:{"src":"/images/logo3T.png","alt":""}}):_vm._e()]),(_vm.currentLayoutStyle !== 'style-4' && _vm.currentLayoutStyle !== 'style-13')?_c('form',{class:{ 'mb-3 mt-0': _vm.currentLayoutStyle == 'style-3', 'mb-2 mt-1': _vm.currentLayoutStyle != 'style-3' }},[_c('div',{staticClass:"input-group"})]):_vm._e(),(_vm.currentLayoutStyle !== 'style-3' && _vm.currentLayoutStyle !== 'style-4' && _vm.currentLayoutStyle !== 'style-13')?_c('layout-a'):_vm._e(),(_vm.currentLayoutStyle == 'style-1' || _vm.currentLayoutStyle == 'style-2')?_c('button',{staticClass:"btn btn-link sidebar-mini-btn text-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.miniSidebar()}}},[_vm._m(0)]):_vm._e(),(_vm.currentLayoutStyle === 'style-4' || _vm.currentLayoutStyle === 'style-13')?_c('layout-g'):_vm._e(),(_vm.currentLayoutStyle === 'style-4' || _vm.currentLayoutStyle === 'style-13')?_c('div',{staticClass:"px-3"},[_vm._l((_vm.menuItems),function(menuItem){return [(!menuItem.isCategory && !menuItem.isApp && menuItem.children.length == 0)?_c('router-link',{key:menuItem.name,staticClass:"py-2 d-flex align-items-center text-muted justify-content-between",attrs:{"to":("" + (menuItem.routerLink))}},[_c('span',[_vm._v(_vm._s(menuItem.name))]),(!menuItem.bedge)?_c('i',{staticClass:"fa fa-long-arrow-right ml-3"}):_vm._e(),(menuItem.bedge)?_c('span',{staticClass:"badge bg-primary rounded-pill ml-auto"},[_vm._v(_vm._s(menuItem.bedge))]):_vm._e()]):_vm._e()]})],2):_vm._e()],1)]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"ms-2"},[_c('i',{staticClass:"fa fa-arrow-left"})])}]

export { render, staticRenderFns }