<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card p-4 mb-4">
        <div class="row py-3 justify-content-between">
          <div class="card-header py-3 d-flex flex-wrap justify-content-between align-items-center  ">
            <div>
              <input @click="showModal('', 1)" type="button" class="btn btn-primary" value="Nuevo Registro" />
            </div>
            <card-actions></card-actions>
          </div>

          <div class="col-md-5">
            <b-form-group label="por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(fecha)="data">
            <span>{{ data.item.fecha != null ? $moment(data.item.fecha).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(action)="data">
            <span
              ><button @click="showModal(data.item, 2)" class="btn btn-primary ml-1">
                <i class="fa fa-pencil"></i>
              </button>
            </span>
            <span
              ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1">
                <i class="fa fa-trash-o"></i>
              </button>
            </span>

            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
            <!-- <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

            <!-- <table-action></table-action> -->
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            hide-goto-end-buttons
            hide-ellipsis
            prev-text="Prev"
            next-text="Next"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal id="exampleModalCenter" tabindex="-1" size="xl" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-4 mb-2">
              <label for="estado" class="">Rubro</label>
              <b-form-select required class="form-control select  " v-model="row.rubro" :options="rubros" value-field="nombre" text-field="nombre"> </b-form-select>
            </div>
            <div class="col-md-4 mb-2">
              <label for="nombre" class="">Nombre</label><input required v-model="row.nombre" class="form-control" type="text" name="nombre" id="nombre" />
            </div>
            <div class="col-md-4 mb-2">
              <label for="estado" class="">Municipio</label>
              <b-form-select required class="form-control select  " v-model="row.municipio" :options="municipios" value-field="nombre" text-field="nombre">
              </b-form-select>
            </div>
            <div class="col-md-6 mb-2"><label for="url" class="">Url</label><input required v-model="row.url" class="form-control" type="text" name="url" id="url" /></div>
            <div class="col-md-6 mb-2">
              <label for="direccion" class="">Dirección</label><input required class="form-control" v-model="row.direccion" type="text" name="direccion" id="direccion" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="Telefono" class="">Telefono</label><input class="form-control" v-model="row.telefono" type="text" name="telefono" id="telefono" />
            </div>
            <div class="col-md-6 mb-2">
              <label for="Telefono" class="">Célular</label><input class="form-control" v-model="row.celular" type="text" name="celular" id="celular" />
            </div>
            <div class="col-md-4">
              <label for="nombre" class="">Nombre contacto</label><input class="form-control" v-model="row.n_contacto" type="text" name="n_contacto" id="n_contacto" />
            </div>
            <div class="col-md-4"><label for="email" class="">Email</label><input class="form-control" v-model="row.email" type="text" name="email" id="email" /></div>
            <div class="col-md-4">
              <label for="facebook" class="">Facebook</label><input class="form-control" v-model="row.facebook" type="text" name="facebook" id="facebook" />
            </div>

            <div class="col-md-12 mb-2 mt-1">
              <label for="depatamento" class="">Expediente</label>
              <b-form-textarea id="textarea" v-model="row.detalle" rows="3" max-rows="6"></b-form-textarea>
            </div>
          </div>
          <div class="text-right">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CardActions from "@/components/core/CardActions.vue";
// import moment from "moment";

export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
    "card-actions": CardActions,
  },
  data() {
    return {
      options: [{ estado: "Nuevo" }, { estado: "Contactado" }, { estado: "En prueba" }, { estado: "Contratado" }, { estado: "Rechazado" }, { estado: "Despedido" }],
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        {
          key: "nombre",
          label: "Nombre",
          sortable: true,
          class: "text-center",
        },
        { key: "rubro", label: "Rubro", sortable: true, class: "text-center" },
        { key: "fecha", label: "fecha", sortable: true, class: "text-center" },

        { key: "action", label: "Acciones", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      ///////
      table: "tv",
      items: [],
      row: {},
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo registro",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      municipios: [
        { nombre: "Managua" },
        { nombre: "Altagracia" },
        { nombre: "Belén" },
        { nombre: "Buenos Aires" },
        { nombre: "Cárdenas" },
        { nombre: "Moyogalpa" },
        { nombre: "Potosí" },
        { nombre: "Rivas" },
        { nombre: "San Jorge" },
        { nombre: "San Juan del Sur" },
        { nombre: "Tola" },
      ],
      singleTable: "Registro",
      textTable: "Trabajo",
      rubros: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // const brain = require("brain.js");
    // const net = new brain.recurrent.LSTMTimeStep();
    // // net.train(["minecraft is a game"]);
    // // var a = net.run("minecraft");
    // net.train([
    //   // [8, 74, 26],
    //   // [60, 65, 58],
    //   // [76, 69, 18],
    //   [83, 48, 80],
    //   [7, 55, 5],
    //   [90, 55],
    // ]);

    // /* Predicción */
    // var result = net.run([90, 55]);
    // console.log("e->", result);
    // console.log("e", a);

    // const net = new brain.NeuralNetwork();
    // var net = new brain.recurrent.LSTMTimeStep();

    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");

    this.getData();
    this.getRubros();
  },
  methods: {
    showModal(item, id) {
      console.log("----->", item);
      if (id == 1) {
        this.modalTitle = "Nueva " + this.singleTable;
        this.row = {};
        this.$bvModal.show("exampleModalCenter");
      } else {
        this.row = item;
        this.modalTitle = "Editar " + this.singleTable;
        this.$bvModal.show("exampleModalCenter");
        console.log(this.row);
      }
    },
    async getRubros() {
      try {
        let res = await this.$store.dispatch("get", { path: "rubros/getAll" });
        if (res.length > 0) {
          this.rubros = res;
        } else {
          this.rubros = [];
        }
      } catch (error) {
        this.rubros = [];
        console.error("error", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", {
          path: this.table + "/getAll",
        });
        if (res.length > 0) {
          this.items = res;
          this.totalRows = this.items.length;
        } else {
          this.items = [];
          this.totalRows = 0;
        }
      } catch (error) {
        this.items = [];
        this.totalRows = 0;
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        this.$isLoading(true);
        console.log("save");
        let data = Object.assign({}, this.row);
        //data.empresa_id = this.company_id;
        let res;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", {
            path: this.table + "/modify/" + this.row.id,
            data: data,
          });
        } else {
          data.fecha = this.$moment.tz(new Date(), "America/Costa_Rica").format();
          res = await this.$store.dispatch("post", {
            path: this.table + "/new",
            data: data,
          });
        }
        this.$isLoading(false);

        if (res.error) {
          this.$swal({
            title: "Aviso",
            text: "Error: " + res.error,
            icon: "error",
          });
        } else {
          this.getData();
          this.row = {};
          this.$swal({
            title: "Aviso",
            text: "Datos almacenados correctamente",
            icon: "success",
          });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          this.$isLoading(true);
          let res = await this.$store.dispatch("delete", {
            path: this.table + "/delete/" + item.id,
          });
          this.$isLoading(false);

          if (res.error) {
            this.$swal({
              title: "Aviso",
              text: "Error: " + res.error,
              icon: "error",
            });
          } else {
            this.getData();
            this.$swal({
              title: "Aviso",
              text: "Eliminado correctamente",
              icon: "success",
            });
          }
        } catch (error) {
          this.items = [];
          this.$isLoading(false);
          console.error("error--->", error);
        }
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped></style>
