<template>
    <div class="col-12">
        <div class="bd-content">
            <h2 id="overview">Overview</h2>
            <p><code translate="no">&lt;b-pagination&gt;</code> is a custom input component that provides a current page number input control. The value should be bound via <code translate="no">v-model</code> in your app. Page numbers are indexed from 1. The number of pages is computed from the provided prop values for <code translate="no">total-rows</code> and <code translate="no">per-page</code>.</p>
            <b-tabs nav-class="tab-card px-3 ">
                <b-tab title="Preview" active>
                    <b-card class="mb-3">
                        <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        prev-text="Previous"
                        next-text="Next"
                        hide-goto-end-buttons
                        hide-ellipsis
                        ></b-pagination>
                    </b-card>
                </b-tab>
                <b-tab title="HTML">
                    <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-pagination
        v-model=&quot;currentPage&quot;
        :total-rows=&quot;rows&quot;
        :per-page=&quot;perPage&quot;
        prev-text=&quot;Previous&quot;
        next-text=&quot;Next&quot;
        hide-goto-end-buttons
        hide-ellipsis
    &gt;&lt;/b-pagination&gt;
&lt;/b-card&gt;</code>
</pre>                 
                    </b-card>
                </b-tab>
            </b-tabs>

            <div class="border-top mt-5 pt-3">
                <h5 id="working-with-icons">Working with icons</h5>
                <p>Looking to use an icon or symbol in place of text for some pagination links? Be sure to provide proper screen reader support with <code>aria</code> attributes.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-pagination
                            v-model="iconPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            prev-text="«"
                            next-text="»"
                            hide-goto-end-buttons
                            hide-ellipsis
                            ></b-pagination>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-pagination
        v-model=&quot;iconPage&quot;
        :total-rows=&quot;rows&quot;
        :per-page=&quot;perPage&quot;
        prev-text=&quot;«&quot;
        next-text=&quot;»&quot;
        hide-goto-end-buttons
        hide-ellipsis
    &gt;&lt;/b-pagination&gt;
&lt;/b-card&gt;</code>
    </pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h5 id="disabled-and-active-states">Disabled and active states</h5>
                <p>Pagination links are customizable for different circumstances. Use <code>.disabled</code> for links that appear un-clickable and <code>.active</code> to indicate the current page.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-pagination 
                            prev-class="disabled"
                            v-model="disabledPage"
                            :total-rows="rows"
                            :per-page="perPage"
                            prev-text="Previous"
                            next-text="Next"
                            hide-goto-end-buttons
                            hide-ellipsis
                            ></b-pagination>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-pagination 
        prev-class=&quot;disabled&quot;
        v-model=&quot;disabledPage&quot;
        :total-rows=&quot;rows&quot;
        :per-page=&quot;perPage&quot;
        prev-text=&quot;Previous&quot;
        next-text=&quot;Next&quot;
        hide-goto-end-buttons
        hide-ellipsis
    &gt;&lt;/b-pagination&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h5 id="sizing">Sizing</h5>
                <p>Optionally change from the default button size by setting the <code translate="no">size</code> prop to either <code translate="no">'sm'</code> for smaller buttons or <code translate="no">'lg'</code> for larger buttons.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-pagination v-model="lgPage" :per-page="perPage" :next-class="'hidden'" :prev-class="'hidden'" :total-rows="rows" size="lg" hide-goto-end-buttons hide-ellipsis></b-pagination>
                            <b-pagination v-model="mdPage" :per-page="perPage" :next-class="'hidden'" :prev-class="'hidden'" :total-rows="rows" hide-goto-end-buttons hide-ellipsis></b-pagination>
                            <b-pagination v-model="smPage" :per-page="perPage" :next-class="'hidden'" :prev-class="'hidden'" :total-rows="rows" size="sm" hide-goto-end-buttons hide-ellipsis></b-pagination>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-pagination v-model=&quot;lgPage&quot; :per-page=&quot;perPage&quot; :next-class=&quot;&#039;hidden&#039;&quot; :prev-class=&quot;&#039;hidden&#039;&quot; :total-rows=&quot;rows&quot; size=&quot;lg&quot; hide-goto-end-buttons hide-ellipsis&gt;&lt;/b-pagination&gt;
    &lt;b-pagination v-model=&quot;mdPage&quot; :per-page=&quot;perPage&quot; :next-class=&quot;&#039;hidden&#039;&quot; :prev-class=&quot;&#039;hidden&#039;&quot; :total-rows=&quot;rows&quot; hide-goto-end-buttons hide-ellipsis&gt;&lt;/b-pagination&gt;
    &lt;b-pagination v-model=&quot;smPage&quot; :per-page=&quot;perPage&quot; :next-class=&quot;&#039;hidden&#039;&quot; :prev-class=&quot;&#039;hidden&#039;&quot; :total-rows=&quot;rows&quot; size=&quot;sm&quot; hide-goto-end-buttons hide-ellipsis&gt;&lt;/b-pagination&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h5 id="alignment">Alignment</h5>
                <p>By default the pagination component is left aligned. Change the alignment to <code>center</code>, <code>right</code> (<code>right</code> is an alias for <code>end</code>), or <code>fill</code> by setting the prop <code>align</code> to the appropriate value.</p>
               <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-pagination v-model="align"   :per-page="perPage" prev-class="disabled"  prev-text="Previous"  next-text="Next" hide-goto-end-buttons hide-ellipsis :total-rows="rows"></b-pagination>
                            <b-pagination v-model="alignCenter"   :per-page="perPage" prev-class="disabled"  prev-text="Previous"  next-text="Next" hide-goto-end-buttons hide-ellipsis :total-rows="rows" align="center"></b-pagination>
                            <b-pagination v-model="alignright"   :per-page="perPage" prev-class="disabled"  prev-text="Previous"  next-text="Next" hide-goto-end-buttons hide-ellipsis :total-rows="rows" align="right"></b-pagination>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-pagination v-model=&quot;lgPage&quot; :per-page=&quot;perPage&quot; :next-class=&quot;&#039;hidden&#039;&quot; :prev-class=&quot;&#039;hidden&#039;&quot; :total-rows=&quot;rows&quot; size=&quot;lg&quot; hide-goto-end-buttons hide-ellipsis&gt;&lt;/b-pagination&gt;
    &lt;b-pagination v-model=&quot;mdPage&quot; :per-page=&quot;perPage&quot; :next-class=&quot;&#039;hidden&#039;&quot; :prev-class=&quot;&#039;hidden&#039;&quot; :total-rows=&quot;rows&quot; hide-goto-end-buttons hide-ellipsis&gt;&lt;/b-pagination&gt;
    &lt;b-pagination v-model=&quot;smPage&quot; :per-page=&quot;perPage&quot; :next-class=&quot;&#039;hidden&#039;&quot; :prev-class=&quot;&#039;hidden&#039;&quot; :total-rows=&quot;rows&quot; size=&quot;sm&quot; hide-goto-end-buttons hide-ellipsis&gt;&lt;/b-pagination&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'PaginationComponent',
    data() {
      return {
        rows: 100,
        perPage: 10,
        currentPage: 1,
        iconPage: 1,
        disabledPage:1,
        smPage:1,
        mdPage:1,
        lgPage:1,
        align:1,
        alignCenter:1,
        alignright:1

      }
    },methods:{
        
    },mounted() {
        Prism.highlightAll()
        
    }
}
</script>