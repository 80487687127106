<template>
    <div class="col-12">
        <div class="bd-content ps-lg-4">
            <h2 id="how-it-works">How it works</h2>
            <p>The <code>v-b-scrollspy</code> directive has a few requirements to function properly:</p>
            <div class="alert alert-danger" role="alert">
                <strong>Scrollspy</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/directives/scrollspy" target="_blank">Bootstrap Scrollspy documentation <i class="fa fa-external-link"></i></a>
            </div>
            <ul> 
                <li>It must be applied on the element/component containing the <code>&lt;b-nav&gt;</code> or <code>&lt;b-list-group&gt;</code> component(s) where you want to indicate which link is currently active.</li> 
                <li>Scrollspy requires <code>position: relative;</code> on the element you're spying on, usually the <code>&lt;body&gt;</code>.</li> 
                <li>When spying on elements other than the <code>&lt;body&gt;</code>, be sure to have a <code>height</code> set and <code>overflow-y: scroll;</code> applied.</li> 
                <li>Anchors (<code>&lt;a&gt;</code>, <code>&lt;b-nav-item&gt;</code>, <code>&lt;b-dropdown-item&gt;</code>, <code>&lt;b-list-group-item&gt;</code>) are required and must have an <code>href</code> (either via the <code>href</code> or <code>to</code> props) that points to an element with that <code>id</code> in the container you are spying on. When using the <code>to</code> prop, either set the <code>path</code> ending with <code>#id-of-element</code>, or set the location property <code>hash</code> to <code>#id-of-element</code>.</li>
            </ul>
            <p>When successfully implemented, your nav or list group will update accordingly, moving the <code>.active</code> class from one item to the next based on their associated targets.</p>

            <div class="border-top mt-5 pt-3">
                <h5 id="example-in-navbar">Example in navbar</h5>
                <p>Using <code>v-b-scrollspy</code> on a <code>&lt;b-nav&gt;</code> component to monitor the scrolling on <code>&lt;b-card-body&gt;</code>. Scroll the area below the navbar and watch the active class change. The dropdown items will be highlighted as well.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-navbar type="light" variant="light" tag="div" toggleable="lg">
                                <div class="container-fluid">
                                    <b-navbar-brand href="#">NavBar</b-navbar-brand>
                                    <b-nav pills card-header slot="header" v-b-scrollspy:nav-scroller>
                                        <b-nav-item href="#fat" @click="scrollIntoView">@fat</b-nav-item>
                                        <b-nav-item href="#mdo" @click="scrollIntoView">@mdo</b-nav-item>
                                        <b-nav-item-dropdown text="Dropdown" right-alignment>
                                            <b-dropdown-item href="#one" @click="scrollIntoView">one</b-dropdown-item>
                                            <b-dropdown-item href="#two" @click="scrollIntoView">two</b-dropdown-item>
                                            <b-dropdown-divider></b-dropdown-divider>
                                            <b-dropdown-item href="#three" @click="scrollIntoView">three</b-dropdown-item>
                                        </b-nav-item-dropdown>
                                    </b-nav>
                                </div>
                            </b-navbar> 
                            <div class="mt-2" id="nav-scroller" ref="content"  style="height: 200px; overflow-y: auto; position: relative;">
                                <h5 id="fat">@fat</h5>
                                <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic. Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
                                <h5 id="mdo">@mdo</h5>
                                <p>Veniam marfa mustache skateboard, adipisicing fugiat velit pitchfork beard. Freegan beard aliqua cupidatat mcsweeney's vero. Cupidatat four loko nisi, ea helvetica nulla carles. Tattooed cosby sweater food truck, mcsweeney's quis non freegan vinyl. Lo-fi wes anderson +1 sartorial. Carles non aesthetic exercitation quis gentrify. Brooklyn adipisicing craft beer vice keytar deserunt.</p>
                                <h5 id="one">one</h5>
                                <p>Occaecat commodo aliqua delectus. Fap craft beer deserunt skateboard ea. Lomo bicycle rights adipisicing banh mi, velit ea sunt next level locavore single-origin coffee in magna veniam. High life id vinyl, echo park consequat quis aliquip banh mi pitchfork. Vero VHS est adipisicing. Consectetur nisi DIY minim messenger bag. Cred ex in, sustainable delectus consectetur fanny pack iphone.</p>
                                <h5 id="two">two</h5>
                                <p>In incididunt echo park, officia deserunt mcsweeney's proident master cleanse thundercats sapiente veniam. Excepteur VHS elit, proident shoreditch +1 biodiesel laborum craft beer. Single-origin coffee wayfarers irure four loko, cupidatat terry richardson master cleanse. Assumenda you probably haven't heard of them art party fanny pack, tattooed nulla cardigan tempor ad. Proident wolf nesciunt sartorial keffiyeh eu banh mi sustainable. Elit wolf voluptate, lo-fi ea portland before they sold out four loko. Locavore enim nostrud mlkshk brooklyn nesciunt.</p>
                                <h5 id="three">three</h5>
                                <p>Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan mcsweeney's photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim qui you probably haven't heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic. Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.</p>
                                <p>Keytar twee blog, culpa messenger bag marfa whatever delectus food truck. Sapiente synth id assumenda. Locavore sed helvetica cliche irony, thundercats you probably haven't heard of them consequat hoodie gluten-free lo-fi fap aliquip. Labore elit placeat before they sold out, terry richardson proident brunch nesciunt quis cosby sweater pariatur keffiyeh ut helvetica artisan. Cardigan craft beer seitan readymade velit. VHS chambray laboris tempor veniam. Anim mollit minim commodo ullamco thundercats.</p>
                            </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-navbar type=&quot;light&quot; variant=&quot;light&quot; tag=&quot;div&quot;&gt;
        &lt;div class=&quot;container-fluid&quot;&gt;
            &lt;b-navbar-brand href=&quot;#&quot;&gt;NavBar&lt;/b-navbar-brand&gt;
            &lt;b-nav pills card-header slot=&quot;header&quot; v-b-scrollspy:nav-scroller&gt;
                &lt;b-nav-item href=&quot;#fat&quot; @click=&quot;scrollIntoView&quot;&gt;@fat&lt;/b-nav-item&gt;
                &lt;b-nav-item href=&quot;#mdo&quot; @click=&quot;scrollIntoView&quot;&gt;@mdo&lt;/b-nav-item&gt;
                &lt;b-nav-item-dropdown text=&quot;Dropdown&quot; right-alignment&gt;
                    &lt;b-dropdown-item href=&quot;#one&quot; @click=&quot;scrollIntoView&quot;&gt;one&lt;/b-dropdown-item&gt;
                    &lt;b-dropdown-item href=&quot;#two&quot; @click=&quot;scrollIntoView&quot;&gt;two&lt;/b-dropdown-item&gt;
                    &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
                    &lt;b-dropdown-item href=&quot;#three&quot; @click=&quot;scrollIntoView&quot;&gt;three&lt;/b-dropdown-item&gt;
                &lt;/b-nav-item-dropdown&gt;
            &lt;/b-nav&gt;
        &lt;/div&gt;
    &lt;/b-navbar&gt; 
    &lt;div class=&quot;mt-2&quot; id=&quot;nav-scroller&quot; ref=&quot;content&quot;  style=&quot;height: 200px; overflow-y: auto; position: relative;&quot;&gt;
        &lt;h5 id=&quot;fat&quot;&gt;@fat&lt;/h5&gt;
        &lt;p&gt;Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan mcsweeney&#039;s photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim qui you probably haven&#039;t heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic. Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.&lt;/p&gt;
        &lt;h5 id=&quot;mdo&quot;&gt;@mdo&lt;/h5&gt;
        &lt;p&gt;Veniam marfa mustache skateboard, adipisicing fugiat velit pitchfork beard. Freegan beard aliqua cupidatat mcsweeney&#039;s vero. Cupidatat four loko nisi, ea helvetica nulla carles. Tattooed cosby sweater food truck, mcsweeney&#039;s quis non freegan vinyl. Lo-fi wes anderson +1 sartorial. Carles non aesthetic exercitation quis gentrify. Brooklyn adipisicing craft beer vice keytar deserunt.&lt;/p&gt;
        &lt;h5 id=&quot;one&quot;&gt;one&lt;/h5&gt;
        &lt;p&gt;Occaecat commodo aliqua delectus. Fap craft beer deserunt skateboard ea. Lomo bicycle rights adipisicing banh mi, velit ea sunt next level locavore single-origin coffee in magna veniam. High life id vinyl, echo park consequat quis aliquip banh mi pitchfork. Vero VHS est adipisicing. Consectetur nisi DIY minim messenger bag. Cred ex in, sustainable delectus consectetur fanny pack iphone.&lt;/p&gt;
        &lt;h5 id=&quot;two&quot;&gt;two&lt;/h5&gt;
        &lt;p&gt;In incididunt echo park, officia deserunt mcsweeney&#039;s proident master cleanse thundercats sapiente veniam. Excepteur VHS elit, proident shoreditch +1 biodiesel laborum craft beer. Single-origin coffee wayfarers irure four loko, cupidatat terry richardson master cleanse. Assumenda you probably haven&#039;t heard of them art party fanny pack, tattooed nulla cardigan tempor ad. Proident wolf nesciunt sartorial keffiyeh eu banh mi sustainable. Elit wolf voluptate, lo-fi ea portland before they sold out four loko. Locavore enim nostrud mlkshk brooklyn nesciunt.&lt;/p&gt;
        &lt;h5 id=&quot;three&quot;&gt;three&lt;/h5&gt;
        &lt;p&gt;Ad leggings keytar, brunch id art party dolor labore. Pitchfork yr enim lo-fi before they sold out qui. Tumblr farm-to-table bicycle rights whatever. Anim keffiyeh carles cardigan. Velit seitan mcsweeney&#039;s photo booth 3 wolf moon irure. Cosby sweater lomo jean shorts, williamsburg hoodie minim qui you probably haven&#039;t heard of them et cardigan trust fund culpa biodiesel wes anderson aesthetic. Nihil tattooed accusamus, cred irony biodiesel keffiyeh artisan ullamco consequat.&lt;/p&gt;
        &lt;p&gt;Keytar twee blog, culpa messenger bag marfa whatever delectus food truck. Sapiente synth id assumenda. Locavore sed helvetica cliche irony, thundercats you probably haven&#039;t heard of them consequat hoodie gluten-free lo-fi fap aliquip. Labore elit placeat before they sold out, terry richardson proident brunch nesciunt quis cosby sweater pariatur keffiyeh ut helvetica artisan. Cardigan craft beer seitan readymade velit. VHS chambray laboris tempor veniam. Anim mollit minim commodo ullamco thundercats.&lt;/p&gt;
    &lt;/div&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h5 id="example-in-navbar">Example in navbar</h5>
                <p>Scrollspy also works with nested <code>&lt;b-nav&gt;</code>. If a nested <code>&lt;b-nav-item&gt;</code> is active, its parent()s will also be active. Scroll the area next to the navbar and watch the active class change.</p>

                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-row>
                                <b-col cols="4">
                                    <b-navbar v-b-scrollspy:scrollspy-nested class="flex-column bg-light">
                                    <b-navbar-brand href="#">Navbar</b-navbar-brand>
                                    <b-nav pills vertical>
                                        <b-nav-item href="#item-1">Item 1</b-nav-item>
                                        <b-nav pills vertical>
                                        <b-nav-item class="ml-3 my-1" href="#item-1-1">Item 1-1</b-nav-item>
                                        <b-nav-item class="ml-3 my-1" href="#item-1-2">Item 1-2</b-nav-item>
                                        </b-nav>
                                        <b-nav-item href="#item-2">Item 2</b-nav-item>
                                        <b-nav-item href="#item-3">Item 3</b-nav-item>
                                        <b-nav pills vertical>
                                        <b-nav-item class="ml-3 my-1" href="#item-3-1">Item 3-1</b-nav-item>
                                        <b-nav-item class="ml-3 my-1" href="#item-3-2">Item 3-2</b-nav-item>
                                        </b-nav>
                                    </b-nav>
                                    </b-navbar>
                                </b-col>
                                <b-col cols="8">
                                        <div data-spy="scroll" data-bs-target="#navbar-example2" data-offset="0" tabindex="0" style="height: 345px; overflow-y: auto; position: relative;">
                                            <h4 id="item-1">Item 1</h4>
                                            <p>Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.</p>
                                            <h5 id="item-1-1">Item 1-1</h5>
                                            <p>Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.</p>
                                            <h5 id="item-1-2">Item 1-2</h5>
                                            <p>Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.</p>
                                            <h4 id="item-2">Item 2</h4>
                                            <p>Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.</p>
                                            <h4 id="item-3">Item 3</h4>
                                            <p>Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.</p>
                                            <h5 id="item-3-1">Item 3-1</h5>
                                            <p>Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.</p>
                                            <h5 id="item-3-2">Item 3-2</h5>
                                            <p>Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.</p>
                                        </div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-row&gt;
        &lt;b-col cols=&quot;4&quot;&gt;
            &lt;b-navbar v-b-scrollspy:scrollspy-nested class=&quot;flex-column bg-light&quot;&gt;
            &lt;b-navbar-brand href=&quot;#&quot;&gt;Navbar&lt;/b-navbar-brand&gt;
            &lt;b-nav pills vertical&gt;
                &lt;b-nav-item href=&quot;#item-1&quot;&gt;Item 1&lt;/b-nav-item&gt;
                &lt;b-nav pills vertical&gt;
                &lt;b-nav-item class=&quot;ml-3 my-1&quot; href=&quot;#item-1-1&quot;&gt;Item 1-1&lt;/b-nav-item&gt;
                &lt;b-nav-item class=&quot;ml-3 my-1&quot; href=&quot;#item-1-2&quot;&gt;Item 1-2&lt;/b-nav-item&gt;
                &lt;/b-nav&gt;
                &lt;b-nav-item href=&quot;#item-2&quot;&gt;Item 2&lt;/b-nav-item&gt;
                &lt;b-nav-item href=&quot;#item-3&quot;&gt;Item 3&lt;/b-nav-item&gt;
                &lt;b-nav pills vertical&gt;
                &lt;b-nav-item class=&quot;ml-3 my-1&quot; href=&quot;#item-3-1&quot;&gt;Item 3-1&lt;/b-nav-item&gt;
                &lt;b-nav-item class=&quot;ml-3 my-1&quot; href=&quot;#item-3-2&quot;&gt;Item 3-2&lt;/b-nav-item&gt;
                &lt;/b-nav&gt;
            &lt;/b-nav&gt;
            &lt;/b-navbar&gt;
        &lt;/b-col&gt;
        &lt;b-col cols=&quot;8&quot;&gt;
                &lt;div data-spy=&quot;scroll&quot; data-bs-target=&quot;#navbar-example2&quot; data-offset=&quot;0&quot; tabindex=&quot;0&quot; style=&quot;height: 345px; overflow-y: auto; position: relative;&quot;&gt;
                    &lt;h4 id=&quot;item-1&quot;&gt;Item 1&lt;/h4&gt;
                    &lt;p&gt;Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.&lt;/p&gt;
                    &lt;h5 id=&quot;item-1-1&quot;&gt;Item 1-1&lt;/h5&gt;
                    &lt;p&gt;Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.&lt;/p&gt;
                    &lt;h5 id=&quot;item-1-2&quot;&gt;Item 1-2&lt;/h5&gt;
                    &lt;p&gt;Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.&lt;/p&gt;
                    &lt;h4 id=&quot;item-2&quot;&gt;Item 2&lt;/h4&gt;
                    &lt;p&gt;Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.&lt;/p&gt;
                    &lt;h4 id=&quot;item-3&quot;&gt;Item 3&lt;/h4&gt;
                    &lt;p&gt;Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.&lt;/p&gt;
                    &lt;h5 id=&quot;item-3-1&quot;&gt;Item 3-1&lt;/h5&gt;
                    &lt;p&gt;Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.&lt;/p&gt;
                    &lt;h5 id=&quot;item-3-2&quot;&gt;Item 3-2&lt;/h5&gt;
                    &lt;p&gt;Ex consequat commodo adipisicing exercitation aute excepteur occaecat ullamco duis aliqua id magna ullamco eu. Do aute ipsum ipsum ullamco cillum consectetur ut et aute consectetur labore. Fugiat laborum incididunt tempor eu consequat enim dolore proident. Qui laborum do non excepteur nulla magna eiusmod consectetur in. Aliqua et aliqua officia quis et incididunt voluptate non anim reprehenderit adipisicing dolore ut consequat deserunt mollit dolore. Aliquip nulla enim veniam non fugiat id cupidatat nulla elit cupidatat commodo velit ut eiusmod cupidatat elit dolore.&lt;/p&gt;
                &lt;/div&gt;
        &lt;/b-col&gt;
    &lt;/b-row&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'ScrollspyComponent',
    data() {
      return {
        
      }
    },methods: {
      scrollIntoView(event) {
        event.preventDefault()
        const href = event.target.getAttribute('href')
        const el = href ? document.querySelector(href) : null
        if (el) {
          this.$refs.content.scrollTop = el.offsetTop
        }
      }
    },mounted() {
        Prism.highlightAll();
    }
}
</script>
