<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card px-3 mb-4">
        <div class="row justify-content-between">
          <div class="img-bg tasks card-header d-flex flex-wrap justify-content-between align-items-center">
            <div class="">
              <p class="h3 pb-2">Tareas</p>
              <button @click="showModal('', 1)" class="btn btn-primary mr-2"><i class="fa fa-plus mr-2"></i>Nueva tarea</button>

              <b-dropdown
                id="hover-text-white"
                :variant="filters == 0 ? 'secondary' : filters == 1 ? 'danger' : filters == 2 ? 'warning' : filters == 3 ? 'info' : filters == 4 ? 'success' : 'dark'"
              >
                <template #button-content>
                  <span class="px-2" :style="filters == 0 ? '' : 'display:none;'"> <i class="fa fa-cogs"></i> Filtros </span>
                  <span class="px-2" :style="filters == 1 ? '' : 'display:none;'"> <i class="fa fa-hourglass-half"></i> Pendientes </span>
                  <span class="px-2" :style="filters == 2 ? '' : 'display:none;'"> <i class="fa fa-cog"></i> En proceso </span>
                  <span class="px-2" :style="filters == 3 ? '' : 'display:none;'"> <i class="fa fa-check"></i> Finalizado </span>
                  <span class="px-2" :style="filters == 4 ? '' : 'display:none;'"> <i class="fa fa-clipboard"></i> Todas </span>
                </template>
                <b-dropdown-item @click="filtro(1)"
                  ><span class="text-danger"><i class="fa fa-hourglass-half mr-2"></i> Pendientes</span></b-dropdown-item
                >
                <b-dropdown-item @click="filtro(2)"
                  ><span class="text-warning"><i class="fa fa-cog mr-2"></i> En proceso</span></b-dropdown-item
                >
                <b-dropdown-item @click="filtro(3)"
                  ><span class="text-info"><i class="fa fa-check mr-2"></i> Finalizado</span></b-dropdown-item
                >
                <b-dropdown-item @click="filtro(4)"
                  ><span class="text-success"><i class="fa fa-clipboard mr-2"></i> Todas</span></b-dropdown-item
                >
              </b-dropdown>
            </div>
            <card-actions></card-actions>
          </div>

          <div class="col-md-5">
            <b-form-group label="Por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
          class="mt-3"
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(fecha_alta)="data">
            <span>{{ data.item.fecha_alta != null ? $moment(data.item.fecha_alta).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(fecha_inicio)="data">
            <span>{{ data.item.fecha_inicio != null ? $moment(data.item.fecha_inicio).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(fecha_fin)="data">
            <span>{{ data.item.fecha_fin != null ? $moment(data.item.fecha_fin).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(action)="data">
            <span
              ><button @click="showModal(data.item, 2)" class="btn btn-primary ml-1"><i class="fa fa-pencil"></i></button>
            </span>
            <span
              ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1"><i class="fa fa-trash-o"></i></button>
            </span>

            <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
            <!-- <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

            <!-- <table-action></table-action> -->
          </template>
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            hide-goto-end-buttons
            hide-ellipsis
            prev-text="Prev"
            next-text="Next"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>

    <b-modal id="exampleModalCenter" tabindex="-1" class="" size="xl" centered :title="modalTitle" hide-footer>
      <template v-slot:modal-header-close>
        <button type="button" class="btn btn-close"></button>
      </template>
      <div class="modal-body bg-gray">
        <form @submit.prevent="saveData()">
          <div class="row">
            <div class="col-md-9">
              <div class="col-md-12 mb-2">
                <label for="nombre" class="">Titulo</label><input required v-model="row.titulo" class="form-control" type="text" name="nombre" id="nombre" />
              </div>
              <div class="col-md-12 mb-2">
                <label for="nombre" class="">Descripción</label><b-form-textarea v-model="row.descripcion" rows="3" id="textarea-default"></b-form-textarea>
              </div>

              <div class="col-md-12 mb-2">
                <label for="tipo" class=""
                  >CheckList <button @click.prevent="addItemCheckList()" class="btn btn-primary p-1 ml-4 pt-0 pb-0"><i class="fa fa-plus"></i></button>
                </label>
                <b-progress :max="checklist.length" show-progress v-if="checklist.length > 0" variant="success" class="mb-3">
                  <b-progress-bar
                    :value="checklist.filter((x) => x.terminado == 1).length"
                    :label-html="`<b style='color:black;font-size:15px'>${((checklist.filter((x) => x.terminado == 1).length / checklist.length) * 100).toFixed(2)} %</b>`"
                  ></b-progress-bar>
                </b-progress>

                <template v-if="checklist.length > 0">
                  <div class="row mt-2" v-for="(iteml, indexf) of checklist" :key="iteml.idtmp">
                    <div class="col-md-10">
                      <textarea required v-model="iteml.titulo" class="form-control" type="text" rows="1" />
                    </div>
                    <!-- <div class="col-md-2 text-center">
                          <span class="text-white mr-1 pointer" @click="changeOrderItem(iteml, 0)">↑</span><span class="text-white">{{ iteml.orden }}</span
                          ><span class="text-white ml-1 pointer" @click="changeOrderItem(iteml, 1)">↓</span>
                        </div> -->
                    <div class="col-md-1 text-left">
                      <b-form-checkbox class="mt-2" size="lg" :id="'checkbox-1' + indexf" v-model="iteml.terminado" :name="'checkbox-1' + indexf" value="1" unchecked-value="0">
                        <!-- <span for="terminado" class="h6">Fin</span> -->
                      </b-form-checkbox>
                    </div>
                    <div class="col-md-1">
                      <button @click.prevent="removeItemChecklist(iteml)" class="btn btn-danger p-1 ml-2 mt-2 pt-0 pb-0"><i class="fa fa-trash-o"></i></button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
            <div class="col-md-3">
              <!-- <div class="col-md-6 mb-2">
              <label for="nombre" class="">Proyecto</label
              ><b-form-select class="form-select" value-field="id" text-field="titulo" v-model="row.proyecto_id" :options="projects"></b-form-select>
            </div> -->

              <!-- <div class="col-md-6 mb-2">
                            <label for="nombre" class="">Proyecto</label><b-form-select  class="form-select" :value="'estado'" text-value="estado" text-field="estado" v-model="row.estado" :options="options"></b-form-select>
                        </div> -->
              <div class="col-md-12 mb-2">
                <label for="precio venta" class="">Fecha Inicio</label
                ><input required class="form-control" v-model="row.fecha_inicio" type="date" name="fechainicio" id="fechainicio" placeholder="" />
              </div>
              <div class="col-md-12 mb-2">
                <label for="precio venta" class="">Fecha fin</label
                ><input required class="form-control" v-model="row.fecha_fin" type="date" name="fechaFin" id="fechafin" placeholder="" />
              </div>

              <!-- <div class="col-md-4 mb-2">
              <label for="existencia" class="">Horas Asignadas</label
              ><input class="form-control" v-model="row.horas_asignadas" type="text" name="horasasignadas" id="" placeholder="0" />
            </div> -->
              <!-- <div class="col-md-4 mb-2">
              <label for="existencia" class="">Horas trabajadas</label
              ><input class="form-control" v-model="row.horas_trabajadas" type="text" name="horastrabajadas" id="" placeholder="0" />
            </div> -->
              <div class="col-md-12 mb-2" v-if="checklist.length > 0">
                <label for="existencia" class="">Por terminada</label
                ><input class="form-control" v-model="row.porterminada" type="text" name="porterminada" id="" placeholder="0" />
              </div>
            </div>
          </div>
          <div class="text-right mt-3">
            <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('exampleModalCenter')">Cerrar</b-button>
            <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import { EventBus } from "@/js/event-bus.js";

import CardActions from "@/components/core/CardActions.vue";
export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
    "card-actions": CardActions,
  },
  data() {
    return {
      filters: 0,
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        { key: "titulo", label: "Titulo", sortable: true, class: "text-center" },
        { key: "fecha_inicio", label: "fecha inicio", sortable: true, class: "text-center" },
        { key: "fecha_fin", label: "fecha fin", sortable: true, class: "text-center" },

        { key: "action", label: "", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      ///////
      table: "tareas",
      items: [],
      row: {
        titulo: "",
        descripcion: "",
      },
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nueva tarea",

      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      projects: [],
      proyecto_id: 0,
      checklist: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    EventBus.$emit("isToogle", false);

    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");

    this.totalRows = this.items.length;
    this.getData();
    this.getProyectos();
  },
  methods: {
    async saveItemChecklist(dataf, tareaId) {
      let datasaveItemChecklist = dataf;
      delete datasaveItemChecklist.idtmp;
      let id = datasaveItemChecklist.id;
      if (Object.prototype.hasOwnProperty.call(datasaveItemChecklist, "id")) {
        delete datasaveItemChecklist.id;
        datasaveItemChecklist.fecha_terminada = this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD H:mm:ss");
        await this.$store.dispatch("put", { path: "tarea_checklist/modify/" + id, data: datasaveItemChecklist });
      } else {
        datasaveItemChecklist.tarea_id = tareaId;
        datasaveItemChecklist.fecha_agregada = this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD H:mm:ss");
        await this.$store.dispatch("post", { path: "tarea_checklist/new", data: datasaveItemChecklist });
      }
      // await this.getChecklist(data.meca_ka-nban_item_id);

      //await this.saveData();
      //   this.$toast.success("Checklist guardado correctamente!", {
      //     timeout: 1000,
      //   });
    },
    async removeItemChecklist(data) {
      try {
        if (Object.prototype.hasOwnProperty.call(data, "id")) {
          await this.$store.dispatch("delete", { path: "/tarea_checklist/delete/" + data.id });
        } else {
          let index = this.checklist.indexOf((x) => x == data);
          this.checklist.splice(index, 1);
        }
        console.log("this.row.id", this.row.id);
        await this.getCheckList(this.row.id);
        this.$toast.success("Eliminado correctamente!", {
          timeout: 2000,
        });
      } catch (error) {
        console.log("error", error);
      }
    },
    addItemCheckList() {
      this.checklist.push({ idtmp: this.checklist.length + 1, titulo: null, terminado: 0, orden: this.checklist.length + 1 });
      this.$forceUpdate();
    },
    async getCheckList(id) {
      try {
        let res = await this.$store.dispatch("get", { path: "tarea_checklist/getAllByTaskId/" + id });
        if (res.length > 0) {
          this.checklist = res;
        } else {
          this.checklist = [];
        }
      } catch (error) {
        this.checklist = [];
        console.error("error", error);
      }
    },

    filtro(item) {
      this.filters = item;
    },
    showModal(item, id) {
      console.log("----->", item);
      if (id == 1) {
        this.modalTitle = "Nueva tarea";
        this.row = {
          titulo: "",
          descripcion: "",
          fecha_inicio: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
          fecha_fin: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        };
        this.checklist = [];
        this.$bvModal.show("exampleModalCenter");
      } else {
        this.row = item;
        this.getCheckList(this.row.id);
        this.modalTitle = "Editar tarea";
        this.$bvModal.show("exampleModalCenter");
        this.row.fecha_inicio = moment(this.row.fecha_inicio).format("YYYY-MM-DD");
        this.row.fecha_fin = moment(this.row.fecha_fin).format("YYYY-MM-DD");
        console.log(this.row);
      }
    },
    async getProyectos() {
      try {
        let res = await this.$store.dispatch("get", { path: "proyectos/getAllByUser/" + this.userId });
        if (res.length > 0) {
          this.projects = res;
        }
      } catch (error) {
        this.projects = [];
        console.error("error", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", { path: "tareasEmpleado/" + this.userId });
        if (res.length > 0) {
          this.items = res;
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        console.log("save");
        let data = { ...this.row };
        data.empresa_id = this.company_id;
        data.usuario_de = this.userId;
        data.usuario_para = this.userId;
        delete data.empresa_id;
        delete data.nombreusuario;
        let res;
        let tareaId = null;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", { path: "/tareas/" + this.row.id, data: data });
          tareaId = this.row.id;
        } else {
          res = await this.$store.dispatch("post", { path: this.table, data: data });
          tareaId = res.insertId;
        }
        console.log("tarea id--->", tareaId);
        if (this.checklist.length > 0) {
          for await (let checkItem of this.checklist) {
            await this.saveItemChecklist(checkItem, tareaId);
          }
        }
        if (res.error) {
          //   this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          this.$toast.error("Error!:" + res.error, {
            timeout: 5000,
          });
        } else {
          this.getData();
          this.getCheckList(tareaId);

          //this.row = {};
          //   this.$swal({ title: "Aviso", text: "Datos almacenados correctamente", icon: "success" });
          this.$toast.success("Datos almacenados correctamente!", {
            timeout: 1000,
          });
          //   this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          let res = await this.$store.dispatch("delete", { path: this.table + "/delete/" + item.id });
          if (res.error) {
            this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          } else {
            this.getData();
            this.$swal({ title: "Aviso", text: "Eliminado correctamente", icon: "success" });
          }
        } catch (error) {
          this.items = [];
          console.error("error--->", error);
        }
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped>
.hover-text-white {
}
#hover-text-white:hover {
  color: white !important;
}
</style>
