<template>
    <div class="col-12">
        <div class="bd-content">
            <p class="bd-lead">Push notifications to your visitors with a <code>&lt;b-toast&gt;</code> and <code>&lt;b-toaster&gt;</code>, lightweight components which are easily customizable for generating alert messages.</p>
            <p>Toasts are lightweight notifications designed to mimic the push notifications that have been popularized by mobile and desktop operating systems.
            <p>Toasts are intended to be small interruptions to your visitors or users, and therefore should contain minimal, to-the-point, non-interactive content. Please refer to the <a href="#accessibility-tips" class="font-weight-bold">Accessibility tips</a> section below for <strong>important</strong> usage information.</p>
            <h2 id="overview">Overview</h2>
            <p>To encourage extensible and predictable toasts, we recommend providing a header (title) and body. Toast headers use the style <code>'display: flex'</code>, allowing easy alignment of content thanks to Bootstrap's <a href="/docs/reference/utility-classes" class="font-weight-bold">margin and flexbox utility classes</a>.</p>
            <p>Toasts are slightly translucent, too, so they blend over whatever they might appear over. For browsers that support the <code>backdrop-filter</code> CSS property, they also attempt to blur the elements under the toast.</p>
            <div class="card card-callout p-3">
                <span>The animation effect of this component is dependent on the <code>prefers-reduced-motion</code> media query. See the <a href="https://bootstrap-vue.org/docs/reference/accessibility#reduced-motion">reduced motion section of our accessibility documentation</a>.</span>
            </div>

            <div class="border-top mt-5 pt-3">
                <h2 id="examples">Examples</h2>
                <h4 id="basic">Basic</h4>
                <p><strong>Note:</strong> we are using the <code>static</code> prop in the above example to render the toast in-place in the document, rather than transporting it to a <code>&lt;b-toaster&gt;</code> target container. And we have added classes <code>bg-secondary</code> and <code>progress-bar-striped</code> to the outer <code>&lt;div&gt;</code> for illustrative purposes of toast transparency only.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-toast id="my-toast"  visible static no-auto-hide>
                                <template #toast-title>
                                    <div class="d-flex flex-grow-1">
                                        <img src="@/assets/images/xs/avatar1.jpg" class="avatar sm rounded me-2" alt="" />
                                        <strong class="me-auto">Bootstrap</strong>
                                        <small class="text-muted mr-2">11 mins ago</small>
                                    </div>
                                </template>
                                Hello, world! This is a toast message.
                            </b-toast>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-toast id=&quot;my-toast&quot;  visible static no-auto-hide&gt;
    &lt;template #toast-title&gt;
        &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
            &lt;img src=&quot;assets/images/xs/avatar2.jpg&quot; class=&quot;avatar sm rounded me-2&quot; alt=&quot;&quot; /&gt;
            &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
            &lt;small class=&quot;text-muted mr-2&quot;&gt;11 mins ago&lt;/small&gt;
        &lt;/div&gt;
    &lt;/template&gt;
    Hello, world! This is a toast message.
&lt;/b-toast&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h4 id="translucent">Translucent</h4>
                <p>Toasts are slightly translucent, too, so they blend over whatever they might appear over. For browsers that support the <code>backdrop-filter</code> CSS property, we’ll also attempt to blur the elements under a toast.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3 bg-dark">
                            <b-toast id="my-toast"  visible static no-auto-hide solid>
                                <template #toast-title>
                                    <div class="d-flex flex-grow-1">
                                        <img src="@/assets/images/xs/avatar2.jpg" class="avatar sm rounded me-2" alt="" />
                                        <strong class="me-auto">Bootstrap</strong>
                                        <small class="text-muted mr-2">11 mins ago</small>
                                    </div>
                                </template>
                                Hello, world! This is a toast message.
                            </b-toast>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-toast id=&quot;my-toast&quot;  visible static no-auto-hide solid&gt;
    &lt;template #toast-title&gt;
        &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
            &lt;img src=&quot;assets/images/xs/avatar2.jpg&quot; class=&quot;avatar sm rounded me-2&quot; alt=&quot;&quot; /&gt;
            &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
            &lt;small class=&quot;text-muted mr-2&quot;&gt;11 mins ago&lt;/small&gt;
        &lt;/div&gt;
    &lt;/template&gt;
    Hello, world! This is a toast message.
&lt;/b-toast&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h3 id="stacking">Stacking</h3>
                <p>When you have multiple toasts, we default to vertically stacking them in a readable manner.</p>    
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-toast id="my-toast"  visible static no-auto-hide>
                                <template #toast-title>
                                    <div class="d-flex flex-grow-1">
                                        <img src="@/assets/images/xs/avatar3.jpg" class="avatar sm rounded me-2" alt="" />
                                        <strong class="me-auto">Bootstrap</strong>
                                        <small class="text-muted mr-2">just now</small>
                                    </div>
                                </template>
                                See? Just like this.
                            </b-toast>
                            <b-toast id="my-toast"  visible static no-auto-hide>
                                <template #toast-title>
                                    <div class="d-flex flex-grow-1">
                                        <img src="@/assets/images/xs/avatar3.jpg" class="avatar sm rounded me-2" alt="" />
                                        <strong class="me-auto">Bootstrap</strong>
                                        <small class="text-muted mr-2">2 seconds ago</small>
                                    </div>
                                </template>
                                Heads up, toasts will stack automatically
                            </b-toast>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-toast id=&quot;my-toast&quot;  visible static no-auto-hide&gt;
        &lt;template #toast-title&gt;
            &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
                &lt;img src=&quot;assets/images/xs/avatar3.jpg&quot; class=&quot;avatar sm rounded me-2&quot; alt=&quot;&quot; /&gt;
                &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
                &lt;small class=&quot;text-muted mr-2&quot;&gt;just now&lt;/small&gt;
            &lt;/div&gt;
        &lt;/template&gt;
        See? Just like this.
    &lt;/b-toast&gt;
    &lt;b-toast id=&quot;my-toast&quot;  visible static no-auto-hide&gt;
        &lt;template #toast-title&gt;
            &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
                &lt;img src=&quot;assets/images/xs/avatar3.jpg&quot; class=&quot;avatar sm rounded me-2&quot; alt=&quot;&quot; /&gt;
                &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
                &lt;small class=&quot;text-muted mr-2&quot;&gt;2 seconds ago&lt;/small&gt;
            &lt;/div&gt;
        &lt;/template&gt;
        Heads up, toasts will stack automatically
    &lt;/b-toast&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h3 id="custom-content">Custom content</h3>
                <p>Customize your toasts by removing sub-components, tweaking with <a href="https://bootstrap-vue.org/docs/reference/utility-classes#bootstrap-v4-utility-classes">utilities</a>, or adding your own markup. Here we’ve created a simpler toast by removing the default <code>.toast-header</code>, adding a custom hide icon from <a href="https://bootstrap-vue.org/docs/icons/#bootstrap-icons">Bootstrap Icons</a>, and using some <a href="https://bootstrap-vue.org/docs/reference/utility-classes#external-references">flexbox utilities</a> to adjust the layout.</p>    
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <div>
                                <b-alert
                                    v-model="showBottom"
                                    class="toast d-flex align-items-center mb-5"
                                    variant="light"
                                    dismissible
                                >
                                Hello, world! This is a toast message.
                                </b-alert>
                                <p>Building on the above example, you can create different toast color schemes with our <a href="https://bootstrap-vue.org/docs/components/toast#variants">color utilities</a>. Here we’ve added <code>.bg-primary</code> and <code>.text-white</code> to the <code>.toast</code>, and then added <code>.text-white</code> to our close button. For a crisp edge, we remove the default border with <code>.border-0</code>.</p>
                                 <b-alert
                                    v-model="showPrimary"
                                    class="toast d-flex align-items-center text-white bg-primary border-0"
                                    variant="light"
                                    dismissible
                                >
                                Hello, world! This is a toast message.
                                </b-alert>
                                <b-alert
                                    v-model="showSuccess"
                                    class="toast d-flex align-items-center text-white bg-success border-0"
                                    variant="light"
                                    dismissible
                                >
                                    Hello, world! This is a toast message.
                                </b-alert>
                                <b-alert
                                    v-model="showDanger"
                                    class="toast d-flex align-items-center text-white bg-danger border-0  mb-5"
                                    variant="light"
                                    dismissible
                                >
                                    Hello, world! This is a toast message.
                                </b-alert>
                                <p>Alternatively, you can also add additional controls and components to toasts.</p>
                                <b-toast title="Hello, world! This is a toast message." visible static no-auto-hide no-close-button v-model="display">
                                    <button type="button" class="btn btn-primary btn-sm mr-1">Take action</button>
                                    <button type="button" class="btn btn-dark btn-sm" @click="closeToast">Close</button>
                                </b-toast>
                            </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;div&gt;
        &lt;b-alert
            v-model=&quot;showBottom&quot;
            class=&quot;toast d-flex align-items-center mb-5&quot;
            variant=&quot;light&quot;
            dismissible
        &gt;
        Hello, world! This is a toast message.
        &lt;/b-alert&gt;
        &lt;p&gt;Building on the above example, you can create different toast color schemes with our &lt;a href=&quot;https://bootstrap-vue.org/docs/components/toast#variants&quot;&gt;color utilities&lt;/a&gt;. Here we’ve added &lt;code&gt;.bg-primary&lt;/code&gt; and &lt;code&gt;.text-white&lt;/code&gt; to the &lt;code&gt;.toast&lt;/code&gt;, and then added &lt;code&gt;.text-white&lt;/code&gt; to our close button. For a crisp edge, we remove the default border with &lt;code&gt;.border-0&lt;/code&gt;.&lt;/p&gt;
            &lt;b-alert
            v-model=&quot;showPrimary&quot;
            class=&quot;toast d-flex align-items-center text-white bg-primary border-0&quot;
            variant=&quot;light&quot;
            dismissible
        &gt;
        Hello, world! This is a toast message.
        &lt;/b-alert&gt;
        &lt;b-alert
            v-model=&quot;showSuccess&quot;
            class=&quot;toast d-flex align-items-center text-white bg-success border-0&quot;
            variant=&quot;light&quot;
            dismissible
        &gt;
            Hello, world! This is a toast message.
        &lt;/b-alert&gt;
        &lt;b-alert
            v-model=&quot;showDanger&quot;
            class=&quot;toast d-flex align-items-center text-white bg-danger border-0  mb-5&quot;
            variant=&quot;light&quot;
            dismissible
        &gt;
            Hello, world! This is a toast message.
        &lt;/b-alert&gt;
        &lt;p&gt;Alternatively, you can also add additional controls and components to toasts.&lt;/p&gt;
        &lt;b-toast title=&quot;Hello, world! This is a toast message.&quot; visible static no-auto-hide no-close-button v-model=&quot;display&quot;&gt;
            &lt;button type=&quot;button&quot; class=&quot;btn btn-primary btn-sm mr-1&quot;&gt;Take action&lt;/button&gt;
            &lt;button type=&quot;button&quot; class=&quot;btn btn-dark btn-sm&quot; @click=&quot;closeToast&quot;&gt;Close&lt;/button&gt;
        &lt;/b-toast&gt;
    &lt;/div&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
            
            <div class="border-top mt-5 pt-3">
                <h2 id="placement">Placement</h2>
                <p>Place toasts with custom CSS as you need them. The top right is often used for notifications, as is the top middle. If you’re only ever going to show one toast at a time, put the positioning styles right on the <code>.toast</code>.</p>    
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <div class="p-3 bg-dark">
                                <div style="position: relative; min-height: 200px;">
                                    <b-toast id="my-toast"  visible static no-auto-hide solid style="position: absolute; top: 0; right: 0;">
                                        <template #toast-title>
                                            <div class="d-flex flex-grow-1">
                                                <img src="@/assets/images/xs/avatar10.jpg" class="avatar sm rounded me-2" alt="" />
                                                <strong class="me-auto">Bootstrap</strong>
                                                <small class="text-muted mr-2">11 mins ago</small>
                                            </div>
                                        </template>
                                        Hello, world! This is a toast message.
                                    </b-toast>
                                </div>
                            </div>
                            <p class="mt-4 mb-1">For systems that generate more notifications, consider using a wrapping element so they can easily stack.</p>
                            <div class="p-3 bg-dark">
                                <div style="position: relative; min-height: 200px;">
                                    <div style="position: absolute; top: 0; right: 0;">
                                        <b-toast id="my-toast"  visible static no-auto-hide>
                                            <template #toast-title>
                                                <div class="d-flex flex-grow-1">
                                                    <img src="@/assets/images/xs/avatar3.jpg" class="avatar sm rounded me-2" alt="" />
                                                    <strong class="me-auto">Bootstrap</strong>
                                                    <small class="text-muted mr-2">just now</small>
                                                </div>
                                            </template>
                                            See? Just like this.
                                        </b-toast>
                                        <b-toast id="my-toast"  visible static no-auto-hide>
                                            <template #toast-title>
                                                <div class="d-flex flex-grow-1">
                                                    <img src="@/assets/images/xs/avatar3.jpg" class="avatar sm rounded me-2" alt="" />
                                                    <strong class="me-auto">Bootstrap</strong>
                                                    <small class="text-muted mr-2">2 seconds ago</small>
                                                </div>
                                            </template>
                                            Heads up, toasts will stack automatically
                                        </b-toast>
                                    </div>
                                </div>
                            </div>
                             <p class="mt-4 mb-1">You can also get fancy with flexbox utilities to align toasts horizontally and/or vertically.</p>
                                <div class="p-3 bg-dark">
                                    <div  class="d-flex justify-content-center align-items-center" style="min-height: 200px;">
                                        <b-toast  visible static no-auto-hide solid>
                                        <template #toast-title>
                                            <div class="d-flex flex-grow-1">
                                                <img src="@/assets/images/xs/avatar10.jpg" class="avatar sm rounded me-2" alt="" />
                                                <strong class="me-auto">Bootstrap</strong>
                                                <small class="text-muted mr-2">11 mins ago</small>
                                            </div>
                                        </template>
                                        Hello, world! This is a toast message.
                                    </b-toast>
                                    </div>
                                </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;div class=&quot;p-3 bg-dark&quot;&gt;
        &lt;div style=&quot;position: relative; min-height: 200px;&quot;&gt;
            &lt;b-toast id=&quot;my-toast&quot;  visible static no-auto-hide solid style=&quot;position: absolute; top: 0; right: 0;&quot;&gt;
                &lt;template #toast-title&gt;
                    &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
                        &lt;img src=&quot;@/assets/images/xs/avatar10.jpg&quot; class=&quot;avatar sm rounded me-2&quot; alt=&quot;&quot; /&gt;
                        &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
                        &lt;small class=&quot;text-muted mr-2&quot;&gt;11 mins ago&lt;/small&gt;
                    &lt;/div&gt;
                &lt;/template&gt;
                Hello, world! This is a toast message.
            &lt;/b-toast&gt;
        &lt;/div&gt;
    &lt;/div&gt;
    &lt;p class=&quot;mt-4 mb-1&quot;&gt;For systems that generate more notifications, consider using a wrapping element so they can easily stack.&lt;/p&gt;
    &lt;div class=&quot;p-3 bg-dark&quot;&gt;
        &lt;div style=&quot;position: relative; min-height: 200px;&quot;&gt;
            &lt;div style=&quot;position: absolute; top: 0; right: 0;&quot;&gt;
                &lt;b-toast id=&quot;my-toast&quot;  visible static no-auto-hide&gt;
                    &lt;template #toast-title&gt;
                        &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
                            &lt;img src=&quot;@/assets/images/xs/avatar3.jpg&quot; class=&quot;avatar sm rounded me-2&quot; alt=&quot;&quot; /&gt;
                            &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
                            &lt;small class=&quot;text-muted mr-2&quot;&gt;just now&lt;/small&gt;
                        &lt;/div&gt;
                    &lt;/template&gt;
                    See? Just like this.
                &lt;/b-toast&gt;
                &lt;b-toast id=&quot;my-toast&quot;  visible static no-auto-hide&gt;
                    &lt;template #toast-title&gt;
                        &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
                            &lt;img src=&quot;@/assets/images/xs/avatar3.jpg&quot; class=&quot;avatar sm rounded me-2&quot; alt=&quot;&quot; /&gt;
                            &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
                            &lt;small class=&quot;text-muted mr-2&quot;&gt;2 seconds ago&lt;/small&gt;
                        &lt;/div&gt;
                    &lt;/template&gt;
                    Heads up, toasts will stack automatically
                &lt;/b-toast&gt;
            &lt;/div&gt;
        &lt;/div&gt;
    &lt;/div&gt;
        &lt;p class=&quot;mt-4 mb-1&quot;&gt;You can also get fancy with flexbox utilities to align toasts horizontally and/or vertically.&lt;/p&gt;
        &lt;div class=&quot;p-3 bg-dark&quot;&gt;
            &lt;div  class=&quot;d-flex justify-content-center align-items-center&quot; style=&quot;min-height: 200px;&quot;&gt;
                &lt;b-toast  visible static no-auto-hide solid&gt;
                &lt;template #toast-title&gt;
                    &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
                        &lt;img src=&quot;@/assets/images/xs/avatar10.jpg&quot; class=&quot;avatar sm rounded me-2&quot; alt=&quot;&quot; /&gt;
                        &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
                        &lt;small class=&quot;text-muted mr-2&quot;&gt;11 mins ago&lt;/small&gt;
                    &lt;/div&gt;
                &lt;/template&gt;
                Hello, world! This is a toast message.
            &lt;/b-toast&gt;
            &lt;/div&gt;
        &lt;/div&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h2 id="accessibility">Accessibility</h2>
                <p>Toasts are intended to be <strong>small interruptions</strong> to your visitors or users, so to help those with screen readers and similar assistive technologies, toasts are wrapped in an aria-live region. Changes to live regions (such as injecting/updating a toast component) are automatically announced by screen readers without needing to move the user's focus or otherwise interrupt the user. Additionally, <code>aria-atomic="true"</code> is automatically set to ensure that the entire toast is always announced as a single (atomic) unit, rather than announcing what was changed (which could lead to problems if you only update part of the toast's content, or if displaying the same toast content at a later point in time).</p>
                <p>If you just need a single simple message to appear along the bottom or top of the user's window, use a <a href="#alerts-versus-toasts" class="font-weight-bold">fixed position <code>&lt;b-alert&gt;</code></a> instead.</p>
<pre class="language-html" data-lang="html">
<code>&lt;div class=&quot;toast&quot; role=&quot;alert&quot; aria-live=&quot;polite&quot; aria-atomic=&quot;true&quot;&gt;
    &lt;div role=&quot;alert&quot; aria-live=&quot;assertive&quot; aria-atomic=&quot;true&quot;&gt;...&lt;/div&gt;
&lt;/div&gt;</code>
</pre>
                <p>When using <code>no-close-button</code>, you must add a close button to allow users to dismiss the toast.</p>
                 <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3">
                            <b-toast id="my-toast"  visible static no-auto-hide no-close-button>
                                <template #toast-title>
                                    <div class="d-flex flex-grow-1">
                                         <svg class="bd-placeholder-img rounded me-2" width="20" height="20" xmlns="http://www.w3.org/2000/svg"  preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#007aff"></rect></svg>
                                        <strong class="me-auto">Bootstrap</strong>
                                        <small class="text-muted mr-2">11 mins ago</small>
                                    </div>
                                </template>
                                Hello, world! This is a toast message.
                            </b-toast>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>   
<pre class="language-html" data-lang="html">
<code>&lt;b-card class=&quot;mb-3&quot;&gt;
    &lt;b-toast id=&quot;my-toast&quot;  visible static no-auto-hide no-close-button&gt;
        &lt;template #toast-title&gt;
            &lt;div class=&quot;d-flex flex-grow-1&quot;&gt;
                    &lt;svg class=&quot;bd-placeholder-img rounded me-2&quot; width=&quot;20&quot; height=&quot;20&quot; xmlns=&quot;http://www.w3.org/2000/svg&quot;  preserveAspectRatio=&quot;xMidYMid slice&quot; focusable=&quot;false&quot;&gt;&lt;rect width=&quot;100%&quot; height=&quot;100%&quot; fill=&quot;#007aff&quot;&gt;&lt;/rect&gt;&lt;/svg&gt;
                &lt;strong class=&quot;me-auto&quot;&gt;Bootstrap&lt;/strong&gt;
                &lt;small class=&quot;text-muted mr-2&quot;&gt;11 mins ago&lt;/small&gt;
            &lt;/div&gt;
        &lt;/template&gt;
        Hello, world! This is a toast message.
    &lt;/b-toast&gt;
&lt;/b-card&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
            
            <h4 id="options">Properties </h4>
            <p>Options can be passed via data attributes or JavaScript. For data attributes, append the option name to <code>data-</code>, as in <code>data-animation=""</code>.</p>
            <table class="table">
                <thead>
                    <tr>
                        <th style="width: 100px;">Property</th>
                        <th style="width: 100px;">Type</th>
                        <th style="width: 50px;">Default</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><code>append-toast</code></td>
                        <td>Boolean</td>
                        <td><code>false</code></td>
                        <td>When true, the toast will be appended to the bottom on the visible toasts, otherwise it will be prepended</td>
                    </tr>
                     <tr>
                        <td><code>auto-hide-delay</code></td>
                        <td>Number or String</td>
                        <td><code>5000</code></td>
                        <td>The number of milliseconds before the toast auto dismisses itself</td>
                    </tr>
                    <tr>
                        <td><code>no-auto-hide</code></td>
                        <td>Boolean</td>
                        <td>
                        <code>false</code>
                        </td>
                        <td>When set, disabled the toast from automatically dismissing itself</td>
                    </tr>
                </tbody>
            </table>
            
            <h4 id="hide">hide</h4>
            <p>It is still possible to create a custom close button for the toast by providing a unique ID and use the <code class="text-nowrap" translate="no">this.$bvToast.hide(id)</code> method to hide the specific toast:</p>
<pre class="chroma"><code class="language-js" data-lang="js"><span class="nx">this</span><span class="p">.</span><span class="nx">$bvToast</span><span class="p">.</span><span class="nx">hide</span><span class="p">(id)</span>
</code></pre>
            
            <h3 id="events">Events</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th>Event</th>
                        <th>Arguments</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><code>change</code></td>
                        <td><code>visible </code> `true` if toast is visible, `false` otherwise</td>
                        <td>Toast visibility state. Used to update the v-model</td>
                    </tr>
                    <tr>
                        <td><code>hidden</code></td>
                        <td><code>bvEvent </code> BvEvent object</td>
                        <td>Always emits after toast is hidden</td>
                    </tr>
                    <tr>
                        <td><code>hide</code></td>
                        <td><code>bvEvent </code> BvEvent object</td>
                        <td>Always emits just before toast has hidden</td>
                    </tr>
                    <tr>
                        <td><code>show</code></td>
                        <td><code>bvEvent </code>  BvEvent object</td>
                        <td>Always emits just before toast is shown</td>
                    </tr>
                    <tr>
                        <td><code>shown</code></td>
                        <td><code>bvEvent </code> BvEvent object</td>
                        <td>Always emits when toast is shown</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'ToastsComponent',
    data() {
      return {
        showBottom: true,
        showPrimary: true,
        showSuccess: true,
        showDanger: true,
        display:true,
      }
    },methods: {
      closeToast(){
        this.display = false
      }
    },mounted() {
        Prism.highlightAll()
        
    }
}
</script>