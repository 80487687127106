<template>
     <div class="col-12">
        <div class="bd-content">
            <div class="alert alert-danger" role="alert">
                <strong>Dropdowns</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/components/dropdown" target="_blank">Bootstrap Dropdowns documentation <i class="fa fa-external-link"></i></a>
            </div>
            <h2 id="overview">Overview</h2>
            <p><code class="text-nowrap" translate="no">&lt;b-dropdown&gt;</code> (or known by its shorter alias of <code class="text-nowrap" translate="no">&lt;b-dd&gt;</code>) components are toggleable, contextual overlays for displaying lists of links and more. They're toggled by clicking (or pressing space or enter when focused), not by hovering; this is an <a href="https://markdotto.com/2012/02/27/bootstrap-explained-dropdowns/" target="_blank" rel="noopener">intentional design decision</a>.</p>
            <div class="border-top mt-5 pt-3">
                <h3 id="single-button">Single button</h3>
                <p>Any single <code>.btn</code> can be turned into a dropdown toggle with some markup changes. Here’s how you can put them to work with either <code>&lt;b-button&gt;</code> elements:</p>
                    <b-tabs nav-class="tab-card px-3 ">
                        <b-tab title="Preview" active>
                            <b-card>
                                <div class="dropdown d-inline-flex m-1">
                                    <b-dropdown id="dropdown-1" menu-class="border-0 shadow" text="Primary Dropdown" variant="primary">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="dropdown d-inline-flex m-1">
                                    <b-dropdown id="dropdown-1" menu-class="border-0 shadow" text=" Primary Outline Dropdown" variant="outline-primary">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="dropdown d-inline-flex m-1">
                                    <b-dropdown id="dropdown-1" menu-class="border-0 shadow" text=" Dark Dropdown" variant="dark">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </b-card>
                        </b-tab>
                        <b-tab title="HTML">
                            <b-card>
<pre class="language-html" data-lang="html">
<code>
&lt;div class=&quot;dropdown d-inline-flex m-1&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot;Primary Dropdown&quot; variant=&quot;primary&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;dropdown d-inline-flex m-1&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot; Primary Outline Dropdown&quot; variant=&quot;outline-primary&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;dropdown d-inline-flex m-1&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot; Dark Dropdown&quot; variant=&quot;dark&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;</code>
</pre>                 
                            </b-card>
                        </b-tab>
                    </b-tabs>
                    <p>And with <code>&lt;b-link&gt;</code> elements:</p>
                    <b-tabs nav-class="tab-card px-3 ">
                        <b-tab title="Preview" active>
                            <b-card>
                                <div class="dropdown d-inline-flex m-1">
                                    <b-dropdown id="dropdown-1" menu-class="border-0 shadow" text="Primary Dropdown" variant="primary" toggle-tag='a'>
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="dropdown d-inline-flex m-1">
                                    <b-dropdown id="dropdown-1" menu-class="border-0 shadow" text=" Primary Outline Dropdown" variant="outline-primary" toggle-tag='a'>
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="dropdown d-inline-flex m-1">
                                        <b-dropdown id="dropdown-1" menu-class="border-0 shadow" text=" Dark Dropdown" variant="dark" toggle-tag='a'>
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </b-card>
                        </b-tab>
                        <b-tab title="HTML">
                            <b-card>
<pre class="language-html" data-lang="html">
<code>
&lt;div class=&quot;dropdown d-inline-flex m-1&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot;Primary Dropdown&quot; variant=&quot;primary&quot; toggle-tag=&apos;a&apos;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;dropdown d-inline-flex m-1&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot; Primary Outline Dropdown&quot; variant=&quot;outline-primary&quot; toggle-tag=&apos;a&apos;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;dropdown d-inline-flex m-1&quot;&gt;
        &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot; Dark Dropdown&quot; variant=&quot;dark&quot; toggle-tag=&apos;a&apos;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;</code>
</pre>                 
                            </b-card>
                        </b-tab>
                    </b-tabs>

                <p>The best part is you can do this with any button variant, too:</p>
                 <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card>
                            <div class="btn-group">
                                <b-dropdown id="dropdown-1" text="Primary" variant="primary" toggle-class="mr-1" menu-class="border-0 shadow bg-primary">
                                    <b-dropdown-item href="#">Action</b-dropdown-item>
                                    <b-dropdown-item href="#">Another action</b-dropdown-item>
                                    <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="btn-group">
                                <b-dropdown id="dropdown-1"  text="Secondary" toggle-class="mr-1">
                                    <b-dropdown-item href="#">Action</b-dropdown-item>
                                    <b-dropdown-item href="#">Another action</b-dropdown-item>
                                    <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="btn-group">
                                <b-dropdown id="dropdown-1"  text="Success" variant="success" toggle-class="mr-1" menu-class="border-0 shadow bg-success">
                                    <b-dropdown-item href="#">Action</b-dropdown-item>
                                    <b-dropdown-item href="#">Another action</b-dropdown-item>
                                    <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="btn-group">
                                <b-dropdown id="dropdown-1" text="Info" variant="info" toggle-class="mr-1" menu-class="border-0 shadow bg-info">
                                    <b-dropdown-item href="#">Action</b-dropdown-item>
                                    <b-dropdown-item href="#">Another action</b-dropdown-item>
                                    <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="btn-group">
                                <b-dropdown id="dropdown-1" text="Warning" variant="warning" toggle-class="mr-1" menu-class="border-0 shadow bg-warning">
                                    <b-dropdown-item href="#">Action</b-dropdown-item>
                                    <b-dropdown-item href="#">Another action</b-dropdown-item>
                                    <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                </b-dropdown>
                            </div>
                            <div class="btn-group">
                                <b-dropdown id="dropdown-1"  text="Danger" variant="danger" toggle-class="mr-1" menu-class="border-0 shadow bg-danger">
                                    <b-dropdown-item href="#">Action</b-dropdown-item>
                                    <b-dropdown-item href="#">Another action</b-dropdown-item>
                                    <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                    <b-dropdown-divider></b-dropdown-divider>
                                    <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                </b-dropdown>
                            </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot;Primary&quot; variant=&quot;primary&quot; toggle-class=&quot;mr-1&quot; menu-class=&quot;border-0 shadow bg-primary&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot;Secondary&quot; toggle-class=&quot;mr-1&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot;Success&quot; variant=&quot;success&quot; toggle-class=&quot;mr-1&quot; menu-class=&quot;border-0 shadow bg-success&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot;Info&quot; variant=&quot;info&quot; toggle-class=&quot;mr-1&quot; menu-class=&quot;border-0 shadow bg-info&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot;Warning&quot; variant=&quot;warning&quot; toggle-class=&quot;mr-1&quot; menu-class=&quot;border-0 shadow bg-warning&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown id=&quot;dropdown-1&quot; text=&quot;Danger&quot; variant=&quot;danger&quot; toggle-class=&quot;mr-1&quot; menu-class=&quot;border-0 shadow bg-danger&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h3 id="split-button">Split button</h3>
                <p>Similarly, create split button dropdowns with virtually the same markup as single button dropdowns, but with the addition of <code>.dropdown-toggle-split</code> for proper spacing around the dropdown caret.</p>
                <p>We use this extra class to reduce the horizontal <code>padding</code> on either side of the caret by 25% and remove the <code>margin-left</code> that’s added for regular button dropdowns. Those extra changes keep the caret centered in the split button and provide a more appropriately sized hit area next to the main button.</p>
                    <b-tabs nav-class="tab-card px-3 ">
                        <b-tab title="Preview" active>
                            <b-card>
                                <div class="btn-group">
                                    <b-dropdown split menu-class="border-0 shadow" split-variant="primary" variant="primary" text="Primary" class="mr-1">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="btn-group">
                                    <b-dropdown split menu-class="border-0 shadow" text="Secondary" class="mr-1">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="btn-group">
                                    <b-dropdown split menu-class="border-0 shadow" split-variant="success" variant="success" text="Success" class="mr-1">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="btn-group">
                                    <b-dropdown split menu-class="border-0 shadow" split-variant="info" variant="info" text="Info" class="mr-1">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="btn-group">
                                    <b-dropdown split menu-class="border-0 shadow" split-variant="warning" variant="warning" text="Warning" class="mr-1">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                                <div class="btn-group">
                                    <b-dropdown split menu-class="border-0 shadow" split-variant="danger" variant="danger" text="Danger" class="mr-1">
                                        <b-dropdown-item href="#">Action</b-dropdown-item>
                                        <b-dropdown-item href="#">Another action</b-dropdown-item>
                                        <b-dropdown-item href="#">Something else here</b-dropdown-item>
                                        <b-dropdown-divider></b-dropdown-divider>
                                        <b-dropdown-item href="#">Separated link</b-dropdown-item>
                                    </b-dropdown>
                                </div>
                            </b-card>
                        </b-tab>
                        <b-tab title="HTML">
                            <b-card>
<pre class="language-html" data-lang="html">
<code>
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown split split-variant=&quot;primary&quot; variant=&quot;primary&quot; text=&quot;Primary&quot; class=&quot;mr-1&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown split text=&quot;Secondary&quot; class=&quot;mr-1&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown split split-variant=&quot;success&quot; variant=&quot;success&quot; text=&quot;Success&quot; class=&quot;mr-1&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown split split-variant=&quot;info&quot; variant=&quot;info&quot; text=&quot;Info&quot; class=&quot;mr-1&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown split split-variant=&quot;warning&quot; variant=&quot;warning&quot; text=&quot;Warning&quot; class=&quot;mr-1&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-group&quot;&gt;
    &lt;b-dropdown split split-variant=&quot;danger&quot; variant=&quot;danger&quot; text=&quot;Danger&quot; class=&quot;mr-1&quot;&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Another action&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Something else here&lt;/b-dropdown-item&gt;
        &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
        &lt;b-dropdown-item href=&quot;#&quot;&gt;Separated link&lt;/b-dropdown-item&gt;
    &lt;/b-dropdown&gt;
&lt;/div&gt;</code>
</pre>                 
                            </b-card>
                        </b-tab>
                    </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h3 id="split-button">Sizing</h3>
                <p>Button dropdowns work with buttons of all sizes, including default and split dropdown buttons.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card>
                            <div class="btn-toolbar" role="toolbar">
                            <div class="btn-group">
                                    <b-dropdown menu-class="border-0 shadow" size="lg" text="Large button" class="m-1">
                                        <b-dropdown-item-button>Action</b-dropdown-item-button>
                                        <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                        <b-dropdown-item-button>Something else here</b-dropdown-item-button>
                                    </b-dropdown>
                                </div><!-- /btn-group -->
                                <div class="btn-group ms-2">
                                    <b-dropdown menu-class="border-0 shadow" size="lg" split text="Large Split button" class="m-1">
                                        <b-dropdown-item-button>Action</b-dropdown-item-button>
                                        <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                        <b-dropdown-item-button>Something else here...</b-dropdown-item-button>
                                    </b-dropdown>
                                </div><!-- /btn-group -->
                            </div>
                            <div class="btn-toolbar py-2" role="toolbar">
                                <div class="btn-group">
                                    <b-dropdown menu-class="border-0 shadow" size="sm" text="Small button" class="m-2">
                                        <b-dropdown-item-button>Action</b-dropdown-item-button>
                                        <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                        <b-dropdown-item-button>Something else here...</b-dropdown-item-button>
                                    </b-dropdown>
                                </div><!-- /btn-group -->
                                <div class="btn-group ms-2">
                                    <b-dropdown menu-class="border-0 shadow" size="sm" split text="Small Split button" class="m-2">
                                        <b-dropdown-item-button>Action</b-dropdown-item-button>
                                        <b-dropdown-item-button>Another action</b-dropdown-item-button>
                                        <b-dropdown-item-button>Something else here...</b-dropdown-item-button>
                                    </b-dropdown>
                                </div><!-- /btn-group -->
                            </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>
&lt;div class=&quot;btn-toolbar&quot; role=&quot;toolbar&quot;&gt;
    &lt;div class=&quot;btn-group&quot;&gt;
        &lt;b-dropdown size=&quot;lg&quot; text=&quot;Large button&quot; class=&quot;m-1&quot;&gt;
            &lt;b-dropdown-item-button&gt;Action&lt;/b-dropdown-item-button&gt;
            &lt;b-dropdown-item-button&gt;Another action&lt;/b-dropdown-item-button&gt;
            &lt;b-dropdown-item-button&gt;Something else here&lt;/b-dropdown-item-button&gt;
        &lt;/b-dropdown&gt;
    &lt;/div&gt;&lt;!-- /btn-group --&gt;
    &lt;div class=&quot;btn-group ms-2&quot;&gt;
        &lt;b-dropdown size=&quot;lg&quot; split text=&quot;Large Split button&quot; class=&quot;m-1&quot;&gt;
            &lt;b-dropdown-item-button&gt;Action&lt;/b-dropdown-item-button&gt;
            &lt;b-dropdown-item-button&gt;Another action&lt;/b-dropdown-item-button&gt;
            &lt;b-dropdown-item-button&gt;Something else here...&lt;/b-dropdown-item-button&gt;
        &lt;/b-dropdown&gt;
    &lt;/div&gt;&lt;!-- /btn-group --&gt;
&lt;/div&gt;
&lt;div class=&quot;btn-toolbar py-2&quot; role=&quot;toolbar&quot;&gt;
    &lt;div class=&quot;btn-group&quot;&gt;
        &lt;b-dropdown size=&quot;sm&quot; text=&quot;Small button&quot; class=&quot;m-2&quot;&gt;
            &lt;b-dropdown-item-button&gt;Action&lt;/b-dropdown-item-button&gt;
            &lt;b-dropdown-item-button&gt;Another action&lt;/b-dropdown-item-button&gt;
            &lt;b-dropdown-item-button&gt;Something else here...&lt;/b-dropdown-item-button&gt;
        &lt;/b-dropdown&gt;
    &lt;/div&gt;&lt;!-- /btn-group --&gt;
    &lt;div class=&quot;btn-group ms-2&quot;&gt;
        &lt;b-dropdown size=&quot;sm&quot; split text=&quot;Small Split button&quot; class=&quot;m-2&quot;&gt;
            &lt;b-dropdown-item-button&gt;Action&lt;/b-dropdown-item-button&gt;
            &lt;b-dropdown-item-button&gt;Another action&lt;/b-dropdown-item-button&gt;
            &lt;b-dropdown-item-button&gt;Something else here...&lt;/b-dropdown-item-button&gt;
        &lt;/b-dropdown&gt;
    &lt;/div&gt;&lt;!-- /btn-group --&gt;
&lt;/div&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h3 id="text">Text</h3>
                <p>Place any freeform text within a dropdown menu with text and use <a href="https://bootstrap-vue.org/docs/reference/spacing-classes#spacing">spacing utilities</a>. Note that you’ll likely need additional sizing styles to constrain the menu width.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card>
                            <b-dropdown id="dropdown-text" text="Dropdown with div" menu-class="border-0 shadow">
                                <b-dropdown-text  style="max-width: 200px;">
                                    <p>Some example text that's free-flowing within the dropdown menu.</p>
                                    <p class="mb-0">And this is more example text.</p>
                                </b-dropdown-text>
                            </b-dropdown>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>
&lt;b-dropdown id=&quot;dropdown-text&quot; text=&quot;Dropdown with div&quot; menu-class=&quot;border-0 shadow&quot;&gt;
    &lt;b-dropdown-text  style=&quot;max-width: 200px;&quot;&gt;
        &lt;p&gt;Some example text that&apos;s free-flowing within the dropdown menu.&lt;/p&gt;
        &lt;p class=&quot;mb-0&quot;&gt;And this is more example text.&lt;/p&gt;
    &lt;/b-dropdown-text&gt;
&lt;/b-dropdown&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

            <div class="border-top mt-5 pt-3">
                <h3 id="forms">Forms</h3>
                <p>Put a form within a dropdown menu, or make it into a dropdown menu, and use <a href="https://bootstrap-vue.org/docs/reference/spacing-classes#spacing">margin or padding utilities</a> to give it the negative space you require.</p>
                 <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card>
                            <b-dropdown id="dropdown-form" text="Dropdown with form" ref="dropdown" class="m-2" menu-class="border-0 shadow">
                                <b-dropdown-form>
                                    <b-form-group label="Email" label-for="dropdown-form-email" @submit.stop.prevent>
                                    <b-form-input
                                        id="dropdown-form-email"
                                        size="sm"
                                        placeholder="Enter email"
                                        value="admin@admin.com"
                                    ></b-form-input>
                                    </b-form-group>

                                    <b-form-group label="Password" label-for="dropdown-form-password">
                                    <b-form-input
                                        id="dropdown-form-password"
                                        type="password"
                                        size="sm"
                                        placeholder="Password"
                                        value="12345"
                                    ></b-form-input>
                                    </b-form-group>

                                    <b-form-checkbox class="mb-3">Remember me</b-form-checkbox>
                                    <b-button variant="primary" size="sm" @click="onClick">Sign In</b-button>
                                </b-dropdown-form>
                                <b-dropdown-divider></b-dropdown-divider>
                                <b-dropdown-item-button>New around here? Sign up</b-dropdown-item-button>
                                <b-dropdown-item-button>Forgot Password?</b-dropdown-item-button>
                            </b-dropdown>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>
&lt;b-dropdown id=&quot;dropdown-form&quot; text=&quot;Dropdown with form&quot; ref=&quot;dropdown&quot; class=&quot;m-2&quot;&gt;
    &lt;b-dropdown-form&gt;
        &lt;b-form-group label=&quot;Email&quot; label-for=&quot;dropdown-form-email&quot; @submit.stop.prevent&gt;
        &lt;b-form-input
            id=&quot;dropdown-form-email&quot;
            size=&quot;sm&quot;
            placeholder=&quot;Enter email&quot;
            value=&quot;admin@admin.com&quot;
        &gt;&lt;/b-form-input&gt;
        &lt;/b-form-group&gt;

        &lt;b-form-group label=&quot;Password&quot; label-for=&quot;dropdown-form-password&quot;&gt;
        &lt;b-form-input
            id=&quot;dropdown-form-password&quot;
            type=&quot;password&quot;
            size=&quot;sm&quot;
            placeholder=&quot;Password&quot;
            value=&quot;12345&quot;
        &gt;&lt;/b-form-input&gt;
        &lt;/b-form-group&gt;

        &lt;b-form-checkbox class=&quot;mb-3&quot;&gt;Remember me&lt;/b-form-checkbox&gt;
        &lt;b-button variant=&quot;primary&quot; size=&quot;sm&quot; @click=&quot;onClick&quot;&gt;Sign In&lt;/b-button&gt;
    &lt;/b-dropdown-form&gt;
    &lt;b-dropdown-divider&gt;&lt;/b-dropdown-divider&gt;
    &lt;b-dropdown-item-button&gt;New around here? Sign up&lt;/b-dropdown-item-button&gt;
    &lt;b-dropdown-item-button&gt;Forgot Password?&lt;/b-dropdown-item-button&gt;
&lt;/b-dropdown&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>

        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'DropdownsComponent',
    data() {
        return {
        
        }
    },methods:{
        onClick() {
            this.$refs.dropdown.hide(true)
        }
    },mounted() {
        Prism.highlightAll()
    }
}
</script>
<style scoped>

</style>