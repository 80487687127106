<template>
  <div class="canvan-bg canvan pt-0 pb-0 mb-0 transparent-bg">
    <div class="id px-0 pt-0 mx-0 content">
      <h3 class="pl-3 mt-0 py-3 mb-0" style="background: var(--card-color)">
        {{ dataKanban.titulo }}
      </h3>
      <div class="mt-0">
        <div class="flex overflow-x-scroll overflow-y-scroll column-height px-4 pt-0" id="now">
          <div v-for="column in columns" :key="column.id" class="rounded-lg px-2 py-3 column-width rounded mr-2" style="max-height: 300vh">
            <div class="row">
              <div class="col-md-9 bg-primary">
                <p class="font-semibold font-sans tracking-wide text-sm text-center m-2">{{ column.title }}</p>
              </div>
              <div class="col-md-2"><button @click="showModal(1, column)" class="btn btn-primary">+</button></div>
            </div>
            <!-- Draggable component comes from vuedraggable. It provides drag & drop functionality -->
            <draggable
              style="min-height: 600px"
              :list="column.tasks"
              :animation="200"
              ghost-class="ghost-card"
              group="columns"
              :groups="columns"
              @change="updateDrag($event, column)"
            >
              <!-- Each element from here will be draggable and animated. Note :key is very important here to be unique both for draggable and animations to be smooth & consistent. -->

              <div
                class="canvan-item canvan-item-blur card mx-0 rounded p-3 border border-white mt-2 cursor-move"
                :style="`border-top: 2px solid ${seekColor(task.tipo)}  !important`"
                @click="showModal(2, column, task)"
                v-for="task in column.tasks"
                :key="task.id"
              >
                <div class="row">
                  <div class="col-9">
                    <p class="font-semibold text-primary font-sans tracking-wide task-title">{{ task.titulo }}</p>
                    <p style="font-weight: bold; font-size: smaller; font-style: italic; color: ">{{ task.tituloproy != null ? task.tituloproy.trim() : "" }}</p>
                    <span class="text-primary" v-if="task.porterminado != null">{{ task.porterminado + "%" }}</span>
                  </div>

                  <div v-if="task.nombres != null" class="col-3">
                    <div class="circle" :style="'text-transform: uppercase; background-color: ' + (task.color != null ? task.color : ' #f89109')">
                      {{ task.nombres != null ? task.nombres.substr(0, 3) : "" }}
                    </div>
                  </div>
                  <div v-else class="col-3">
                    <div class="circle2">--</div>
                  </div>
                  <!-- titulo de la tarjeta -->
                </div>

                <!-- <div class="flex mt-0 justify-between items-center">
                  <span class="text-sm text-gray-600" style="font-size: 10px"
                    ><b>Respo: {{ task.nombres }} </b></span
                  >
                </div> -->
                <div class="flex mt-0 justify-between items-center">
                  <span class="text-sm" style="font-size: 10px">{{ $moment(task.fecha).utc().format("DD-MM-YYYY") }}</span>
                  <span>
                    <badge
                      style="background-color: rgba(255, 255, 255, 0.116) !important"
                      :style="`border: 2px solid background-color: transparent !important; ${seekColor(task.tipo)}  !important`"
                      v-if="task.tipo"
                      :color="seekColor(task.tipo)"
                    >
                      <span style="z-index: 99 !important; color: rgb(197, 197, 197)">{{ task.tipo }}</span>
                    </badge>
                  </span>
                </div>
                <div class="flex mt-0 justify-between items-center">
                  <badge v-if="task.archivado == 1" color="yellow">{{ task.archivado == 1 ? "Archivado" : "" }}</badge>
                </div>
              </div>

              <!-- <task-card 
                  v-for="(task) in column.tasks"
                  :key="task.id"
                  :task="task"
                  class="mt-3 cursor-move"
                ></task-card> -->

              <!-- </transition-group> -->
            </draggable>
          </div>
        </div>
      </div>

      <!-- <b-modal class="modal-dialog" id="kanbanModal" tabindex="-1" :size="meca_kanban_archivos.length > 0 ? 'xl' : 'lg'" centered :title="modalTitle" hide-footer>
        <template v-slot:modal-header-close>
          <button type="button" class="btn btn-close"></button>
        </template>
        <div class="modal-body bg-gray">
          <form @submit.prevent="saveData()">
            <div class="row">
              <div :class="meca_kanban_archivos.length > 0 ? ' col-md-8 ' : 'col-md-12'">
                <div class="row">
                  <div class="col-md-4 mb-2">
                    <label for="nombre" class="">Titulo</label><input required v-model="row.titulo" class="form-control" type="text" name="nombre" id="nombre" />
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="codigo" class="">Fecha Inicio</label><input required v-model="row.fecha" class="form-control" type="date" name="fecha" id="fecha" />
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="fechafin" class="">Fecha Fin</label><input required v-model="row.fecha_fin" class="form-control" type="date" name="fechafin" id="fechafin" />
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="tipo" class="">Tipo</label>
                    <b-form-select required class="form-control select" v-model="row.tipo" :options="listaTipo" value="tipo" value-field="tipo" text-field="tipo"> </b-form-select>
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="tipo" class="">Respo</label>
                    <b-form-select required class="form-control select" v-model="row.usuario_id" :options="usuarios" value="id" value-field="id" text-field="nombres">
                    </b-form-select>
                  </div>
                  <div class="col-md-4 mb-2">
                    <label for="tipo" class="">Proyecto</label>
                    <b-form-select required class="form-control select" v-model="row.proyecto_id" :options="projects" value="id" value-field="id" text-field="titulo">
                    </b-form-select>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="descripcion" class="mt-2">Descripción</label>
                    <button v-if="Object.prototype.hasOwnProperty.call(row, 'id')" @click.prevent="generarChecklist()" class="btn btn-primary p-1 float-end m-1">
                      Generar CheckList
                    </button>
                    <textarea class="form-control" v-model="row.descripcion" name="" id="" cols="30" rows="2"></textarea>
                  </div>

                  <div class="col-md-12 mb-2" v-if="Object.prototype.hasOwnProperty.call(row, 'id')">
                    <label for="tipo" class=""
                      >CheckList <button @click.prevent="addItemCheckList()" class="btn btn-primary p-1 ml-4 pt-0 pb-0"><i class="fa fa-plus"></i></button>
                    </label>
                    <b-progress :max="checklist.length" show-progress variant="success" class="mb-3">
                      <b-progress-bar
                        :value="checklist.filter((x) => x.terminado == 1).length"
                        :label-html="`<b style='color:black;font-size:15px'>${((checklist.filter((x) => x.terminado == 1).length / checklist.length) * 100).toFixed(2)} %</b>`"
                      ></b-progress-bar>
                    </b-progress>

                    <template v-if="checklist.length > 0">
                      <div class="row mt-2" v-for="(iteml, indexf) of checklist" :key="iteml.idtmp">
                        <div class="col-md-7">
                          <textarea
                            v-auto-resize
                            v-on:keyup="saveItemTmeout(iteml)"
                            required
                            v-model="iteml.titulo"
                            class="form-control textarea"
                            type="text"
                            name="nombre"
                            id="nombre"
                            cols="50"
                            rows="1"
                          />
                        </div>
                        <div class="col-md-2 text-center">
                          <span class="text-white mr-1 pointer" @click="changeOrderItem(iteml, 0)">↑</span><span class="text-white">{{ iteml.orden }}</span
                          ><span class="text-white ml-1 pointer" @click="changeOrderItem(iteml, 1)">↓</span>
                        </div>
                        <div class="col-md-1 text-left">
                          <b-form-checkbox
                            @change="saveItemChecklist(iteml)"
                            size="lg"
                            :id="'checkbox-1' + indexf"
                            v-model="iteml.terminado"
                            :name="'checkbox-1' + indexf"
                            value="1"
                            unchecked-value="0"
                          >
                            <span for="terminado" class="h6">Fin</span>
                          </b-form-checkbox>
                        </div>
                        <div class="col-md-2">
                          <button @click.prevent="removeItemChecklist(iteml)" class="btn btn-danger p-1 ml-2 mt-2 pt-0 pb-0"><i class="fa fa-trash-o"></i></button>
                        </div>
                      </div>
                    </template>
                  </div>

                  <div class="col-md-12 mb-2 mt-2 ml-4">
                    <b-form-checkbox size="lg" id="checkbox-1" v-model="row.archivado" name="checkbox-1" value="1" unchecked-value="0">
                      <span for="Archivado" class="h6">Archivado</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="descripcion" class="">Subir imagen / archivo</label><br />
                    <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('kanban')" /><br />
                  </div>
                </div>
              </div>
              <div class="col-md-4" v-if="meca_kanban_archivos.length > 0">
                <div class="row">
                  <div class="col-4" v-for="item of meca_kanban_archivos" :key="item.id">
                    <a target="_blank" :href="item.url">

                      <img
                        v-if="
                          item.url.split('.').pop() == 'jpg' ||
                          item.url.split('.').pop() == 'gif' ||
                          item.url.split('.').pop() == 'bmp' ||
                          item.url.split('.').pop() == 'jpeg' ||
                          item.url.split('.').pop() == 'png' ||
                          item.url.split('.').pop() == 'webp'
                        "
                        :src="item.url"
                        alt=""
                        style="margin: 5px !important"
                      />
                      <img
                        v-if="
                          item.url.split('.').pop() != 'jpg' &&
                          item.url.split('.').pop() != 'gif' &&
                          item.url.split('.').pop() != 'bmp' &&
                          item.url.split('.').pop() != 'jpeg' &&
                          item.url.split('.').pop() != 'png' &&
                          item.url.split('.').pop() != 'webp'
                        "
                        src="https://upload.wikimedia.org/wikipedia/commons/1/1b/SD_Download_Manager_logo.png"
                        alt=""
                        style="margin: 5px !important"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-right">
              <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('kanbanModal')">Cerrar</b-button>
              <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
            </div>
          </form>
        </div>
      </b-modal> -->

      <b-modal class="modal-dialog" id="kanbanModal" tabindex="-1" :size="meca_kanban_archivos.length > 0 ? 'xl' : 'lg'" centered :title="modalTitle" hide-footer>
        <template v-slot:modal-header-close>
          <button type="button" class="btn btn-close"></button>
        </template>
        <div class="modal-body bg-gray">
          <form @submit.prevent="saveData()">
            <div class="row">
              <!-- columna 1 -->
              <div class="col-md-9">
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <label for="nombre" class=""> Titulo</label><input required v-model="row.titulo" class="form-control" type="text" name="nombre" id="nombre" />
                  </div>

                  <div class="col-md-12 mb-2">
                    <label for="descripcion" class="mt-2">Descripción</label>
                    <button v-if="Object.prototype.hasOwnProperty.call(row, 'id')" @click.prevent="generarChecklist()" class="btn btn-primary p-1 float-end m-1">
                      Generar CheckList
                    </button>
                    <textarea class="form-control" v-model="row.descripcion" name="" id="" cols="30" rows="2"></textarea>
                  </div>

                  <div class="col-md-12 mb-2" v-if="Object.prototype.hasOwnProperty.call(row, 'id')">
                    <label for="tipo" class=""
                      >CheckList <button @click.prevent="addItemCheckList()" class="btn btn-primary p-1 ml-4 pt-0 pb-0"><i class="fa fa-plus"></i></button>
                    </label>
                    <b-progress :max="checklist.length" show-progress variant="success" class="mb-3">
                      <b-progress-bar
                        :value="checklist.filter((x) => x.terminado == 1).length"
                        :label-html="`<b style='color:black;font-size:15px'>${((checklist.filter((x) => x.terminado == 1).length / checklist.length) * 100).toFixed(2)} %</b>`"
                      ></b-progress-bar>
                    </b-progress>

                    <template v-if="checklist.length > 0">
                      <div class="row mt-2" v-for="(iteml, indexf) of checklist" :key="iteml.idtmp">
                        <div class="col-md-10">
                          <textarea
                            ref="textarea"
                            v-on:keyup="saveItemTmeout(iteml)"
                            required
                            v-model="iteml.titulo"
                            class="form-control auto-resize-textarea"
                            type="text"
                            name="nombre"
                            id="nombre"
                          />
                        </div>
                        <!-- <div class="col-md-2 text-center">
                          <span class="text-white mr-1 pointer" @click="changeOrderItem(iteml, 0)">↑</span><span class="text-white">{{ iteml.orden }}</span
                          ><span class="text-white ml-1 pointer" @click="changeOrderItem(iteml, 1)">↓</span>
                        </div> -->
                        <div class="col-md-1 text-left">
                          <b-form-checkbox
                            class="mt-2"
                            @change="saveItemChecklist(iteml)"
                            size="lg"
                            :id="'checkbox-1' + indexf"
                            v-model="iteml.terminado"
                            :name="'checkbox-1' + indexf"
                            value="1"
                            unchecked-value="0"
                          >
                            <!-- <span for="terminado" class="h6">Fin</span> -->
                          </b-form-checkbox>
                        </div>
                        <div class="col-md-1">
                          <button @click.prevent="removeItemChecklist(iteml)" class="btn btn-danger p-1 ml-2 mt-2 pt-0 pb-0"><i class="fa fa-trash-o"></i></button>
                        </div>
                      </div>
                    </template>
                  </div>

                  <div class="col-md-12 mb-2 mt-2 ml-4">
                    <b-form-checkbox size="lg" id="checkbox-1" v-model="row.archivado" name="checkbox-1" value="1" unchecked-value="0">
                      <span for="Archivado" class="h6">Archivado</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="descripcion" class="">Subir imagen / archivo</label><br />
                    <input type="file" id="file1" ref="file1" v-on:change="onFileSelected('kanban')" /><br />
                  </div>
                </div>
                <br />
              </div>
              <!-- columna 2 -->
              <div class="col-md-3">
                <div class="row">
                  <div class="col-md-12 mb-2">
                    <label for="codigo" class="">Fecha Inicio</label><input required v-model="row.fecha" class="form-control" type="date" name="fecha" id="fecha" />
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="fechafin" class="">Fecha Fin</label><input required v-model="row.fecha_fin" class="form-control" type="date" name="fechafin" id="fechafin" />
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="tipo" class="">Tipo</label>
                    <b-form-select required class="form-control select" v-model="row.tipo" :options="listaTipo" value="tipo" value-field="tipo" text-field="tipo"> </b-form-select>
                  </div>
                  <div class="col-md-12 mb-2">
                    <label for="tipo" class="">Respo</label>
                    <b-form-select required class="form-control select" v-model="row.usuario_id" :options="usuarios" value="id" value-field="id" text-field="nombres">
                    </b-form-select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label for="tipo" class="">Proyecto</label>
                    <b-form-select required class="form-control select" v-model="row.proyecto_id" :options="projects" value="id" value-field="id" text-field="titulo">
                    </b-form-select>
                  </div>
                </div>
                <!-- imagenes -->
                <div class="row bg-dark mt-3" v-if="meca_kanban_archivos.length > 0">
                  <span class="text-center text-primary mb-1 mt-1">ARCHIVOS</span>
                </div>
                <div class="row bg-dark p-1" v-if="meca_kanban_archivos.length > 0">
                  <div class="col-md-4" v-for="item of meca_kanban_archivos" :key="item.id">
                    <a target="_blank" :href="item.url">
                      <img
                        v-if="
                          item.url.split('.').pop() == 'jpg' ||
                          item.url.split('.').pop() == 'gif' ||
                          item.url.split('.').pop() == 'bmp' ||
                          item.url.split('.').pop() == 'jpeg' ||
                          item.url.split('.').pop() == 'png' ||
                          item.url.split('.').pop() == 'webp'
                        "
                        :src="item.url"
                        alt=""
                        style="margin: 5px !important"
                      />
                      <img
                        v-if="
                          item.url.split('.').pop() != 'jpg' &&
                          item.url.split('.').pop() != 'gif' &&
                          item.url.split('.').pop() != 'bmp' &&
                          item.url.split('.').pop() != 'jpeg' &&
                          item.url.split('.').pop() != 'png' &&
                          item.url.split('.').pop() != 'webp'
                        "
                        src="https://upload.wikimedia.org/wikipedia/commons/1/1b/SD_Download_Manager_logo.png"
                        alt=""
                        style="margin: 5px !important"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="text-right">
              <b-button class="btn btn-dark mr-2" @click="$bvModal.hide('kanbanModal')">Cerrar</b-button>
              <input class="btn btn-primary mr-2" type="submit" value="Guardar" />
            </div>
          </form>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import Badge from "./Badge.vue";
import { EventBus } from "@/js/event-bus.js";

// import TaskCard from "../superAdmin/TaskCard.vue";
export default {
  name: "App",
  components: {
    // TaskCard,
    Badge,
    draggable,
  },
  data() {
    return {
      bgcolor: [
        { nombre: "Desarrollo", color: "blue" },
        { nombre: "Maquetación", color: "cyan" },
        { nombre: "Backend", color: "red" },
        { nombre: "Diseño", color: "green" },
        { nombre: "Investigación", color: "gray" },
        { nombre: "App", color: "gray" },
        { nombre: "Desarrollo", color: "gray" },
        { nombre: "Info", color: "gray" },
        { nombre: "Otros", color: "gray" },
      ],
      columns: [],
      user: {},
      modalTitle: "Nueva tarjeta",
      row: {},
      listaTipo: [
        { tipo: "Maquetación" },
        { tipo: "Backend" },
        { tipo: "Diseño" },
        { tipo: "Investigación" },
        { tipo: "App" },
        { tipo: "Desarrollo" },
        { tipo: "Info" },
        { tipo: "Otros" },
      ],
      id: 0,
      tipo: 0,
      file1: null,
      finename1: null,
      meca_kanban_archivos: [],
      dataKanban: {},
      company_id: 0,
      projects: [],
      imageUrl: null,
      checklist: [],
      resulta: null,
    };
  },
  destroyed() {
    EventBus.$emit("isToogle", false);
  },
  async mounted() {
    this.$store.watch(
      (state) => [state.showEditItemsModal].join(),
      () => {
        this.refreshButton();
      }
    );
    this.id = this.$route.params.id;
    this.user = JSON.parse(localStorage.getItem("user")) || {};
    this.company_id = localStorage.getItem("company_id");
    console.log("usuario", this.user.id);

    await this.getData();
    await this.getUsers();
    await this.getProjects();
    EventBus.$emit("isToogle", true);
  },
  methods: {
    async changeOrderItem(dataItem, value) {
      let ordenActual = dataItem.orden;
      if (this.checklist.length > 1) {
        if (value == 1 && dataItem.orden < this.checklist.length) {
          //buscar si ya hay un item con ese orden, si hay, reemplazar orden
          let itemTmp = this.checklist.filter((x) => x.orden == dataItem.orden + 1);
          if (itemTmp.length > 0) {
            // alert("si hay ", dataItem.orden + 1);
            itemTmp[0].orden = ordenActual;
            await this.saveItemChecklist(itemTmp[0]);
          }
          dataItem.orden = dataItem.orden + 1;
        }
        if (value == 0 && dataItem.orden > 0) {
          let itemTmp = this.checklist.filter((x) => x.orden == dataItem.orden - 1);
          if (itemTmp.length > -1) {
            // alert("si hay ", dataItem.orden + 1);
            itemTmp[0].orden = ordenActual;
            await this.saveItemChecklist(itemTmp[0]);
          }
          dataItem.orden = dataItem.orden - 1;
        }
        await this.saveItemChecklist(dataItem);
        await this.getChecklist(this.row.id);
      }
    },
    saveItemTmeout(data) {
      clearTimeout(this.resulta);
      this.resulta = setTimeout(() => {
        this.saveItemChecklist(data);
      }, 2000);
    },
    async generarChecklist() {
      if (this.row.descripcion != null && this.row.descripcion.length > 0) {
        const lines = this.row.descripcion.split("\n");
        console.log("*****", lines);
        let contador = 0;
        for await (let itLines of lines) {
          contador = contador + 1;
          await this.saveItemChecklist({ idtmp: this.checklist.length + 1, titulo: itLines, terminado: 0, orden: contador });
        }
      }
    },
    async removeItemChecklist(data) {
      if (Object.prototype.hasOwnProperty.call(data, "id")) {
        await this.$store.dispatch("delete", { path: "/meca_kanban_checklist/delete/" + data.id });
      } else {
        let index = this.checklist.indexOf((x) => x == data);
        this.checklist.splice(index, 1);
      }
      await this.getChecklist(this.row.id);
      this.$toast.success("Eliminado correctamente!", {
        timeout: 2000,
      });
    },
    async saveItemChecklist(dataf) {
      let datasaveItemChecklist = dataf;
      // let idtmp = data.meca_kanban_item_id;
      delete datasaveItemChecklist.idtmp;
      let id = datasaveItemChecklist.id;
      if (Object.prototype.hasOwnProperty.call(datasaveItemChecklist, "id")) {
        delete datasaveItemChecklist.id;
        datasaveItemChecklist.fecha_terminada = this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD H:mm:ss");
        await this.$store.dispatch("put", { path: "meca_kanban_checklist/modify/" + id, data: datasaveItemChecklist });
      } else {
        datasaveItemChecklist.meca_kanban_item_id = this.row.id;
        datasaveItemChecklist.fecha_agregada = this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD H:mm:ss");
        await this.$store.dispatch("post", { path: "meca_kanban_checklist/new", data: datasaveItemChecklist });
      }
      // await this.getChecklist(data.meca_ka-nban_item_id);

      await this.saveData();
      // this.$toast.success("Registro guardado correctamente!", {
      //   timeout: 1000,
      // });
    },
    async saveData() {
      try {
        console.log("save");
        // let data = Object.assign({}, this.row);
        let datasaveData = { ...this.row };
        delete datasaveData.nombres;
        delete datasaveData.tituloproy;
        //data.kanban_id = this.id
        let res;
        // this.$isLoading(true);
        if (this.tipo == 1) {
          res = await this.$store.dispatch("post", { path: "meca_kanban_items/new", data: datasaveData });
        }
        if (this.tipo == 2) {
          let row = { ...this.row };
          delete row.id;
          delete row.nombres;
          delete row.tituloproy;
          delete row.color;
          row.porterminado = Number(((this.checklist.filter((x) => x.terminado == 1).length / this.checklist.length) * 100).toFixed(2));
          // alert(row.porterminado);
          console.log("data**********", this.row);
          res = await this.$store.dispatch("put", { path: "meca_kanban_items/modify/" + datasaveData.id, data: row });
        }
        // this.$isLoading(false);

        if (res.error) {
          // this.$swal({ title: "Aviso", text: "Error: " + res.error, icon: "error" });
          this.$toast.error("Error: " + res.error, {
            timeout: 2000,
          });

          // this.$isLoading(false);
        } else {
          // this.$isLoading(false);
          //this.$swal({title:"Aviso", text:"Datos almacenados correctamente", icon:"success"})
          this.$toast.success("Registro guardado correctamente!", {
            timeout: 2000,
          });
          if (this.tipo == 1) {
            this.row = {};
            this.$bvModal.hide("kanbanModal");
          }
          this.getData();
          //alert(this.id);
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
        this.$toast.error("Error: " + error, {
          timeout: 2000,
        });
      }
    },
    addItemCheckList() {
      this.checklist.push({ idtmp: this.checklist.length + 1, titulo: null, terminado: 0, orden: this.checklist.length + 1 });
      this.$forceUpdate();
    },
    handlePaste(event) {
      // Obtener el contenido pegado
      const pastedContent = this.$refs.pasteArea.value;

      // Puedes realizar alguna lógica adicional con el contenido pegado si es necesario

      // Mostrar el contenido en la imagen
      this.imageUrl = pastedContent;

      // Limpiar el área de pegado
      this.$refs.pasteArea.value = "";

      // Evitar que se maneje el pegado por defecto
      event.preventDefault();
    },
    async getChecklist(id) {
      try {
        let res = await this.$store.dispatch("get", { path: "meca_kanban_checklist/getAllBykanbanId/" + id });
        if (res.length > 0) {
          this.checklist = res;
        } else {
          this.checklist = [];
        }
      } catch (error) {
        this.checklist = [];
        console.error("error", error);
      }
    },
    async getProjects() {
      try {
        let res = await this.$store.dispatch("get", { path: "proyectos/getAllByCompany/" + this.company_id });
        if (res.length > 0) {
          this.projects = res;
          //this.Projects = this.items.length;
        } else {
          this.projects = [];
          //this.totalRows = 0;
        }
      } catch (error) {
        this.projects = [];
        //this.totalRows = 0;
        console.error("error", error);
      }
    },
    async getUsers() {
      try {
        let res = await this.$store.dispatch("get", { path: "usuarios/getAllByCompany/" + this.company_id });

        this.usuarios = res;
        // console.log(res);
      } catch (error) {
        this.usuarios = [];
        console.error("error", error);
      }
    },
    async getFilesKanbanId(id) {
      this.meca_kanban_archivos = [];
      // this.$isLoading(true);
      try {
        let res = await this.$store.dispatch("get", { path: "meca_kanban_archivos/getAllById/" + id });
        if (res.length > 0) {
          this.meca_kanban_archivos = res;
        }
        // this.$isLoading(false);
      } catch (error) {
        this.meca_kanban_archivos = [];
        // this.$isLoading(false);
        console.error("error", error);
      }
    },
    async onFileSelected(type) {
      try {
        this.file1 = event.target.files[0];
        console.log(this.file1);
        this.filename1 = Math.floor(Math.random() * 9999999999999 + 1);
        let extension = this.file1.name.split(".").pop();
        this.filename1 = type + "_" + this.filename1 + "." + extension;
        console.log(this.filename1);
        if (this.filename1 != null) {
          this.$isLoading(true);
          // let uploadImg = await this.$store.dispatch("onFileSelected", { filename: this.filename1, file: this.file1 });
          let uploadImg = await this.$store.dispatch("subirarchivosCustom", { filename: this.filename1, file: this.file1, type: type });
          console.log(uploadImg);
          if (uploadImg == true) {
            console.log("----", uploadImg);
            let data = {
              // url: "https://siscoper.mecasite.com/public/img/" + this.filename1,
              url: "https://admin.siscoper.net/img/kanban/" + this.filename1,
              nombre: "",
              kanban_item_id: this.row.id,
              kanban_id: this.row.kanban_id,
              usuario_id: this.user.id,
            };

            let res = await this.$store.dispatch("post", { path: "meca_kanban_archivos/new", data: data });
            this.getFilesKanbanId(this.row.id);

            console.log("res", res);
          }
          this.$isLoading(false);
        }

        this.$isLoading(false);
      } catch (error) {
        this.$isLoading(false);
        console.log("errror upload", error);
      }
    },
    seekColor(tipo) {
      let resulta = this.bgcolor.filter((x) => x.nombre == tipo);
      resulta = resulta[0].color;
      return resulta;
      //bgcolor.name == task.tipo ? bgcolor.color: ''
    },
    async showModal(type, column, task) {
      // console.log("taskkkkk ", task);
      this.meca_kanban_archivos = [];
      this.checklist = [];
      //alert(type)
      //nuevo
      this.tipo = type;
      if (type == 1) {
        this.modalTitle = "Nueva tarjeta";
        this.row = {};
        this.row.orden = column.tasks.length;
        this.row.kanban_id = column.id;
        this.row.fecha = this.$moment(new Date()).utc().format("YYYY-MM-DD");
        this.row.fecha_fin = this.$moment(new Date()).utc().format("YYYY-MM-DD");
      }
      //editar
      if (type == 2) {
        this.modalTitle = "Editar tarjeta";
        this.row = task;
        // console.log("tipo 2", this.row);
        this.row.fecha = this.$moment(task.fecha).utc().format("YYYY-MM-DD");
        this.row.fecha_fin = this.$moment(task.fecha_fin).utc().format("YYYY-MM-DD");
        this.getFilesKanbanId(this.row.id);
        await this.getChecklist(this.row.id);
        let filtroItems = this.checklist.filter((x) => x.orden == null);
        if (filtroItems.length > 0) {
          //let totalItems = this.checklist.length;
          let contadorItem = 1;
          for await (let itemCheck of this.checklist) {
            itemCheck.orden = contadorItem;
            await this.saveItemChecklist(itemCheck);
            contadorItem = contadorItem + 1;
          }
        }
      }
      this.$bvModal.show("kanbanModal");
      this.resizeTextarea();
      this.$forceUpdate();
    },
    async updateDrag(e, col) {
      // console.log('-->',e)
      //  console.log('-->',id)
      if (e.moved) {
        console.log("nuevo orden:", col);
        // console.log('element--->',e.moved)
        // console.log('col---->',col)
        await this.$store.dispatch("put", { path: "meca_kanban_orderItems/modify/" + e.moved.element.id, data: col.tasks });
        this.$toast.success("Actualizado...", {
          timeout: 1000,
        });
        //await this.getData()
      }
      if (e.added) {
        console.log("element--->", e.added.element);
        console.log("col---->", col);
        let data = {
          kanban_id: col.id,
        };
        await this.$store.dispatch("put", { path: "meca_kanban_items/modify/" + e.added.element.id, data: data });
        this.$toast.success("Actualizado...", {
          timeout: 1000,
        });
        await this.getData();
      }
    },
    async getData() {
      try {
        // this.$isLoading(true);
        let result = await this.$store.dispatch("get", { path: "meca_kanban/get/" + this.id });
        this.dataKanban = result;
        console.log(this.dataKanban);
        let res = await this.$store.dispatch("get", { path: "meca_kanban_lista/getAllById/" + this.id + "/active" });
        if (res.length > 0) {
          this.columns = res;
        }
        // this.$isLoading(false);
      } catch (error) {
        this.columns = [];
        // this.$isLoading(false);
        console.error("error", error);
      }
    },
    resizeTextarea() {
      const textarea = this.$refs.textarea;
      textarea.style.height = "auto";
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
  },
};
</script>

<style scoped>
.bg-opacity {
  filter: opacity(0.5);
}
.canvan-item {
  border-color: rgba(255, 255, 255, 0.103) !important;
  border-color: var(--primary-color);
  margin-right: 8px !important;
  margin-left: -15px !important;
}
.canvan-item-blur {
  backdrop-filter: blur(100px);
}
/* .primary-bg-opacity{
  background-color: var(--primary-dark-color);
} */

.bg-title-card {
  background-color: var(--primary-opacity-color-rgba) !important;
  color: white !important;
}
.column-width {
  min-width: 250px;
  width: 250px;
}
.column-height {
  height: 70vh !important;
}
/* Unfortunately @apply cannot be setup in codesandbox, 
but you'd use "@apply border opacity-50 border-blue-500 bg-gray-200" here */
.ghost-card {
  opacity: 0.5;
  /* background: #5db9f7; */
  border: 1px solid #4299e1;
}
.task-title {
  font-size: 14px;
}

.modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
}
.modal-fullscreen .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 100000;
}
img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 150px;
}

img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

.id {
  /* background-image: url('/images/fondo33.jpg'); */
}
.circle {
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 13px;
  color: #ffffff;
}
.circle2 {
  width: 35px;
  line-height: 35px;
  border-radius: 50%;
  text-align: center;
  font-size: 15px;
  color: #ffffff;
  background-color: rgb(176, 176, 176);
}

.bgcols {
  /* background-image: url("/images/cesped.jpg"); */
  /* background-color: ; */
  backdrop-filter: blur(3px) grayscale(100%) brightness(0.25);
  border-radius: 50px !;

  /* background-image: url("/images/signin-left10-bn.jpg") !important; */
}

.bgcols-bg {
  /* background-image: url("/images/signin-left10-bn.jpg") !important; */
  background-image: url("https://img.freepik.com/vector-gratis/fondo-hojas-verdes-tropicales_52683-36494.jpg") !important;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100px;
  max-height: 100%;
}
.flex::-webkit-scrollbar {
  width: 8px;
  background-color: rgba(255, 255, 255, 0.034);
  border-radius: 20px;
  height: 8px !important;
}
.conflextenido::-webkit-scrollbar:window-inactive {
  display: none;
  height: 5px !important;
}
.flex::-webkit-scrollbar-thumb {
  background-color: var(--primary-opacity-color-rgba);
  border-radius: 6px;
}
.auto-resize-textarea {
  overflow: hidden !important;
}
</style>
