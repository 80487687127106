<template>
    <div class="col-12">
        <div class="bd-content">
            <h1>List group</h1>
            <p>List groups are a flexible and powerful component for displaying a series of content. List group items can be modified to support just about any content within. They can also be used as navigation via various props.</p>
            <div class="alert alert-danger" role="alert">
                <strong>List group</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/components/list-group" target="_blank">Bootstrap List group documentation <i class="fa fa-external-link"></i></a>
            </div>
            <div class="border-top mt-5 pt-3">
                <h4 id="basic-example">Basic example</h4>
                <p>The most basic list group is an unordered list with list items and the proper classes. Build upon it with the options that follow, or with your own CSS as needed.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3 bg-transparent">
                            <div class="row">
                                <div class="col-lg-3 col-md-6">
                                    <b-list-group>
                                        <b-list-group-item>Cras justo odio</b-list-group-item>
                                        <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
                                        <b-list-group-item>Morbi leo risus</b-list-group-item>
                                        <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
                                        <b-list-group-item>Vestibulum at eros</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <b-list-group>
                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            <b-badge variant="primary" pill>14</b-badge>
                                            Cras justo odio
                                        </b-list-group-item>

                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            <b-badge variant="danger" pill>2</b-badge>
                                            Dapibus ac facilisis in
                                        </b-list-group-item>

                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            <b-badge variant="info" pill>1</b-badge>
                                            Morbi leo risus
                                        </b-list-group-item>
                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            <b-badge variant="warning" pill>2</b-badge>
                                            Dapibus ac facilisis in
                                        </b-list-group-item>
                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            <b-badge variant="secondary" pill>1</b-badge>
                                            Morbi leo risus
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <b-list-group>
                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            Cras justo odio
                                            <b-badge variant="primary" pill>14</b-badge>
                                        </b-list-group-item>

                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            Dapibus ac facilisis in
                                            <b-badge variant="danger" pill>2</b-badge>
                                        </b-list-group-item>

                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            Morbi leo risus
                                            <b-badge variant="info" pill>1</b-badge>
                                        </b-list-group-item>
                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            Dapibus ac facilisis in
                                            <b-badge variant="warning" pill>2</b-badge>
                                        </b-list-group-item>
                                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                                            Morbi leo risus
                                            <b-badge variant="secondary" pill>1</b-badge>
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <b-list-group>
                                        <b-list-group-item>
                                            <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                            Cras justo odio
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                            Dapibus ac facilisis in
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                            Morbi leo risus
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                            Porta ac consectetur ac
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <input class="form-check-input me-1" type="checkbox" value="" aria-label="...">
                                            Vestibulum at eros
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card class="mb-3 bg-transparent">
<pre class="language-html" data-lang="html">
<code>
&lt;!-- List Group: normal  --&gt;
&lt;b-list-group&gt;
&lt;b-list-group-item&gt;Cras justo odio&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Morbi leo risus&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Porta ac consectetur ac&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;Vestibulum at eros&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;

&lt;!-- List Group: with badge left side --&gt;
&lt;b-list-group&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    &lt;b-badge variant=&quot;primary&quot; pill&gt;14&lt;/b-badge&gt;
    Cras justo odio
&lt;/b-list-group-item&gt;

&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    &lt;b-badge variant=&quot;danger&quot; pill&gt;2&lt;/b-badge&gt;
    Dapibus ac facilisis in
&lt;/b-list-group-item&gt;

&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    &lt;b-badge variant=&quot;info&quot; pill&gt;1&lt;/b-badge&gt;
    Morbi leo risus
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    &lt;b-badge variant=&quot;warning&quot; pill&gt;2&lt;/b-badge&gt;
    Dapibus ac facilisis in
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    &lt;b-badge variant=&quot;secondary&quot; pill&gt;1&lt;/b-badge&gt;
    Morbi leo risus
&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;

&lt;!-- List Group: with badge pill right side --&gt;
&lt;b-list-group&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    Cras justo odio
    &lt;b-badge variant=&quot;primary&quot; pill&gt;14&lt;/b-badge&gt;
&lt;/b-list-group-item&gt;

&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    Dapibus ac facilisis in
    &lt;b-badge variant=&quot;danger&quot; pill&gt;2&lt;/b-badge&gt;
&lt;/b-list-group-item&gt;

&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    Morbi leo risus
    &lt;b-badge variant=&quot;info&quot; pill&gt;1&lt;/b-badge&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    Dapibus ac facilisis in
    &lt;b-badge variant=&quot;warning&quot; pill&gt;2&lt;/b-badge&gt;
&lt;/b-list-group-item&gt;
&lt;b-list-group-item class=&quot;d-flex justify-content-between align-items-center&quot;&gt;
    Morbi leo risus
    &lt;b-badge variant=&quot;secondary&quot; pill&gt;1&lt;/b-badge&gt;
&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;

&lt;!-- List Group: with checkbox --&gt;
&lt;b-list-group&gt;
&lt;b-list-group-item&gt;
    &lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot; aria-label=&quot;...&quot;&gt;
    Cras justo odio
&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;
    &lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot; aria-label=&quot;...&quot;&gt;
    Dapibus ac facilisis in
&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;
    &lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot; aria-label=&quot;...&quot;&gt;
    Morbi leo risus
&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;
    &lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot; aria-label=&quot;...&quot;&gt;
    Porta ac consectetur ac
&lt;/b-list-group-item&gt;
&lt;b-list-group-item&gt;
    &lt;input class=&quot;form-check-input me-1&quot; type=&quot;checkbox&quot; value=&quot;&quot; aria-label=&quot;...&quot;&gt;
    Vestibulum at eros
&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3 bg-transparent">
                            <div class="row">
                                <div class="col-lg-4 col-md-12">
                                    <b-list-group tag="ul" :flush="true" class="list-unstyled list-group-custom mb-0">
                                        <b-list-group-item tag="li" class="px-md-4 py-3">
                                            <b-link href="javascript:void(0);" class="d-flex">
                                                <img class="avatar rounded-circle" src="@/assets/images/xs/avatar1.jpg" alt="">
                                                <div class="flex-fill ms-3 text-truncate">
                                                    <h6 class="d-flex justify-content-between mb-0"><span>Chris Fox</span></h6>
                                                    <span class="text-muted">ChrisFox@alui.com</span>
                                                </div>
                                            </b-link>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="px-md-4 py-3">
                                            <b-link href="javascript:void(0);" class="d-flex">
                                                <div class="avatar rounded-circle no-thumbnail">RH</div>
                                                <div class="flex-fill ms-3 text-truncate">
                                                    <h6 class="d-flex justify-content-between mb-0"><span>Robert Hammer</span></h6>
                                                    <span class="text-muted">RobertHammer@alui.com</span>
                                                </div>
                                            </b-link>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="px-md-4 py-3">
                                            <b-link href="javascript:void(0);" class="d-flex">
                                                <img class="avatar rounded-circle" src="@/assets/images/xs/avatar3.jpg" alt="">
                                                <div class="flex-fill ms-3 text-truncate">
                                                    <h6 class="d-flex justify-content-between mb-0"><span>Orlando Lentz</span></h6>
                                                    <span class="text-muted">RobertHammer@alui.com</span>
                                                </div>
                                            </b-link>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="px-md-4 py-3">
                                            <b-link href="javascript:void(0);" class="d-flex">
                                                <img class="avatar rounded-circle" src="@/assets/images/xs/avatar4.jpg" alt="">
                                                <div class="flex-fill ms-3 text-truncate">
                                                    <h6 class="d-flex justify-content-between mb-0"><span>Barbara Kelly</span></h6>
                                                    <span class="text-muted">RobertHammer@alui.com</span>
                                                </div>
                                            </b-link>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="px-md-4 py-3">
                                            <b-link href="javascript:void(0);" class="d-flex">
                                                <img class="avatar rounded-circle" src="@/assets/images/xs/avatar5.jpg" alt="">
                                                <div class="flex-fill ms-3 text-truncate">
                                                    <h6 class="d-flex justify-content-between mb-0"><span>Robert Hammer</span></h6>
                                                    <span class="text-muted">RobertHammer@alui.com</span>
                                                </div>
                                            </b-link>
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <b-list-group tag="ul" :flush="true" class="list-unstyled list-group-custom mb-0">
                                        <b-list-group-item tag="li" class="d-flex py-3">
                                            <div class="avatar"><i class="fa fa-thumbs-o-up fa-lg"></i></div>
                                            <div class="flex-grow-1">
                                                <h6 class="mb-0">7 New Feedback <small class="float-right text-muted">Today</small></h6>
                                                <small>It will give a smart finishing to your site</small>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex py-3">
                                            <div class="avatar"><i class="fa fa-user fa-lg"></i></div>
                                            <div class="flex-grow-1">
                                                <h6 class="mb-0">New User <small class="float-right text-muted">10:45</small></h6>
                                                <small>I feel great! Thanks team</small>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex py-3">
                                            <div class="avatar"><i class="fa fa-question-circle fa-lg"></i></div>
                                            <div class="flex-grow-1">
                                                <h6 class="mb-0 text-warning">Server Warning <small class="float-right text-muted">10:50</small></h6>
                                                <small>Your connection is not private</small>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex py-3">
                                            <div class="avatar"><i class="fa fa-check fa-lg"></i></div>
                                            <div class="flex-grow-1">
                                                <h6 class="mb-0 text-danger">Issue Fixed <small class="float-right text-muted">11:05</small></h6>
                                                <small>WE have fix all Design bug with Responsive</small>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex py-3">
                                            <div class="avatar"><i class="fa fa-shopping-basket fa-lg"></i></div>
                                            <div class="flex-grow-1">
                                                <h6 class="mb-0">7 New Orders <small class="float-right text-muted">11:35</small></h6>
                                                <small>You received a new oder from Tina.</small>
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <b-list-group tag="ul" :flush="true" class="llist-unstyled list-group-custom mb-0">
                                        <b-list-group-item tag="li" class="d-flex align-items-center py-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id="list-group1">
                                                <label class="form-check-label" for="list-group1">Front Door</label>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex align-items-center py-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id="list-group2" checked="">
                                                <label class="form-check-label" for="list-group2">Air Conditioner</label>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex align-items-center py-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id="list-group3">
                                                <label class="form-check-label" for="list-group3">Enable RTL Mode!</label>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex align-items-center py-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id="list-group4">
                                                <label class="form-check-label" for="list-group4">Front Door</label>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex align-items-center py-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id="list-group5">
                                                <label class="form-check-label" for="list-group5">Air Conditioner</label>
                                            </div>
                                        </b-list-group-item>
                                        <b-list-group-item tag="li" class="d-flex align-items-center py-3">
                                            <div class="form-check form-switch">
                                                <input class="form-check-input" type="checkbox" id="list-group6" checked="">
                                                <label class="form-check-label" for="list-group6">Washing Machine</label>
                                            </div>
                                        </b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card class="mb-3 bg-transparent">
<pre class="language-html" data-lang="html">
<code>&lt;!-- List Group: User list  --&gt;
&lt;b-list-group :flush=&quot;true&quot;  tag=&quot;ul&quot; class=&quot;list-unstyled list-group-custom mb-0&quot;&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;px-md-4 py-3&quot;&gt;
        &lt;b-link href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
            &lt;img class=&quot;avatar rounded-circle&quot; src=&quot;@/assets/images/xs/avatar1.jpg&quot; alt=&quot;&quot;&gt;
            &lt;div class=&quot;flex-fill ms-3 text-truncate&quot;&gt;
                &lt;h6 class=&quot;d-flex justify-content-between mb-0&quot;&gt;&lt;span&gt;Chris Fox&lt;/span&gt;&lt;/h6&gt;
                &lt;span class=&quot;text-muted&quot;&gt;ChrisFox@alui.com&lt;/span&gt;
            &lt;/div&gt;
        &lt;/b-link&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;px-md-4 py-3&quot;&gt;
        &lt;b-link href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
            &lt;div class=&quot;avatar rounded-circle no-thumbnail&quot;&gt;RH&lt;/div&gt;
            &lt;div class=&quot;flex-fill ms-3 text-truncate&quot;&gt;
                &lt;h6 class=&quot;d-flex justify-content-between mb-0&quot;&gt;&lt;span&gt;Robert Hammer&lt;/span&gt;&lt;/h6&gt;
                &lt;span class=&quot;text-muted&quot;&gt;RobertHammer@alui.com&lt;/span&gt;
            &lt;/div&gt;
        &lt;/b-link&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;px-md-4 py-3&quot;&gt;
        &lt;b-link href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
            &lt;img class=&quot;avatar rounded-circle&quot; src=&quot;@/assets/images/xs/avatar3.jpg&quot; alt=&quot;&quot;&gt;
            &lt;div class=&quot;flex-fill ms-3 text-truncate&quot;&gt;
                &lt;h6 class=&quot;d-flex justify-content-between mb-0&quot;&gt;&lt;span&gt;Orlando Lentz&lt;/span&gt;&lt;/h6&gt;
                &lt;span class=&quot;text-muted&quot;&gt;RobertHammer@alui.com&lt;/span&gt;
            &lt;/div&gt;
        &lt;/b-link&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;px-md-4 py-3&quot;&gt;
        &lt;b-link href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
            &lt;img class=&quot;avatar rounded-circle&quot; src=&quot;@/assets/images/xs/avatar4.jpg&quot; alt=&quot;&quot;&gt;
            &lt;div class=&quot;flex-fill ms-3 text-truncate&quot;&gt;
                &lt;h6 class=&quot;d-flex justify-content-between mb-0&quot;&gt;&lt;span&gt;Barbara Kelly&lt;/span&gt;&lt;/h6&gt;
                &lt;span class=&quot;text-muted&quot;&gt;RobertHammer@alui.com&lt;/span&gt;
            &lt;/div&gt;
        &lt;/b-link&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;px-md-4 py-3&quot;&gt;
        &lt;b-link href=&quot;javascript:void(0);&quot; class=&quot;d-flex&quot;&gt;
            &lt;img class=&quot;avatar rounded-circle&quot; src=&quot;@/assets/images/xs/avatar5.jpg&quot; alt=&quot;&quot;&gt;
            &lt;div class=&quot;flex-fill ms-3 text-truncate&quot;&gt;
                &lt;h6 class=&quot;d-flex justify-content-between mb-0&quot;&gt;&lt;span&gt;Robert Hammer&lt;/span&gt;&lt;/h6&gt;
                &lt;span class=&quot;text-muted&quot;&gt;RobertHammer@alui.com&lt;/span&gt;
            &lt;/div&gt;
        &lt;/b-link&gt;
    &lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;

&lt;!-- List Group: Notification --&gt;
 &lt;b-list-group :flush=&quot;true&quot;  tag=&quot;ul&quot; class=&quot;list-unstyled list-group-custom mb-0&quot;&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex py-3&quot;&gt;
        &lt;div class=&quot;avatar&quot;&gt;&lt;i class=&quot;fa fa-thumbs-o-up fa-lg&quot;&gt;&lt;/i&gt;&lt;/div&gt;
        &lt;div class=&quot;flex-grow-1&quot;&gt;
            &lt;h6 class=&quot;mb-0&quot;&gt;7 New Feedback &lt;small class=&quot;float-right text-muted&quot;&gt;Today&lt;/small&gt;&lt;/h6&gt;
            &lt;small&gt;It will give a smart finishing to your site&lt;/small&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex py-3&quot;&gt;
        &lt;div class=&quot;avatar&quot;&gt;&lt;i class=&quot;fa fa-user fa-lg&quot;&gt;&lt;/i&gt;&lt;/div&gt;
        &lt;div class=&quot;flex-grow-1&quot;&gt;
            &lt;h6 class=&quot;mb-0&quot;&gt;New User &lt;small class=&quot;float-right text-muted&quot;&gt;10:45&lt;/small&gt;&lt;/h6&gt;
            &lt;small&gt;I feel great! Thanks team&lt;/small&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex py-3&quot;&gt;
        &lt;div class=&quot;avatar&quot;&gt;&lt;i class=&quot;fa fa-question-circle fa-lg&quot;&gt;&lt;/i&gt;&lt;/div&gt;
        &lt;div class=&quot;flex-grow-1&quot;&gt;
            &lt;h6 class=&quot;mb-0 text-warning&quot;&gt;Server Warning &lt;small class=&quot;float-right text-muted&quot;&gt;10:50&lt;/small&gt;&lt;/h6&gt;
            &lt;small&gt;Your connection is not private&lt;/small&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex py-3&quot;&gt;
        &lt;div class=&quot;avatar&quot;&gt;&lt;i class=&quot;fa fa-check fa-lg&quot;&gt;&lt;/i&gt;&lt;/div&gt;
        &lt;div class=&quot;flex-grow-1&quot;&gt;
            &lt;h6 class=&quot;mb-0 text-danger&quot;&gt;Issue Fixed &lt;small class=&quot;float-right text-muted&quot;&gt;11:05&lt;/small&gt;&lt;/h6&gt;
            &lt;small&gt;WE have fix all Design bug with Responsive&lt;/small&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex py-3&quot;&gt;
        &lt;div class=&quot;avatar&quot;&gt;&lt;i class=&quot;fa fa-shopping-basket fa-lg&quot;&gt;&lt;/i&gt;&lt;/div&gt;
        &lt;div class=&quot;flex-grow-1&quot;&gt;
            &lt;h6 class=&quot;mb-0&quot;&gt;7 New Orders &lt;small class=&quot;float-right text-muted&quot;&gt;11:35&lt;/small&gt;&lt;/h6&gt;
            &lt;small&gt;You received a new oder from Tina.&lt;/small&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;

&lt;!-- List Group: iOT list with switch --&gt;
&lt;b-list-group :flush=&quot;true&quot; tag=&quot;ul&quot; class=&quot;llist-unstyled list-group-custom mb-0&quot;&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex align-items-center py-3&quot;&gt;
        &lt;div class=&quot;form-check form-switch&quot;&gt;
            &lt;input class=&quot;form-check-input&quot; type=&quot;checkbox&quot; id=&quot;list-group1&quot;&gt;
            &lt;label class=&quot;form-check-label&quot; for=&quot;list-group1&quot;&gt;Front Door&lt;/label&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex align-items-center py-3&quot;&gt;
        &lt;div class=&quot;form-check form-switch&quot;&gt;
            &lt;input class=&quot;form-check-input&quot; type=&quot;checkbox&quot; id=&quot;list-group2&quot; checked=&quot;&quot;&gt;
            &lt;label class=&quot;form-check-label&quot; for=&quot;list-group2&quot;&gt;Air Conditioner&lt;/label&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex align-items-center py-3&quot;&gt;
        &lt;div class=&quot;form-check form-switch&quot;&gt;
            &lt;input class=&quot;form-check-input&quot; type=&quot;checkbox&quot; id=&quot;list-group3&quot;&gt;
            &lt;label class=&quot;form-check-label&quot; for=&quot;list-group3&quot;&gt;Enable RTL Mode!&lt;/label&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex align-items-center py-3&quot;&gt;
        &lt;div class=&quot;form-check form-switch&quot;&gt;
            &lt;input class=&quot;form-check-input&quot; type=&quot;checkbox&quot; id=&quot;list-group4&quot;&gt;
            &lt;label class=&quot;form-check-label&quot; for=&quot;list-group4&quot;&gt;Front Door&lt;/label&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex align-items-center py-3&quot;&gt;
        &lt;div class=&quot;form-check form-switch&quot;&gt;
            &lt;input class=&quot;form-check-input&quot; type=&quot;checkbox&quot; id=&quot;list-group5&quot;&gt;
            &lt;label class=&quot;form-check-label&quot; for=&quot;list-group5&quot;&gt;Air Conditioner&lt;/label&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
    &lt;b-list-group-item tag=&quot;li&quot; class=&quot;d-flex align-items-center py-3&quot;&gt;
        &lt;div class=&quot;form-check form-switch&quot;&gt;
            &lt;input class=&quot;form-check-input&quot; type=&quot;checkbox&quot; id=&quot;list-group6&quot; checked=&quot;&quot;&gt;
            &lt;label class=&quot;form-check-label&quot; for=&quot;list-group6&quot;&gt;Washing Machine&lt;/label&gt;
        &lt;/div&gt;
    &lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code>
</pre>                
                        </b-card>
                    </b-tab>
                </b-tabs>

                <h5 id="active-items">Active & Disabled items</h5>
                <p class="mb-0">Set the <code>active</code> prop on a <code>&lt;b-list-group-item&gt;</code> to indicate the current active selection.</p>
                <p>Set the <code>disabled</code> prop on a <code>&lt;b-list-group-item&gt;</code> to make it appear disabled (also works with actionable items. see below).</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3 bg-transparent">
                            <b-list-group  style="max-width: 400px;">
                                <b-list-group-item active>Cras justo odio</b-list-group-item>
                                <b-list-group-item >Dapibus ac facilisis in</b-list-group-item>
                                <b-list-group-item>Morbi leo risus</b-list-group-item>
                                <b-list-group-item disabled>Porta ac consectetur ac</b-list-group-item>
                                <b-list-group-item>Vestibulum at eros</b-list-group-item>
                            </b-list-group>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card class="mb-3 bg-transparent">
<pre class="language-html" data-lang="html">
<code>&lt;b-list-group style=&quot;max-width: 400px;&quot;&gt;
    &lt;b-list-group-item active&gt;Cras justo odio&lt;/b-list-group-item&gt;
    &lt;b-list-group-item &gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Morbi leo risus&lt;/b-list-group-item&gt;
    &lt;b-list-group-item disabled&gt;Porta ac consectetur ac&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Vestibulum at eros&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code>
</pre>                
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="border-top mt-5 pt-3">
                <h2 id="flush">Flush</h2>
                <p>Add <code>flush</code> props to remove some borders and rounded corners to render list group items edge-to-edge in a parent container (e.g., cards).</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3 bg-transparent">
                            <b-list-group :flush='true' style="max-width: 400px;">
                                <b-list-group-item>Cras justo odio</b-list-group-item>
                                <b-list-group-item>Dapibus ac facilisis in</b-list-group-item>
                                <b-list-group-item>Morbi leo risus</b-list-group-item>
                                <b-list-group-item>Porta ac consectetur ac</b-list-group-item>
                                <b-list-group-item>Vestibulum at eros</b-list-group-item>
                            </b-list-group>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card class="mb-3 bg-transparent">
<pre class="language-html" data-lang="html">
<code>&lt;b-list-group :flush=&apos;true&apos; style=&quot;max-width: 400px;&quot;&gt;
    &lt;b-list-group-item&gt;Cras justo odio&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Dapibus ac facilisis in&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Morbi leo risus&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Porta ac consectetur ac&lt;/b-list-group-item&gt;
    &lt;b-list-group-item&gt;Vestibulum at eros&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code>
</pre>                
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="border-top mt-5 pt-3">
                <h2 id="contextual-classes">Contextual classes</h2>
                <p>Use contextual variants to style list items with a stateful background and color, via the <code>variant</code> prop.</p>
                <p>Contextual variants also work with action items. Note the addition of the hover styling here not present in the previous example. Also supported is the <code>active</code> state; set it to indicate an active selection on a contextual list group item.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card class="mb-3 bg-transparent">
                            <div class="row">
                                <div class="col-lg-6 col-md-12">
                                    <p>Simple list group</p>
                                    <b-list-group>
                                        <b-list-group-item>Default list group item</b-list-group-item>
                                        <b-list-group-item variant="primary">Primary list group item</b-list-group-item>
                                        <b-list-group-item variant="secondary">Secondary list group item</b-list-group-item>
                                        <b-list-group-item variant="success">Success list group item</b-list-group-item>
                                        <b-list-group-item variant="danger">Danger list group item</b-list-group-item>
                                        <b-list-group-item variant="warning">Warning list group item</b-list-group-item>
                                        <b-list-group-item variant="info">Info list group item</b-list-group-item>
                                        <b-list-group-item variant="light">Light list group item</b-list-group-item>
                                        <b-list-group-item variant="dark">Dark list group item</b-list-group-item>
                                    </b-list-group>
                                </div>
                                <div class="col-lg-6 col-md-12">
                                    <p>list gorup with Anchor Link tag</p>
                                    <b-list-group>
                                        <b-list-group-item href="#">Default list group item</b-list-group-item>
                                        <b-list-group-item href="#" variant="primary">Primary list group item</b-list-group-item>
                                        <b-list-group-item href="#" variant="secondary">Secondary list group item</b-list-group-item>
                                        <b-list-group-item href="#" variant="success">Success list group item</b-list-group-item>
                                        <b-list-group-item href="#" variant="danger">Danger list group item</b-list-group-item>
                                        <b-list-group-item href="#" variant="warning">Warning list group item</b-list-group-item>
                                        <b-list-group-item href="#" variant="info">Info list group item</b-list-group-item>
                                        <b-list-group-item href="#" variant="light">Light list group item</b-list-group-item>
                                        <b-list-group-item href="#" variant="dark">Dark list group item</b-list-group-item>
                                    </b-list-group>
                                </div>
                            </div>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card class="mb-3 bg-transparent">
<pre class="language-html" data-lang="html">
<code>&lt;b-list-group&gt;
    &lt;b-list-group-item&gt;Default list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item variant=&quot;primary&quot;&gt;Primary list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item variant=&quot;secondary&quot;&gt;Secondary list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item variant=&quot;success&quot;&gt;Success list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item variant=&quot;danger&quot;&gt;Danger list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item variant=&quot;warning&quot;&gt;Warning list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item variant=&quot;info&quot;&gt;Info list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item variant=&quot;light&quot;&gt;Light list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item variant=&quot;dark&quot;&gt;Dark list group item&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;
&lt;b-list-group&gt;
    &lt;b-list-group-item href=&quot;#&quot;&gt;Default list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot; variant=&quot;primary&quot;&gt;Primary list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot; variant=&quot;secondary&quot;&gt;Secondary list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot; variant=&quot;success&quot;&gt;Success list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot; variant=&quot;danger&quot;&gt;Danger list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot; variant=&quot;warning&quot;&gt;Warning list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot; variant=&quot;info&quot;&gt;Info list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot; variant=&quot;light&quot;&gt;Light list group item&lt;/b-list-group-item&gt;
    &lt;b-list-group-item href=&quot;#&quot; variant=&quot;dark&quot;&gt;Dark list group item&lt;/b-list-group-item&gt;
&lt;/b-list-group&gt;</code>
</pre>                
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
    </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'ListgroupComponent',
    data() {
        return {
        }
    },methods:{

    },mounted() {
        Prism.highlightAll()
    }
}
</script>
<style scoped>

</style>