import Vue from 'vue'
import VueRouter from 'vue-router'

import StaterPageComponent from '@/components/common/main/staterpage/StaterPageComponent.vue'
import DefaultLayout from '@/components/admin/layouts/layout/DefaultLayout.vue'
import MyDefaultLayout from '@/components/admin/layouts/layout/MyDefaultLayout.vue'
import AuthenticationLayout from '@/components/admin/layouts/layout/AuthenticationLayout.vue'
import DummyView from '@/components/common/main/dashboard/DummyView.vue'
// import IndexComponent from '@/components/common/main/dashboard/IndexComponent.vue'
import IndexComponent from '@/components/common/main/dashboard/SiscoperIndexComponent.vue'

import CalendarComponent from '@/components/common/main/calendar/CalendarComponent.vue'
import ChatComponent from '@/components/common/main/chat/ChatComponent.vue'
import TimelineComponent from '@/components/common/main/pages/TimelineComponent.vue'
import ProfileComponent from '@/components/common/main/pages/ProfileComponent.vue'
import ImageGalleryComponent from '@/components/common/main/pages/ImageGalleryComponent.vue'
import InvoicesComponent from '@/components/common/main/pages/InvoicesComponent.vue'
import PricingComponent from '@/components/common/main/pages/PricingComponent.vue'
import TeamsBoardComponent from '@/components/common/main/pages/TeamsBoardComponent.vue'
import FaqsComponent from '@/components/common/main/pages/FaqsComponent.vue'
import WidgetComponent from '@/components/common/main/widgets/WidgetComponent.vue'
import ChartsComponent from '@/components/common/main/charts/ChartsComponent.vue'
import TableComponent from '@/components/common/main/tables/TableComponent.vue'
import AlertsComponent from '@/components/common/main/uielements/AlertsComponent.vue'
import BadgesComponent from '@/components/common/main/uielements/BadgesComponent.vue'
import BreadcrumbComponent from '@/components/common/main/uielements/BreadcrumbComponent.vue'
import ButtonsComponent from '@/components/common/main/uielements/ButtonsComponent.vue'
import CardsComponent from '@/components/common/main/uielements/CardsComponent.vue'
import CarouselComponent from '@/components/common/main/uielements/CarouselComponent.vue'
import CollapseComponent from '@/components/common/main/uielements/CollapseComponent.vue'
import DropdownsComponent from '@/components/common/main/uielements/DropdownsComponent.vue'
import ListgroupComponent from '@/components/common/main/uielements/ListgroupComponent.vue'
import ModalComponent from '@/components/common/main/uielements/ModalComponent.vue'
import NavsComponent from '@/components/common/main/uielements/NavsComponent.vue'
import NavbarComponent from '@/components/common/main/uielements/NavbarComponent.vue'
import PaginationComponent from '@/components/common/main/uielements/PaginationComponent.vue'
import PopoversComponent from '@/components/common/main/uielements/PopoversComponent.vue'
import ProgressComponent from '@/components/common/main/uielements/ProgressComponent.vue'
import ScrollspyComponent from '@/components/common/main/uielements/ScrollspyComponent.vue'
import SpinnersComponent from '@/components/common/main/uielements/SpinnersComponent.vue'
import ToastsComponent from '@/components/common/main/uielements/ToastsComponent.vue'
import TooltipsComponent from '@/components/common/main/uielements/TooltipsComponent.vue'

//////////

import Customers from '@/components/common/main/purchasesSales/Customers.vue'
import BrachOffices from '@/components/common/main/purchasesSales/BrachOffices.vue'
import Suppliers from '@/components/common/main/purchasesSales/Suppliers.vue'
import Purchases from '@/components/common/main/purchasesSales/Purchases.vue'
import Products from '@/components/common/main/purchasesSales/Products.vue'
import Sales from '@/components/common/main/purchasesSales/Sales.vue'
import newSaleV2 from '@/components/common/main/purchasesSales/salesDetailv2.vue'
import salesDetail from '@/components/common/main/purchasesSales/salesDetail.vue'
import purchaseDetail from '@/components/common/main/purchasesSales/purchaseDetail.vue'
import invoicePreview from '@/components/common/main/purchasesSales/InvoicePreview.vue'
import purchasePreview from '@/components/common/main/purchasesSales/purchasePreview.vue'
import Balance from '@/components/common/main/purchasesSales/Balance.vue'
import Categories from '@/components/common/main/purchasesSales/Categories.vue'
import NewFreeSale from '@/components/common/main/purchasesSales/NewFreeSale.vue'
import asia from '@/components/common/main/purchasesSales/asia.vue'
import SCharts from '@/components/common/main/purchasesSales/SCharts.vue'

import Reports from '@/components/common/main/finance/Reports.vue'
import ReportList from '@/components/common/main/finance/ReportList.vue'

import Jobs from '@/components/common/main/superAdmin/Jobs.vue'
import Tv from '@/components/common/main/superAdmin/Tv.vue'

import pageBoards from '@/components/common/main/projectManager/pageBoards.vue'
import ItemsKanbanCalendarComponent from '@/components/common/main/calendar/ItemsKanbanCalendarComponent.vue'
import projects from '@/components/common/main/projectManager/projects.vue'
import reminders from '@/components/common/main/projectManager/Reminder.vue'
import note from '@/components/common/main/projectManager/note.vue'
import tasks from '@/components/common/main/projectManager/tasks.vue'
import media from '@/components/common/main/projectManager/media.vue'
import media2 from '@/components/common/main/projectManager/media2.vue'

import Ledger from '@/components/common/main/finance/Ledger.vue'
import Accounts from '@/components/common/main/finance/Accounts.vue'
import AccountStatus from '@/components/common/main/finance/AccountStatus.vue'

import Kanban from '@/components/common/main/projectManager/Kanban.vue'

import AccessControl from '@/components/common/main/superAdmin/AccessControl.vue'
import Competencia from '@/components/common/main/superAdmin/Competence.vue'

import Quotes from '@/components/common/main/purchasesSales/Quotes.vue'
import Config from '@/components/common/main/Config/Config.vue'
import Users from '@/components/common/main/users/UserList.vue'
import Positions from '@/components/common/main/positions/Positions.vue'

import invoice1 from '@/components/common/main/invoice1/invoice1.vue'
import invoice2 from '@/components/common/main/invoice2/invoice2.vue'
import invoice3 from '@/components/common/main/invoice3/invoice3.vue'

import Tracking from '@/components/common/main/Tracking/Tracking.vue'
// import NewSale from '@/components/common/main/purchasesSales/NewSale.vue'


Vue.component('default-layout', DefaultLayout)
Vue.component('mydefaultlayout', MyDefaultLayout)
Vue.component('authentication-layout', AuthenticationLayout)


Vue.use(VueRouter)

// const routes = [  
//   { path: '/', name: 'login', component: () => import('../views/login.vue')},

//   { path: '/home', name: 'home', component: () => import('../views/home.vue')},
//   { path: '/regions', name: 'regions', component: () => import('../views/regions.vue')},
//   { path: '/regions/:id', name: 'stations', component: () => import('../views/stations/stations.vue')},

//   { path: '/warehouses', name: 'warehouses', component: () => import('../views/warehouses/warehouses.vue')},
//   { path: '/warehouses/:id/categories', name: 'caterogires', component: () => import('../views/warehouses/productCategories.vue')},
//   { path: '/station/:id/assignWarehouses', name: 'assignwWarehouses', component: () => import('../views/stations/assignWarehouses.vue')},
//   { path: '/products', name: 'products', component: () => import('../views/products.vue')},

//   { path: '/providers', name: 'providers', component: () => import('../views/providers.vue')},
//   { path: '/accounts', name: 'accounts', component: () => import('../views/accounts.vue')},

//   { path: '/purchases', name: 'purchases', component: () => import('../views/purchases/purchases.vue')},
//   { path: '/purchases/create', name: 'createPurchase', component: () => import('../views/purchases/createPurchase.vue')},
// ] 


const routes = [
  // {
  //   path: '/',
  //   redirect: '/dashboard/index',
  // },

  {
    path: '/asia',
    name: 'asia', meta: { layout: 'mydefaultlayout' },
    component: asia,

  },
  {
    // path: '/',
    // name: 'login', component:()=>import('@/components/admin/authentication/Sign_inComponent.vue')
    //redirect: '/authentication/auth-login',
    path: '/', name: 'login', meta: { layout: 'authentication' }, component: () => import('@/components/admin/authentication/Sign_inComponent.vue')
  },
  {
    // path: '/dashboard/index',
    path: '/dashboard/index',
    name: 'defaultview',
    component: IndexComponent
  },

  {
    path: '/purchasesSales',
    redirect: '/purchasesSales-*',
    component: DummyView,
    children: [
      { path: 'purchasesSales-customers', component: Customers, name: 'customers' },
      { path: 'purchasesSales-brachOffices', component: BrachOffices, name: 'brachOffices' },
      { path: 'purchasesSales-suppliers', component: Suppliers, name: 'suppliers' },
      { path: 'purchasesSales-Purchases', component: Purchases, name: 'Purchases' },
      { path: 'purchasesSales-Products', component: Products, name: 'Products' },
      { path: 'purchasesSales-Sales', component: Sales, name: 'Sales' },
      { path: 'salesDetail', component: salesDetail, name: 'salesDetail' },
      { path: 'newSaleV2', component: newSaleV2, name: 'newSaleV2' },
      { path: 'purchaseDetail', component: purchaseDetail, name: 'purchaseDetail' },
      { path: 'invoicePreview/:id', component: invoicePreview, name: 'invoicePreview' },
      { path: 'Balance', component: Balance, name: 'Balance' },
      { path: 'Categories', component: Categories, name: 'Categories' },
      { path: 'purchasePreview/:id', component: purchasePreview, name: 'purchasePreview' },
      { path: 'newFreeSale', component: NewFreeSale, name: 'newFreeSale' },
      { path: 'Quotes', component: Quotes, name: 'Quotes' },
      { path: 'SCharts', component: SCharts, name: 'SCharts' }

    ]
  },
  {
    path: '/projectManager',
    redirect: '/projectManager-*',
    component: DummyView,
    children: [
      { path: 'pageBoards', component: pageBoards, name: 'pageBoards' },
      { path: 'kanban/:id', component: Kanban, name: 'kanban' },
      { path: 'ItemsKanbanCalendar', component: ItemsKanbanCalendarComponent, name: 'ItemsKanbanCalendarComponent' },
      { path: 'projects', component: projects, name: 'projects' },
      { path: 'note', component: note, name: 'note' },
      { path: 'tasks', component: tasks, name: 'tasks' },
      { path: 'reminders', component: reminders, name: 'reminders' },
      { path: 'Media', component: media, name: 'media' },
      { path: 'Media2', component: media2, name: 'media2' },
      // { path: 'purchasesSales-brachOffices', component: BrachOffices, name:'brachOffices'},
      // { path: 'purchasesSales-suppliers', component: Suppliers, name:'suppliers'},
    ]
  },
  {
    path: '/brachOffices',
    redirect: '/*',
    component: DummyView,
    children: [
      { path: 'brachOffices', component: BrachOffices, name: 'brachOffices' },

      // { path: 'ReportList', component: ReportList, name: 'ReportList' },

      // { path: 'kanban', component: Kanban, name:'kanban'},
      // { path: 'purchasesSales-brachOffices', component: BrachOffices, name:'brachOffices'},
      // { path: 'purchasesSales-suppliers', component: Suppliers, name:'suppliers'},
    ]
  },
  {
    path: '/accounting',
    redirect: '/accounting-*',
    component: DummyView,
    children: [
      { path: 'ledger', component: Ledger, name: 'ledger' },
      { path: 'accounts', component: Accounts, name: 'accounts' },
      { path: 'accountStatus/:account?/:invoice?', component: AccountStatus, name: 'accountStatus' },
      { path: 'Reports', component: Reports, name: 'Reports' },
      { path: 'brachOffices', component: BrachOffices, name: 'brachOffices' },

      // { path: 'ReportList', component: ReportList, name: 'ReportList' },

      // { path: 'kanban', component: Kanban, name:'kanban'},
      // { path: 'purchasesSales-brachOffices', component: BrachOffices, name:'brachOffices'},
      // { path: 'purchasesSales-suppliers', component: Suppliers, name:'suppliers'},
    ]
  },
  {
    path: '/reports',
    redirect: '/reports-*',
    component: DummyView,
    children: [

      { path: 'ReportList/:empresa_id/:tipo?/:id?/:desde?/:hasta?', component: ReportList, name: 'ReportList' },

      // { path: 'kanban', component: Kanban, name:'kanban'},
      // { path: 'purchasesSales-brachOffices', component: BrachOffices, name:'brachOffices'},
      // { path: 'purchasesSales-suppliers', component: Suppliers, name:'suppliers'},
    ]
  },
  {
    path: '/superAdmin',
    redirect: '/superAdmin-*',
    component: DummyView,
    children: [
      { path: 'superAdmin-jobs', component: Jobs, name: 'jobs' },
      { path: 'AccessControl', component: AccessControl, name: 'AccessControl' },
      { path: 'Tv', component: Tv, name: 'Tv' },
      { path: 'Competence', component: Competencia, name: 'Competence' },
      // { path: 'kanban', component: Kanban, name:'kanban'},
      // { path: 'purchasesSales-brachOffices', component: BrachOffices, name:'brachOffices'},
      // { path: 'purchasesSales-suppliers', component: Suppliers, name:'suppliers'},
    ]
  },
  {
    path: '/track',
    redirect: '/track-*',
    component: DummyView,
    children: [
      { path: 'Tracking', component: Tracking, name: 'Tracking' },

    ]
  },
  {
    path: '/authentication',
    redirect: '/auth-*',
    component: DummyView,
    children: [
      { path: 'auth-login', name: 'login', meta: { layout: 'authentication' }, component: () => import('@/components/admin/authentication/Sign_inComponent.vue') },
      { path: 'auth-signup', name: 'register', meta: { layout: 'authentication' }, component: () => import('@/components/admin/authentication/Sign_upComponent.vue') },
      { path: 'auth-password-reset', name: 'fpassword', meta: { layout: 'authentication' }, component: () => import('@/components/admin/authentication/ResetPasswordComponent.vue') },
      { path: 'auth-two-step', name: 'verification', meta: { layout: 'authentication' }, component: () => import('@/components/admin/authentication/TwostepVerificationComponent.vue') },
      { path: 'auth-404', name: '404', meta: { layout: 'authentication' }, component: () => import('@/components/admin/authentication/PageNotFoundComponent.vue') },

    ]
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: CalendarComponent
  },
  {
    path: '/chat',
    name: 'chat',
    component: ChatComponent
  },
  {
    path: '/config',
    name: 'config',
    component: Config
  },
  {
    path: '/positions',
    name: 'positions',
    component: Positions
  },
  {
    path: '/invoice1',
    name: 'invoice1',
    component: invoice1
  },
  {
    path: '/invoice2',
    name: 'invoice2',
    component: invoice2
  },
  {
    path: '/invoice3',
    name: 'invoice3',
    component: invoice3
  },
  {
    path: '/users',
    name: 'users',
    component: Users
  },
  {
    path: '/pages',
    redirect: '/page-*',
    component: DummyView,
    children: [
      { path: 'page-timeline', component: TimelineComponent },
      { path: 'page-profile', component: ProfileComponent, name: 'profile' },
      { path: 'page-gallery', component: ImageGalleryComponent },
      { path: 'page-invoices', component: InvoicesComponent, name: 'invoice' },
      { path: 'page-pricing', component: PricingComponent },
      { path: 'page-teamsboard', component: TeamsBoardComponent },
      { path: 'page-faqs', component: FaqsComponent },
    ]
  },
  {
    path: '/widget',
    name: 'widget',
    component: WidgetComponent
  },
  {
    path: '/stater-page',
    name: 'staterpage',
    component: StaterPageComponent,
    meta: { isFooter: true },
  },
  {
    path: '/charts',
    name: 'charts',
    component: ChartsComponent,
    meta: { isFooter: true },
  },
  {
    path: '/table',
    name: 'table',
    component: TableComponent,
    meta: { isFooter: true },
  },
  {
    path: '/ui-elements',
    redirect: '/ui-*',
    component: DummyView,
    children: [
      { path: 'ui-alerts', component: AlertsComponent, meta: { isFooter: true } },
      { path: 'ui-badge', component: BadgesComponent, meta: { isFooter: true } },
      { path: 'ui-breadcrumb', component: BreadcrumbComponent, meta: { isFooter: true } },
      { path: 'ui-buttons', component: ButtonsComponent, meta: { isFooter: true } },
      { path: 'ui-cards', component: CardsComponent, meta: { isFooter: true } },
      { path: 'ui-carousel', component: CarouselComponent, meta: { isFooter: true } },
      { path: 'ui-collapse', component: CollapseComponent, meta: { isFooter: true } },
      { path: 'ui-dropdowns', component: DropdownsComponent, meta: { isFooter: true } },
      { path: 'ui-listgroup', component: ListgroupComponent, meta: { isFooter: true } },
      { path: 'ui-modal', component: ModalComponent, meta: { isFooter: true } },
      { path: 'ui-navs', component: NavsComponent, meta: { isFooter: true } },
      { path: 'ui-navbar', component: NavbarComponent, meta: { isFooter: true } },
      { path: 'ui-pagination', component: PaginationComponent, meta: { isFooter: true } },
      { path: 'ui-popovers', component: PopoversComponent, meta: { isFooter: true } },
      { path: 'ui-progress', component: ProgressComponent, meta: { isFooter: true } },
      { path: 'ui-scrollspy', component: ScrollspyComponent, meta: { isFooter: true } },
      { path: 'ui-spinners', component: SpinnersComponent, meta: { isFooter: true } },
      { path: 'ui-toasts', component: ToastsComponent, meta: { isFooter: true } },
      { path: 'ui-tooltips', component: TooltipsComponent, meta: { isFooter: true } },
    ]
  },
  {
    path: '*',
    redirect: '/dashboard/index',
  }
]


// const router = new VueRouter({
//   mode: 'history',
//   base: process.env.BASE_URL,
//   routes
// })

const router = new VueRouter({
  //mode: 'history',
  routes,
  base: '/'
})
router.afterEach(() => {
  Vue.nextTick(() => {
    document.title = ':: SISCOPER :: ' + 'Main';
  });
})

export default router
