<template>
  <div class="Simple invoice" id="Invoice-Simple">
    <div class="row justify-content-center">
      <div class="col-lg-8 col-md-12">
        <div class="card p-xl-5 p-lg-4 p-0">
          <div class="card-body">
            <div class="mb-3 pb-3 border-bottom">
              Fecha:
              <b>{{ $moment(simple_invoice.fecha_i).utc().format("DD-MM-YYYY") }}</b>
              <!-- <strong>{{ simple_invoice.fecha_i }}</strong> -->
              <span class="float-right"> <strong>Estado: </strong> {{ simple_invoice.estado }}</span>
            </div>

            <div class="row mb-4">
              <div class="col-sm-6">
                <span class="mb-3"><b>Nombre:</b> {{ simple_invoice.nombre }}</span>

                <!-- <div>{{ simple_invoice.from[0].line1 }}</div>
                                <div>{{ simple_invoice.from[0].line2 }}</div>
                                <div>Email: {{ simple_invoice.from[0].email }}</div>
                                <div>Phone: {{ simple_invoice.from[0].phone }}</div> -->
              </div>

              <!-- <div class="col-sm-6">
                                <h6 class="mb-3">To:</h6>
                                <div><strong>{{ simple_invoice.to[0].to_name }}</strong></div>
                                <div>Attn: {{ simple_invoice.to[0].line1 }}</div>
                                <div>{{ simple_invoice.to[0].line2 }}</div>
                                <div>Email:{{ simple_invoice.to[0].email }}</div>
                                <div>Phone: {{ simple_invoice.to[0].phone }}</div>
                            </div> -->
            </div>

            <div class="table-responsive-sm">
              <b-table striped responsive :items="simple_invoice.details" :fields="fields">
                <template #cell(id)="data">
                  {{ data.index + 1 }}
                </template>
                <template #cell(nombre)="data">
                  {{ data.item.nombre == null || data.item.nombre == "" ? data.item.descripcion : data.item.nombre }}
                </template>
                <template #cell(precio_venta)="data">{{ config.moneda + data.item.precio_venta.toFixed(2) }} </template>
                <template #cell(total)="data">{{ config.moneda + data.item.total.toFixed(2) }} </template>
                <!-- <template #row-details="row">
                                    <b-card>
                                    <ul>
                                        <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                                    </ul>
                                    </b-card>
                                </template> -->
              </b-table>
            </div>

            <div class="row">
              <div class="col-lg-4 col-sm-5"></div>

              <div class="col-lg-4 col-sm-5 ms-auto">
                <table class="table table-clear">
                  <tbody>
                    <tr>
                      <td><strong>Subtotal</strong></td>
                      <td class="text-end">{{ config.moneda + (simple_invoice.total_venta - simple_invoice.iva).toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td><strong>IVA (15%)</strong></td>
                      <td class="text-end">{{ config.moneda + simple_invoice.iva.toFixed(2) }}</td>
                    </tr>
                    <tr>
                      <td><strong>Total</strong></td>
                      <td class="text-end">
                        <strong>{{ config.moneda + simple_invoice.total_venta.toFixed(2) }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="row">
              <!-- <div class="col-lg-12">
                                <h6>Terms &amp; Condition</h6>
                                <p class="text-muted">You know, being a test pilot isn't always the healthiest business
                                in the world. We predict too much for the next year and yet far
                                too little for the next 10.</p>
                            </div> -->
              <!-- <div class="col-lg-12 text-end">
                <button type="button" class="btn btn-outline-secondary btn-lg my-1 mr-1"><i class="fa fa-print"></i> Imprimir</button>
                <button type="button" class="btn btn-info btn-lg my-1"><i class="fa fa-paper-plane-o"></i> Enviar Factura</button>
              </div> -->
            </div>
          </div>
          <div class="text-right mt-4">
            <i>Siscoper.net</i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      table: "meca_ventas",
      invoice_id: 0,
      simple_invoice: {},
      fields: [
        { key: "id", label: "#", thClass: "text-center", tdClass: "text-center" },
        { key: "nombre", label: "ITEM" },
        // { key: 'des', label: 'DESCRIPTION'},
        { key: "precio_venta", label: "precio", thClass: "text-end", tdClass: "text-end" },
        { key: "cantidad", label: "QTY", thClass: "text-center", tdClass: "text-center" },
        { key: "total", label: "TOTAL", thClass: "text-end", tdClass: "text-end" },
      ],
      items: [],
      config: {},
    };
  },
  async mounted() {
    this.config = JSON.parse(localStorage.getItem("config"));

    this.invoice_id = this.$route.params.id;
    console.log(this.invoice_id);
    await this.getData();
  },
  methods: {
    async getData() {
      try {
        this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: this.table + "/get/" + this.invoice_id });
        this.$isLoading(false);
        if (res) {
          this.simple_invoice = res;
          console.log(this.simple_invoice);
        }
      } catch (error) {
        this.$isLoading(false);
        this.simple_invoice = [];
        console.error("error", error);
      }
    },
  },
};
</script>

<style></style>
