<template>
  <div class="row clearfix">
    <h3 class="text-center">Reportes</h3>
    <div class="col-md-12">
      <h5>Reportes de ventas</h5>
      <div class="row p-4 mb-4">
        <div class="col-6">
          <div class="col-12">
            <!-- /:empresa_id/:tipo?/:id?/:desde?/:hasta? -->
            <input @click="$router.push('/reports/ReportList/' + company_id + '/0')" type="button" class="btn btn-primary w-50" value="Ventas x item" />
          </div>
          <div class="col-12 mt-3">
            <input @click="$router.push('/reports/ReportList/' + company_id + '/1')" type="button" class="btn btn-primary w-50" value="Ventas x clientes" />
          </div>
        </div>
        <div class="col-6">
          <div class="col-12">
            <input @click="showModal('', 1)" type="button" class="btn btn-primary w-50" value="Ventas diarias" />
          </div>
          <div class="col-12 mt-3">
            <input @click="showModal('', 1)" type="button" class="btn btn-primary w-50" value="Ventas x vendedor" />
          </div>
          <!-- <div class="col-12 mt-3">
            <input @click="showModal('', 1)" type="button" class="btn btn-primary" value="Ventas x clientes" />
          </div>
          <div class="col-12 mt-3">
            <input @click="showModal('', 1)" type="button" class="btn btn-primary" value="Ventas x vendedor" />
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import moment from "moment";

export default {
  name: "TableComponent",

  data() {
    return {
      options: [{ estado: "Nuevo" }, { estado: "Contactado" }, { estado: "En prueba" }, { estado: "Contratado" }, { estado: "Rechazado" }, { estado: "Despedido" }],
      fields: [
        { key: "id", label: "id", sortable: true, sortDirection: "desc" },
        {
          key: "nombreusuario",
          label: "Nombre",
          sortable: true,
          class: "text-center",
        },
        { key: "estado", label: "Estado", sortable: true, class: "text-center" },
        { key: "rol", label: "rol", sortable: true, class: "text-center" },

        { key: "action", label: "Acciones", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      ///////
      table: "usuarios",
      items: [],
      row: {},
      company_id: 0,
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo registro",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      municipios: [
        { nombre: "Managua" },
        { nombre: "Altagracia" },
        { nombre: "Belén" },
        { nombre: "Buenos Aires" },
        { nombre: "Cárdenas" },
        { nombre: "Moyogalpa" },
        { nombre: "Potosí" },
        { nombre: "Rivas" },
        { nombre: "San Jorge" },
        { nombre: "San Juan del Sur" },
        { nombre: "Tola" },
      ],
      singleTable: "Registro",
      textTable: "Trabajo",
      rubros: [],
      rolUsuario: [
        { tipo: "Usuario estándar", mapa: "1,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0" },
        { tipo: "Vendedor", mapa: "0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,1" },
      ],
      superAdminRolUsuario: [
        { tipo: "Usuario estándar", mapa: "1,1,0,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,0" },
        { tipo: "Vendedor", mapa: "0,0,0,0,0,0,1,0,0,0,0,0,0,0,0,1,0,0,0,0,0,0,1" },
        { tipo: "Administrador", mapa: "1,1,1,0,1,1,1,1,0,0,1,1,1,1,1,1,1,1,1,1,1,1" },
      ],
      company: {},
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.rol = localStorage.getItem("rol");
    this.getData();
    this.getRubros();
  },
  methods: {
    async getDataCompany() {
      try {
        let res = await this.$store.dispatch("get", { path: "empresas/getById/" + this.company_id });
        this.company = res[0];
        console.log("item company", this.company);
        // console.log(res);
      } catch (error) {
        this.company = [];
        console.error("error", error);
      }
    },
    actualizaMapa(e) {
      console.log(e);
    },
    async showModal(item, id) {
      console.log("----->", item);
      await this.getDataCompany();
      if (id == 1) {
        //verificar si puede crear mas usuarios
        if (this.items.length == this.company.usuarios || this.items.length > this.company.usuarios) {
          // alert("no puedes crear mas usuarios");
          this.$swal({
            title: "Aviso",
            text: "No puedes crear mas usuarios, debes mejorar plan.",
            icon: "error",
          });
          return;
        }
        this.modalTitle = "Nuevo " + this.singleTable;
        this.row = {
          mapa: "1,1,1,0,1,0,0,0",
          empresa_id: this.company_id,
          urlfoto:
            "https://previews.123rf.com/images/stalkerstudent/stalkerstudent1601/stalkerstudent160101173/50961996-vector-icono-de-usuario-estilo-de-dise%C3%B1o-plano-eps-10.jpg",
          rol: "Usuario estándar",
        };
        this.$bvModal.show("exampleModalCenter");
      } else {
        this.row = item;
        this.modalTitle = "Editar " + this.singleTable;
        this.$bvModal.show("exampleModalCenter");
        console.log(this.row);
      }
    },
    async getRubros() {
      try {
        let res = await this.$store.dispatch("get", { path: "rubros/getAll" });
        if (res.length > 0) {
          this.rubros = res;
        } else {
          this.rubros = [];
        }
      } catch (error) {
        this.rubros = [];
        console.error("error", error);
      }
    },
    async getData() {
      try {
        let res = await this.$store.dispatch("get", {
          path: this.table + "/getAllByCompany/" + this.company_id,
        });
        if (res.length > 0) {
          this.items = res;
          this.totalRows = this.items.length;
        } else {
          this.items = [];
          this.totalRows = 0;
        }
      } catch (error) {
        this.items = [];
        this.totalRows = 0;
        console.error("error", error);
      }
    },
    async saveData() {
      try {
        this.$isLoading(true);
        console.log("save");
        let data = Object.assign({}, this.row);
        //data.empresa_id = this.company_id;
        let res;
        if (this.row.id) {
          delete data.id;
          res = await this.$store.dispatch("put", {
            path: this.table + "/modify/" + this.row.id,
            data: data,
          });
        } else {
          data.fechacreacion = this.$moment.tz(new Date(), "America/Costa_Rica").format();
          data.estado = "Activo";
          res = await this.$store.dispatch("post", {
            path: this.table + "/new",
            data: data,
          });
        }
        this.$isLoading(false);

        if (res.error) {
          this.$swal({
            title: "Aviso",
            text: "Error: " + res.error,
            icon: "error",
          });
        } else {
          this.getData();
          this.row = {};
          this.$swal({
            title: "Aviso",
            text: "Datos almacenados correctamente",
            icon: "success",
          });
          this.$bvModal.hide("exampleModalCenter");
        }
      } catch (error) {
        this.items = [];
        this.$isLoading(false);
        console.error("error", error);
      }
    },
    async deleteItem(item) {
      const { isConfirmed } = await this.$swal({
        title: "Confirmar",
        text: "¿Desea realmente eliminar el registro?",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Confirmar",
        reverseButtons: true,
      });
      if (isConfirmed) {
        try {
          this.$isLoading(true);
          let res = await this.$store.dispatch("delete", {
            path: this.table + "/delete/" + item.id,
          });
          this.$isLoading(false);

          if (res.error) {
            this.$swal({
              title: "Aviso",
              text: "Error: " + res.error,
              icon: "error",
            });
          } else {
            this.getData();
            this.$swal({
              title: "Aviso",
              text: "Eliminado correctamente",
              icon: "success",
            });
          }
        } catch (error) {
          this.items = [];
          this.$isLoading(false);
          console.error("error--->", error);
        }
      }
    },

    ///////////////
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped></style>
