<template>
  <div class="container-fluid">
    <div class="row p-3">
      <div class="col-12">
        <div class="row">
          <div class="col-md-6 pl-0 ml-0 pr-0 mr-0">
            <b-input-group size="sm" prepend="Codigo cliente" class="desktop">
              <b-form-input></b-form-input>
              <b-form-input v-model="text" class="mx-2" placeholder="Enter your name"></b-form-input>
            </b-input-group>
            <!-- <div class="input-group  pr-2 desktop">
              <div size="sm" class="input-group-prepend">
                <span class="input-group-text " id="basic-addon1">Codigo cliente</span>
              </div>
              <b-form-input size="sm" type="text"  placeholder="Username" aria-label="Username" aria-describedby="basic-addon1"></b-form-input>
              <b-form-input size="sm" v-model="text" class="ml-2" placeholder="Enter your name"></b-form-input>
              
            </div> -->
            <!-- <div class="input-group  pr-2 mt-1 desktop">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Codigo Empresa</span>
              </div>
              <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
              <b-form-input v-model="text" class="ml-2" placeholder="Enter your name"></b-form-input>
              
            </div> -->
            <b-input-group size="sm" prepend="Codigo Empresa" class="mt-1 desktop">
              <b-form-input></b-form-input>
              <b-form-input v-model="text" class="mx-2" placeholder="Enter your name"></b-form-input>
            </b-input-group>
            <!-- desktop fin -->
            <!-- movil -->
            <div class="row movil">
              <div class="col-md-3 mr-0 pr-0">
                <label for="" class="mb-1">Codigo cliente</label>
              </div>
              <div class="col-md-3 ml-0 pl-0 pl-3">
                <b-form-input v-model="text" placeholder="Enter your name"></b-form-input>
              </div>
              <div class="col-md-6 pl-0 ml-0 pl-3 pt-2 pt-md-0">
                <b-form-input class="" v-model="text" placeholder="Enter your name"></b-form-input>
              </div>
            </div>

            <div class="row mt-2 movil">
              <div class="col-md-3 mr-0 pr-0">
                <label for="" class="mb-1">Codigo Empresa</label>
              </div>
              <div class="col-md-3 ml-0 pl-0 pl-3">
                <b-form-input v-model="text" placeholder="Enter your name"></b-form-input>
              </div>
              <div class="col-md-6 pl-0 ml-0 pl-3 pt-2 pt-md-0">
                <b-form-input class="" v-model="text" placeholder="Enter your name"></b-form-input>
              </div>
            </div>
          </div>
          <div class="col-md-6 pl-0 ml-0 mr-0 pr-0">
            <div class="desktop mt-0 pt-0 text-right">
              <b-button size="sm" class="mt-0 border border-light mx-0" variant="primary">Meser@</b-button>
              <b-button size="sm" class="mt-0 border border-light mx-0" variant="warning">comanda por mesa</b-button>
              <b-button size="sm" class="mt-0 border border-light mx-0" variant="danger">No. mesa</b-button>
              <b-button size="sm" class="mt-0 border border-light mx-0" variant="success">Venta expres</b-button>
            </div>
            <div class="movil mt-2">
              <b-button class="border border-light mt-md-1 mx-0" variant="primary" style="width: 25%; height: 25%"><i class="fa-solid fa-utensils"></i></b-button>
              <b-button class="border border-light mt-md-1 mx-0" variant="warning" style="width: 25%; height: 25%"><i class="fa-solid fa-clipboard-list"></i></b-button>
              <b-button class="border border-light mt-md-1 mx-0" variant="danger" style="width: 25%; height: 25%"><i class="fa-solid fa-list-ol"></i></b-button>
              <b-button class="border border-light mt-md-1 mx-0" variant="success" style="width: 25%; height: 25%"><i class="fa-solid fa-cart-shopping"></i></b-button>
            </div>

            <b-input-group size="sm" prepend="No. de comanda" class="mt-1 desktop">
              <b-form-input></b-form-input>
              <b-form-input v-model="text" class="ml-2" placeholder="Enter your name"></b-form-input>
            </b-input-group>
            <!-- <div class="input-group   mt-1 desktop">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">Codigo cliente</span>
              </div>
              <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1">
              <b-form-input class="border border-danger text-danger ml-2" style=" " v-model="text" placeholder="TOTAL"></b-form-input>

              
            </div> -->
            <!-- fin desktop -->
            <!-- movil -->
            <div class="row mt-2 movil">
              <div class="col-md-3 mr-0 pr-3">
                <label for="" class="mt-2 text-left">Codigo cliente</label>
              </div>
              <div class="col-md-5 col-xl-6 pr-3 pr-md-0">
                <b-form-input v-model="text" placeholder="Enter your name"></b-form-input>
              </div>
              <div class="col-md-4 col-xl-6 pr-3 pr-md-0 mt-2 mt-md-0">
                <b-form-input class="border border-danger text-danger" style="" v-model="text" placeholder="TOTAL"></b-form-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-1 px-0 mx-0 movil scroll-container">
        <div class="py-0 px-0 text-center mt-3 scroll-content" v-for="itemCategorias of listCategorias" :key="itemCategorias.id">
          <b-button @click="getProductos(itemCategorias)" class="border-top btn-warning btn" style="width: 25vh !important ;">{{ itemCategorias.nombre }}</b-button>
        </div>
      </div>

      <div class="row mt-2 px-0 mx-0">
        <div class="col-md-5 bg-light px-0 mx-0 px-0 h-55vh" style="width: 40% !important ">
          <b-table sticky-header class="px-0 table-secondary" hover :items="carrito"></b-table>
        </div>
        <div class="col-6 bg-dark px-4 pt-2 h-55vh desktop" style="overflow-y: scroll">
          <div class="row" style="">
            <div class="col-3 px-0 py-0 mx-0" v-for="item in productList" :key="item.id" @click="seleccionaProducto(item)">
              <product-vue class="px-0 mx-0" responsive="true" :product="item" />
            </div>
            <!-- <div class="col-3 px-0 mx-0" v-for="item in product_asia" :key="item.id" @click="seleccionaProducto(item)" >
              <product-vue  responsive="true"   :product="item" class="zoom "  />
            </div> -->
          </div>
        </div>
        <div class="col-12 bg-light pt-2 movil">
          <div class="row" style="overflow-x: scroll !important">
            <div class="col" v-for="item in productList" :key="item.id" @click="seleccionaProducto(item)">
              <product-vue responsive="true" :product="item" class="zoom" />
            </div>
          </div>
        </div>
        <div class="col-1 px-0 mx-0 desktop">
          <div class="h-55vh" style="overflow-y: scroll; width: 120%">
            <div class="py-0 px-0 text-center" v-for="itemCategorias of listCategorias" :key="itemCategorias.id">
              <button @click="getProductos(itemCategorias)" class="btn-circle-middle border-top btn-warning btn-block py-3">{{ itemCategorias.nombre }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <div class="row">
          <div class="col-9 pl-0 ml-0 pr-0 mr-0 text-justify desktop-block">
            <b-button class="border py-0 btn-asia" variant="danger">Salir</b-button>
            <b-button class="border py-0 btn-asia" variant="secondary">Cancelar</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Multi cuentas</b-button>
            <b-button class="border disabled py-0 btn-asia" variant="primary">Grabar comanda</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Selección cuenta</b-button>
            <b-button class="border disabled py-0 btn-asia" variant="primary">Imprimir credito fiscal</b-button>
            <b-button class="border disabled py-0 btn-asia" variant="primary">Imprimir factura</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Imprimir ticket</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Extras y otros sin costos</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Eliminar linea</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Anular comanda</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">imprimir orden de cocina</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">imprimir orden de bebida</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Cortesias</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Descto global%</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Descto con tarjetas</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Cum,pones y promociones</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Buscar FCF, CCF, TIC</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Reportes</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Cambiar mesa</b-button>
            <b-button class="border py-0 btn-asia" variant="primary">Descto especial%</b-button>

            <!-- <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Multi cuentas</b-button>           
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Grabar comanda</b-button>                      
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Selección cuenta</b-button>                    
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Imprimir credito fiscal</b-button>            
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Imprimir factura</b-button>                    
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Imprimir ticket</b-button>                    
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Extras y otros sin costos</b-button>
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Eliminar linea</b-button>                      
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Anular comanda</b-button>                      
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">imprimir orden de cocina</b-button>
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">imprimir orden de bebida</b-button>
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Cortesias</b-button>
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Descto global%</b-button>                      
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Descto con tarjetas</b-button>                
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Cum,pones y promociones</b-button>            
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Cancelar</b-button>                          
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Buscar FCF, CCF, TIC</b-button>                
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Reportes</b-button>
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Cambiar mesa</b-button>                        
            <b-button class="mx-1 my-1 px-3 py-0 btn-asia col"   variant="primary">Descto especial%</b-button>                     -->
          </div>

          <div class="col-md-3 pb-5 mb-5 pb-md-0 mb-md-0 text-right desktop-block">
            <div class="row" v-if="otros == 0">
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">7</b-button>
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">8</b-button>
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">9</b-button>
            </div>
            <div class="row" v-if="otros == 0">
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">4</b-button>
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">5</b-button>
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">6</b-button>
            </div>
            <div class="row" v-if="otros == 0">
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">1</b-button>
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">2</b-button>
              <b-button class="border col-4 btn-asia-calculadora" variant="danger">3</b-button>
            </div>
            <div class="row" v-if="otros == 0">
              <b-button class="border col-4 border btn-asia-calculadora" variant="danger">.</b-button>
              <b-button class="border col-4 border btn-asia-calculadora" variant="danger">0</b-button>
              <b-button class="border col-4 border btn-asia-calculadora" @click="otros = 1" style="font-size: 10.5px !important" variant="secondary">OTROS...</b-button>
            </div>

            <div class="row" v-if="otros == 1">
              <b-button class="border col-4 tn-asia-calculadora" variant="danger">Pagos y anticipos</b-button>
              <b-button class="border col-4 tn-asia-calculadora" variant="danger">Borrar cantidad</b-button>
              <b-button class="border col-4 tn-asia-calculadora" variant="danger">consultar precios</b-button>
              <b-button class="border col-4 tn-asia-calculadora" variant="danger">buscar productos</b-button>
              <b-button class="border col-4 tn-asia-calculadora" variant="danger">Precio especial</b-button>
              <b-button class="border col-4 tn-asia-calculadora" variant="secondary" @click="otros = 0">VOLVER</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ProductVue from "./Product-asia.vue";
export default {
  components: { ProductVue },
  data() {
    return {
      product_asia: [
        {
          id: 1,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "CERVEZA SUPREMA NEGRA",
        },
        {
          id: 2,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 3,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 4,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 5,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 6,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 7,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 8,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 9,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 10,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 11,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 12,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 13,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 14,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 15,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 16,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 17,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 18,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 19,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 20,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 21,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 22,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 23,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 24,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 25,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 26,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 27,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
        {
          id: 28,
          url: "https://walmartgt.vtexassets.com/arquivos/ids/337753/Boquitas-Diana-Conga-Mix-110-Gr-1-14209.jpg",
          precio_venta: 50,
          existencia: 150,
          nombre: "Boquitas Diana",
        },
      ],
      table: [
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
        {
          descripcion: "CERVEZA CORONAML",
          cant: "1",
          punt: "12.05",
          subtot: "12.50",
          $des: "12.50",
          total: "12.50",
          nombrecta: "12.50",
        },
      ],
      listCategorias: [],
      // listCategorias: [
      //   {
      //     id: 1,
      //     nombre: "hola",
      //   },
      //   {
      //     id: 1,
      //     nombre: "hola",
      //   }

      // ],
      company_id: 0,
      category_id: null,
      productList: [],
      productListBk: [],
      carrito: [],
      otros: 0,
    };
  },
  async mounted() {
    this.company_id = 29; // localStorage.getItem("company_id");

    await this.getDataCategorias();
  },
  methods: {
    seleccionaProducto(item) {
      console.log(item);
      let itemData = {
        id: item.id,
        nombre: item.nombre,
        "cant.": 1,
        "p.unit": item.precio_venta,
        subtot: item.precio_venta * 1,
        "des%": 0,
        total: item.precio_venta * 1,
      };
      this.carrito.push(itemData);
    },
    async getDataCategorias() {
      try {
        // this.$isLoading(true);
        let res = await this.$store.dispatch("get", { path: "meca_categorias/getAllByCompany/" + this.company_id });
        // this.$isLoading(false);
        if (res.length > 0) {
          this.listCategorias = res;
          //   this.totalRows = this.items.length;
        } else {
          this.listCategorias = [];
          //   this.totalRows = 0;
        }
      } catch (error) {
        this.listCategorias = [];
        // this.totalRows = 0;
        // this.$isLoading(false);
        console.error("error", error);
      }
    },
    async getProductos(item) {
      this.category_id = item.id;
      try {
        let res = await this.$store.dispatch("get", {
          path: "meca_productos/getAllVisibleByCategoryCompany/" + this.company_id + "/" + this.category_id,
        });
        if (res.length > 0) {
          console.log(this.productList);
          this.productList = res;
          this.productListBk = this.productList;
        } else {
          this.productList = [];
          this.productListBk = this.productList;
        }
      } catch (error) {
        this.productList = [];
        this.productListBk = this.productList;
        console.error("error", error);
      }
    },
  },
};
</script>
<style scoped>
.table td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  background: #f1f1f100;
}

::-webkit-scrollbar-thumb {
  background: #8888884b;
  border-radius: 2px;
}

.scroll-box {
  height: 70vh;
  overflow-y: scroll !important;
}

.scroll-content {
  height: 70vh;
  width: 100% !important;
}
.btn-circle-middle {
  border-bottom-right-radius: 12px !important;
  border-bottom-left-radius: 0px !important;
  border-top-right-radius: 12px !important;
  border-top-left-radius: 0px !important;
  border-top-color: rgb(255, 255, 255) !important ;
  transition: 0.5s !important;
}
.btn-circle-middle:hover {
  margin-left: -15px !important;
  transition: 0.5s !important;
}

.b-table-sticky-header {
  overflow-y: auto;
  max-height: 100%;
}
.btn-asia {
  width: 8.7vw;
  height: 10.5vh;
  font-size: 10.5px;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
}
.btn-asia-calculadora {
  height: 7.9vh;
  font-size: 17px;
}
.movil {
  display: none;
}
.desktop {
  display: flex;
}
.h-55vh {
  height: 55vh;
}
.desktop-block {
  display: block !important;
}
.scroll-container {
  width: 300px; /* Ancho del contenedor */
  white-space: nowrap; /* Evita el salto de línea del contenido */
  overflow-x: scroll; /* Habilita la barra de desplazamiento horizontal */
}
.scroll-content {
  width: 800px; /* Ancho del contenido que excede el del contenedor */
}
@media screen and (max-width: 719px) {
  .movil {
    display: flex;
  }
  .movil-block {
    display: block;
  }
  .h-55vh {
  }
  .desktop {
    display: none !important;
  }
  .desktop-block {
    display: none !important;
  }
}
</style>
