<template>
   <div class="col-12">
        <div class="bd-content">
            <h2 id="how-it-works">How it works</h2>
            <p>Modals are streamlined, but flexible dialog prompts powered by JavaScript and CSS. They support a number of use cases from user notification to completely custom content and feature a handful of helpful sub-components, sizes, variants, accessibility, and more.</p>
            <div class="alert alert-danger" role="alert">
                <strong>Modal</strong> for more bootstrao components <a href="https://bootstrap-vue.org/docs/components/modal" target="_blank">Bootstrap Modal documentation <i class="fa fa-external-link"></i></a>
            </div>
            <ul>
                <li><code>&lt;b-modal&gt;</code>, by default, has an <strong>OK</strong> and <strong>Cancel</strong> buttons in the footer. These buttons can be customized by setting various props on the component. You can customize the size of the buttons, disable buttons, hide the <strong>Cancel</strong> button (i.e. <code>ok-only</code>), choose a variant (e.g. <code>danger</code> for a red OK button) using the <code>ok-variant</code> and <code>cancel-variant</code> props, and provide custom button content using the <code>ok-title</code> and <code>cancel-title</code> props, or using the named slots <code>modal-ok</code> and <code>modal-cancel</code>.</li>
                <li><code>&lt;b-modal&gt;</code> supports close on ESC (enabled by default), close on backdrop click (enabled by default), and the <code>X</code> close button in the header (enabled by default). These features may be disabled by setting the props <code>no-close-on-esc</code>, <code>no-close-on-backdrop</code>, and <code>hide-header-close</code> respectively.</li>
                <li>You can override the modal title via the named slot <code>modal-title</code>, override the header completely via the <code>modal-header</code> slot, and override the footer completely via the <code>modal-footer</code> slot.</li>
                <li><strong>Note</strong>: when using the <code>modal-footer</code> slot, the default <strong>OK</strong> and <strong>Cancel</strong> buttons will not be present. Also, if you use the <code>modal-header</code> slot, the default header <code>X</code> close button will not be present, nor can you use the <code>modal-title</code> slot.</li>
                <li>Modals will not render their content in the document until they are shown (lazily rendered). Modals, when visible, are rendered <strong>appended to the <code>&lt;body&gt;</code> element</strong>. The placement of the <code>&lt;b-modal&gt;</code> component will not affect layout, as it always renders as a placeholder comment node (<code>&lt;!----&gt;</code>). You can revert to the behaviour of older BootstrapVue versions via the use of the <a href="https://bootstrap-vue.org/docs/components/modal" class="font-weight-bold"><code>static</code> prop</a>.</li>
            </ul>
            <div class="border-top mt-5 pt-3">
                <h4 id="modal-components">Modal components</h4>
                <p>Other elements can easily show modals using the <code>v-b-modal</code> directive.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card>
                            <button type="button" class="btn btn-dark" v-b-modal.exampleModalLive>Launch demo modal</button>
                            <!-- Modal -->
                            <b-modal class="" id="exampleModalLive" tabindex="-1" title="Modal title" okTitle='Save changes'>
                                <template v-slot:modal-header-close>
                                    <button type="button" class="btn btn-close"></button>
                                </template>
                                <div class="modal-body">
                                    <p>Woohoo, you're reading this text in a modal!</p>
                                </div>
                            </b-modal>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>&lt;button type=&quot;button&quot; class=&quot;btn btn-dark&quot; v-b-modal.exampleModalLive&gt;Launch demo modal&lt;/button&gt;
&lt;!-- Modal --&gt;
&lt;b-modal class=&quot;&quot; id=&quot;exampleModalLive&quot; tabindex=&quot;-1&quot; title=&quot;Modal title&quot; okTitle=&apos;Save changes&apos;&gt;
&lt;template v-slot:modal-header-close&gt;
    &lt;button type=&quot;button&quot; class=&quot;btn btn-close&quot;&gt;&lt;/button&gt;
&lt;/template&gt;
&lt;div class=&quot;modal-body&quot;&gt;
    &lt;p&gt;Woohoo, you&apos;re reading this text in a modal!&lt;/p&gt;
&lt;/div&gt;
&lt;/b-modal&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="border-top mt-5 pt-3">
                <h5 id="static-backdrop">Static backdrop</h5>
                <p>When backdrop is set to static, the modal will not close when clicking outside it. Click the button below to try it.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card>
                            <button type="button" class="btn btn-dark" v-b-modal.staticBackdropLive>Launch static backdrop modal</button>
                            <b-modal id="staticBackdropLive" tabindex="-1" title="Modal title" okTitle='Understood'>
                                <template v-slot:modal-header-close>
                                    <button type="button" class="btn btn-close"></button>
                                </template>
                                <div class="modal-body">
                                    <p>I will not close if you click outside me. Don't even try to press escape key.</p>
                                </div>
                            </b-modal>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>&lt;button type=&quot;button&quot; class=&quot;btn btn-dark&quot; v-b-modal.staticBackdropLive&gt;Launch static backdrop modal&lt;/button&gt;
&lt;b-modal id=&quot;staticBackdropLive&quot; tabindex=&quot;-1&quot; title=&quot;Modal title&quot; okTitle=&apos;Understood&apos;&gt;
    &lt;template v-slot:modal-header-close&gt;
        &lt;button type=&quot;button&quot; class=&quot;btn btn-close&quot;&gt;&lt;/button&gt;
    &lt;/template&gt;
    &lt;div class=&quot;modal-body&quot;&gt;
        &lt;p&gt;I will not close if you click outside me. Don&apos;t even try to press escape key.&lt;/p&gt;
    &lt;/div&gt;
&lt;/b-modal&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="border-top mt-5 pt-3">
                <h5 id="vertically-centered">Vertically centered</h5>
                <p>Vertically center your modal in the viewport by setting the <code>centered</code> prop.</p>
                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card>
                                <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                                <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button>
                                
                                <b-modal id="exampleModalCenter" tabindex="-1" centered  title="Modal title" okTitle='Save changes'>
                                    <template v-slot:modal-header-close>
                                        <button type="button" class="btn btn-close"></button>
                                    </template>
                                    <div class="modal-body">
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                    </div>
                                </b-modal>

                                <b-modal id="exampleModalCenteredScrollable" tabindex="-1" centered title="Modal title" okTitle='Save changes'>
                                    <template v-slot:modal-header-close>
                                        <button type="button" class="btn btn-close"></button>
                                    </template>
                                    <div class="modal-body">
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                    </div>
                                </b-modal>
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>&lt;button type=&quot;button&quot; class=&quot;btn btn-dark mr-1&quot; v-b-modal.exampleModalCenter&gt;Vertically centered modal&lt;/button&gt;
&lt;button type=&quot;button&quot; class=&quot;btn btn-dark mr-1&quot; v-b-modal.exampleModalCenteredScrollable&gt;Vertically centered scrollable modal&lt;/button&gt;

&lt;b-modal id=&quot;exampleModalCenter&quot; tabindex=&quot;-1&quot; centered  title=&quot;Modal title&quot; okTitle=&apos;Save changes&apos;&gt;
    &lt;template v-slot:modal-header-close&gt;
        &lt;button type=&quot;button&quot; class=&quot;btn btn-close&quot;&gt;&lt;/button&gt;
    &lt;/template&gt;
    &lt;div class=&quot;modal-body&quot;&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
    &lt;/div&gt;
&lt;/b-modal&gt;

&lt;b-modal id=&quot;exampleModalCenteredScrollable&quot; tabindex=&quot;-1&quot; centered title=&quot;Modal title&quot; okTitle=&apos;Save changes&apos;&gt;
    &lt;template v-slot:modal-header-close&gt;
        &lt;button type=&quot;button&quot; class=&quot;btn btn-close&quot;&gt;&lt;/button&gt;
    &lt;/template&gt;
    &lt;div class=&quot;modal-body&quot;&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
        &lt;p&gt;Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.&lt;/p&gt;
        &lt;p&gt;Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.&lt;/p&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
        &lt;p&gt;Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.&lt;/p&gt;
    &lt;/div&gt;
&lt;/b-modal&gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
            <div class="border-top mt-5 pt-3">
                <h2 id="optional-sizes">Optional sizes</h2>
                <p>Modals have three optional sizes, available via modifier classes to be placed on a <code>.modal-dialog</code>. These sizes kick in at certain breakpoints to avoid horizontal scrollbars on narrower viewports.</p>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Size</th>
                            <th>Class</th>
                            <th>Modal max-width</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>sm</td>
                            <td><code>.modal-sm</code></td>
                            <td><code>300px</code></td>
                        </tr>
                        <tr>
                            <td>Default</td>
                            <td class="text-muted">None</td>
                            <td><code>500px</code></td>
                        </tr>
                        <tr>
                            <td>lg</td>
                            <td><code>.modal-lg</code></td>
                            <td><code>800px</code></td>
                        </tr>
                        <tr>
                            <td>xl</td>
                            <td><code>.modal-xl</code></td>
                            <td><code>1140px</code></td>
                        </tr>
                        <tr>
                            <td>Fullscreen</td>
                            <td><code>.modal-fullscreen</code></td>
                            <td><code>Always</code></td>
                        </tr>
                    </tbody>
                </table>

                <b-tabs nav-class="tab-card px-3 ">
                    <b-tab title="Preview" active>
                        <b-card>
                                <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalFullscreen>Full screen</button>
                                <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalXl>Extra large modal</button>
                                <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalLg>Large modal</button>
                                <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalSm>Small modal</button>

                                <b-modal class="modal fade" id="exampleModalFullscreen" tabindex="-1" title="Modal title" dialog-class="modal-fullscreen">
                                    <template v-slot:modal-header-close>
                                        <button type="button" class="btn btn-close"></button>
                                    </template>
                                    <div class="modal-body">
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                        <p>Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.</p>
                                        <p>Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.</p>
                                        <p>Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.</p>
                                    </div>
                                </b-modal>

                                <b-modal id="exampleModalXl" tabindex="-1" title="Extra large modal" size="xl" :hide-footer="true">
                                    <template v-slot:modal-header-close>
                                        <button type="button" class="btn btn-close"></button>
                                    </template>
                                    <div class="modal-body">
                                    ...
                                    </div>
                                </b-modal>

                                <b-modal  id="exampleModalLg" tabindex="-1" title="Large modal" size="lg" :hide-footer="true">
                                     <template v-slot:modal-header-close>
                                        <button type="button" class="btn btn-close"></button>
                                    </template>
                                    <div class="modal-body">
                                        ...
                                    </div>
                                </b-modal >

                                <b-modal id="exampleModalSm" tabindex="-1" size="sm" title="Small modal" :hide-footer="true">
                                    <div class="modal-body">
                                        ...
                                    </div>
                                </b-modal >
                        </b-card>
                    </b-tab>
                    <b-tab title="HTML">
                        <b-card>
<pre class="language-html" data-lang="html">
<code>&lt;button type=&quot;button&quot; class=&quot;btn btn-dark mr-1&quot; v-b-modal.exampleModalFullscreen&gt;Full screen&lt;/button&gt;
&lt;button type=&quot;button&quot; class=&quot;btn btn-dark mr-1&quot; v-b-modal.exampleModalXl&gt;Extra large modal&lt;/button&gt;
&lt;button type=&quot;button&quot; class=&quot;btn btn-dark mr-1&quot; v-b-modal.exampleModalLg&gt;Large modal&lt;/button&gt;
&lt;button type=&quot;button&quot; class=&quot;btn btn-dark mr-1&quot; v-b-modal.exampleModalSm&gt;Small modal&lt;/button&gt;

&lt;b-modal class=&quot;modal fade&quot; id=&quot;exampleModalFullscreen&quot; tabindex=&quot;-1&quot; title=&quot;Modal title&quot; dialog-class=&quot;modal-fullscreen&quot;&gt;
    &lt;template v-slot:modal-header-close&gt;
        &lt;button type=&quot;button&quot; class=&quot;btn btn-close&quot;&gt;&lt;/button&gt;
    &lt;/template&gt;
    &lt;div class=&quot;modal-body&quot;&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
        &lt;p&gt;Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.&lt;/p&gt;
        &lt;p&gt;Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.&lt;/p&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
        &lt;p&gt;Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.&lt;/p&gt;
        &lt;p&gt;Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.&lt;/p&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
        &lt;p&gt;Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.&lt;/p&gt;
        &lt;p&gt;Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.&lt;/p&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
        &lt;p&gt;Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.&lt;/p&gt;
        &lt;p&gt;Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.&lt;/p&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
        &lt;p&gt;Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.&lt;/p&gt;
        &lt;p&gt;Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.&lt;/p&gt;
        &lt;p&gt;Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.&lt;/p&gt;
        &lt;p&gt;Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor.&lt;/p&gt;
        &lt;p&gt;Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus auctor fringilla.&lt;/p&gt;
    &lt;/div&gt;
&lt;/b-modal&gt;

&lt;b-modal id=&quot;exampleModalXl&quot; tabindex=&quot;-1&quot; title=&quot;Extra large modal&quot; size=&quot;xl&quot; :hide-footer=&quot;true&quot;&gt;
    &lt;template v-slot:modal-header-close&gt;
        &lt;button type=&quot;button&quot; class=&quot;btn btn-close&quot;&gt;&lt;/button&gt;
    &lt;/template&gt;
    &lt;div class=&quot;modal-body&quot;&gt;
    ...
    &lt;/div&gt;
&lt;/b-modal&gt;

&lt;b-modal  id=&quot;exampleModalLg&quot; tabindex=&quot;-1&quot; title=&quot;Large modal&quot; size=&quot;lg&quot; :hide-footer=&quot;true&quot;&gt;
        &lt;template v-slot:modal-header-close&gt;
        &lt;button type=&quot;button&quot; class=&quot;btn btn-close&quot;&gt;&lt;/button&gt;
    &lt;/template&gt;
    &lt;div class=&quot;modal-body&quot;&gt;
        ...
    &lt;/div&gt;
&lt;/b-modal &gt;

&lt;b-modal id=&quot;exampleModalSm&quot; tabindex=&quot;-1&quot; size=&quot;sm&quot; title=&quot;Small modal&quot; :hide-footer=&quot;true&quot;&gt;
    &lt;div class=&quot;modal-body&quot;&gt;
        ...
    &lt;/div&gt;
&lt;/b-modal &gt;</code>
</pre>                 
                        </b-card>
                    </b-tab>
                </b-tabs>
            </div>
        </div>
   </div>
</template>
<script>
import Prism from 'prismjs'
import 'prismjs/themes/prism.css'
export default {
    name:'ModalComponent',
    data() {
        return {
        
        }
    },methods:{
        
    },mounted() {
        Prism.highlightAll()
    }
}
</script>
<style lang="scss">

</style>