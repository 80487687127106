<template>
  <div class="row clearfix">
    <div class="col-md-12">
      <div class="card">
        <div class="row py-3 justify-content-between">
          <div class="card-header py-3 d-flex flex-wrap justify-content-between align-items-center">
            <div class="d-flex">
              <span class="mt-2 ml-2 mr-1">Desde:</span><input type="Date" class="form-control" v-model="fechainicio" placeholder="" /> <span class="mt-2 ml-2 mr-1">Hasta:</span
              ><input type="Date" class="form-control" v-model="fechafin" placeholder="" />
              <input @click="getData()" type="button" class="btn btn-primary ml-2 mr-1" value="Filtrar" />
            </div>
            <div class="d-flex">
              <span class="mt-2 ml-2 mr-1"><b>Total ventas:</b></span
              ><label class="form-control" style="width: 100px"
                ><b>{{ config.moneda + " " + total.toFixed(2) }}</b></label
              >
            </div>
            <card-actions></card-actions>
          </div>

          <div class="col-md-5">
            <b-form-group label="por página" label-for="per-page-select" label-cols-sm="6" label-cols-md="4" label-cols-lg="3" label-align-sm="start" label-size="sm" class="mb-0">
              <b-form-select id="per-page-select" v-model="perPage" :options="pageOptions" size="sm"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-5">
            <b-form-group label="Buscar" label-for="filter-input" label-cols-sm="3" label-align-sm="right" label-size="sm" class="mb-0">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Texto a buscar..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </div>
        </div>
        <b-table
          hover
          responsive
          :items="items"
          :fields="fields"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          @filtered="onFiltered"
        >
          <template #cell(name)="row"> {{ row.value.first }} {{ row.value.last }} </template>
          <template #cell(fecha)="data">
            <span>{{ data.item.fecha != null ? $moment(data.item.fecha).format("DD-MM-YYYY") : "" }}</span>
          </template>
          <template #cell(precio_venta)="data">
            <span>{{ config.moneda + " " + data.item.precio_venta.toFixed(2) }}</span>
          </template>
          <template #cell(ventas)="data">
            <span>{{ config.moneda + " " + (data.item.precio_venta * data.item.cantidad).toFixed(2) }}</span>
          </template>
          <template #cell(totalventas)="data">
            <span>{{ config.moneda + " " + data.item.totalventas.toFixed(2) }}</span>
          </template>
          <!-- <template #cell(action)="data"> -->
          <!-- <span
              ><button @click="showModal(data.item, 2)" class="btn btn-primary ml-1">
                <i class="fa fa-pencil"></i>
              </button>
            </span>
            <span
              ><button @click="deleteItem(data.item)" class="btn btn-danger ml-1">
                <i class="fa fa-trash-o"></i>
              </button>
            </span> -->

          <!-- <span><button class="btn btn-success ml-1 text-white"><i class="fa fa-pencil"></i> </button> </span> -->
          <!-- <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenter>Vertically centered modal</button>
                            <button type="button" class="btn btn-dark mr-1" v-b-modal.exampleModalCenteredScrollable>Vertically centered scrollable modal</button> -->

          <!-- <table-action></table-action> -->
          <!-- </template> -->
          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            hide-goto-end-buttons
            hide-ellipsis
            prev-text="Prev"
            next-text="Next"
            align="right"
          ></b-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CardActions from "@/components/core/CardActions.vue";
// import moment from "moment";

export default {
  name: "TableComponent",
  components: {
    // 'table-action': TableAction,
    "card-actions": CardActions,
  },
  props: ["tipo", "fechainicio", "fechafin", "empresa_id", "origen"],
  data() {
    return {
      options: [{ estado: "Nuevo" }, { estado: "Contactado" }, { estado: "En prueba" }, { estado: "Contratado" }, { estado: "Rechazado" }, { estado: "Despedido" }],
      fields: [
        // { key: "fecha", label: "fecha", sortable: true, sortDirection: "desc" },
        {
          key: "nombre",
          label: "Nombre",
          sortable: true,
          class: "text-center",
        },
        { key: "cantidad", label: "Cantidad", sortable: true, class: "text-center" },
        { key: "precio_venta", label: "Precio unit", sortable: true, class: "text-center" },
        { key: "ventas", label: "Total venta", sortable: true, class: "text-center" },

        // { key: "action", label: "Acciones", tdClass: "dt-body-right" },

        {
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, { value: 100, text: 100 }],
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      ///////
      table: "meca_reportes",
      items: [],
      row: {},
      userId: 0,
      rol: 0,
      modalTitle: "Nuevo registro",
      listaEstado: [{ estado: "Activo" }, { estado: "Inactivo" }],
      municipios: [
        { nombre: "Managua" },
        { nombre: "Altagracia" },
        { nombre: "Belén" },
        { nombre: "Buenos Aires" },
        { nombre: "Cárdenas" },
        { nombre: "Moyogalpa" },
        { nombre: "Potosí" },
        { nombre: "Rivas" },
        { nombre: "San Jorge" },
        { nombre: "San Juan del Sur" },
        { nombre: "Tola" },
      ],
      singleTable: "Registro",
      textTable: "Trabajo",
      company: {},
      //datos del reporte

      // tipo: 0,
      config: {},
      total: 0,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  async mounted() {
    try {
      this.userId = localStorage.getItem("user_id");
      this.company_id = localStorage.getItem("company_id");
      this.rol = localStorage.getItem("rol");
      this.empresa_id = this.empresa_id == undefined ? this.$route.params.empresa_id : this.empresa_id;

      this.tipo = this.tipo == undefined ? this.$route.params.tipo : this.tipo;
      this.config = JSON.parse(localStorage.getItem("config"));
      this.fechainicio = this.fechainicio == undefined ? this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD") : this.fechainicio;
      this.fechafin = this.fechafin == undefined ? this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD") : this.fechafin;
      this.getData();
    } catch (error) {
      console.log("error", error);
    }
  },
  methods: {
    async getData() {
      try {
        if (this.tipo == 0) {
          this.fields = [
            // { key: "fecha", label: "fecha", sortable: true, sortDirection: "desc" },
            {
              key: "nombre",
              label: "Nombre",
              sortable: true,
              class: "text-center",
            },
            { key: "cantidad", label: "Cantidad", sortable: true, class: "text-center" },
            { key: "precio_venta", label: "Precio unit", sortable: true, class: "text-center" },
            { key: "ventas", label: "Total venta", sortable: true, class: "text-center" },
            {
              sortable: true,
              sortByFormatted: true,
              filterByFormatted: true,
            },
          ];
        }
        if (this.tipo == 1) {
          this.fields = [
            {
              key: "nombre",
              label: "Nombre",
              sortable: true,
              class: "text-center",
            },
            { key: "total", label: "Total", sortable: true, class: "text-center" },
            { key: "totalventas", label: "Ventas", sortable: true, class: "text-center" },

            // { key: "action", label: "Acciones", tdClass: "dt-body-right" },

            {
              sortable: true,
              sortByFormatted: true,
              filterByFormatted: true,
            },
          ];
        }
        let res = await this.$store.dispatch("get", {
          path: this.table + "/getReports/" + this.empresa_id + "/" + this.tipo + "/0/" + this.fechainicio + "/" + this.fechafin,
        });
        if (res.length > 0) {
          this.items = res;
          this.totalRows = this.items.length;
          // this.total = this.items.map(item => ( item.tipo == 'I' ? item.total :0 )).reduce((p,c) => p + c, 0)
          if (this.tipo == 0) {
            this.total = this.items.reduce((a, b) => {
              return a + Number(b.precio_venta * b.cantidad);
            }, 0);
          } else {
            this.total = this.items.reduce((a, b) => {
              return a + Number(b.totalventas);
            }, 0);
          }
        } else {
          this.items = [];
          this.totalRows = 0;
        }
      } catch (error) {
        this.items = [];
        this.totalRows = 0;
        console.error("error", error);
      }
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
<style scoped></style>
