<template>
  <div class="container">
    <div class=" p-5">
      <b-card-group class="">
        <b-card style="border-radius:10px ;" class="mx-3 m-0 p-0 img-card-media  "  img-alt="Image" >
         <div class=" p-0">
            <div class="text-center img-bg text-light -media-v media">
              <!-- <img class=" d-flex justify-content-center" style="height: 80% !important" src="/images/media.png" alt=""> -->
              <div class="px-3 pb-2" style="margin-top: 110px; z-index: 999px; display: flex;">
                <button  class="btn btn-success " @click="activeVid=true">
                  <i v-if="activeVid==false" class="fa-solid fa-play mr-1"></i> 
                  <i v-if="activeVid==true" class="fa-solid fa-circle-notch loader"></i>
                  <!-- <i   v-if="active==true" class="fas fa-spìnner mr-1 loader"></i>  -->
                  <span v-if="activeVid==false">
                    Grabar
                  </span>
                    
                </button>
                <div class="">
                  <button v-if="activeVid==true" class="btn btn-danger mx-2"  @click="activeVid=false">
                    <i class="fa-solid fa-stop"></i>
                  </button>
                </div>
              </div>
              <!-- <i class=" bg-icon-video icon-img icon-video fa-solid fa-video"></i> -->
            </div>
         </div>
          
          <b-card-text class="  px-3" style="display: block;">
            <h4><b class=""> Grabación de pantalla: </b><br><span class="">Captura y comparte en segundos</span> </h4>
            La grabación de pantalla te permite capturar lo que sucede en tu computadora con facilidad. Perfecta para tutoriales y demostraciones, esta opción es rápida, fácil de usar y te permite compartir tu contenido en segundos.
           <!-- <div class="alert-custom alert-custom-primary">
              <span>ddffdfsd</span>
           </div> -->
          </b-card-text>
          
        </b-card>

        <b-card style="border-radius:10px ;" class="mx-3 m-0 p-0 img-card-media  "  img-alt="Image" >
         <div class=" p-0">
            <div class="text-center img-bg text-light -media-v audio">
              <!-- <img class=" d-flex justify-content-center"  src="/images/podcast.png" alt=""> -->
              <div class="px-3 pb-2" style="margin-top: 110px; z-index: 999px; display: flex;">
                <button  class="btn btn-warning " @click="activeAud=true">
                  <i v-if="activeAud==false" class="fa-solid fa-microphone mr-1"></i> 
                  <i v-if="activeAud==true" class="fa-solid fa-circle-notch loader"></i>
                  <!-- <i   v-if="active==true" class="fas fa-spìnner mr-1 loader"></i>  -->
                  <span v-if="activeAud==false">
                    Grabar
                  </span>
                    
                </button>
                <div class="">
                  <button v-if="activeAud==true" class="btn btn-danger mx-2"  @click="activeAud=false">
                    <i class="fa-solid fa-stop"></i>
                  </button>
                </div>
              </div>
              <!-- <i class=" bg-icon-video icon-img icon-video fa-solid fa-video"></i> -->
            </div>
         </div>
          
          <b-card-text class=" p-3" style="display: block;">
            <h4><b class=""> Grabación de audio: </b><br><span class="">Captura y comparte tus sonidos</span> </h4>
            La opción de grabación de audio te permite capturar sonidos directamente desde tu computadora. Perfecta para grabar notas de voz, esta herramienta es rápida, fácil de usar y te permite compartir tus grabaciones en segundos.
          </b-card-text>
          
        </b-card>
      </b-card-group>
      
    </div>
    <!-- <div v-for="item of videoList" :key="item.id" class="mt-4">
      <a :href="item.url">{{ item.nombrearchivo }}</a>
    </div> -->
    <button class="mt-5" @click="startRecording" :disabled="recording">Comenzar grabación</button><br />
      <button @click="stopRecording" :disabled="!recording">Detener grabación</button>
  </div>
</template>

<script>
export default {
  data() {
    return {
    activeVid: false,
    activeAud: false,
      recording: false,
      mediaRecorder: null,
      chunks: [],
      userId: null,
      company_id: null,
      fileName: "",
      videoList: [],
    };
  },
  async mounted() {
    this.userId = localStorage.getItem("user_id");
    this.company_id = localStorage.getItem("company_id");
    this.getVideos();
  },
  methods: {
    async getVideos() {
      try {
        let res = await this.$store.dispatch("get", {
          path: "meca_media/getAllByCompany/" + this.company_id,
        });
        this.$isLoading(false);
        if (res.length > 0) {
          this.videoList = res;
        } else {
          this.videoList = [];
        }
      } catch (error) {
        this.videoList = [];
        this.$isLoading(false);
      }
    },
    async newVideo() {
      let data = {
        usuario_id: this.userId,
        empresa_id: this.company_id,
        url: this.$store.state.urlMedia + "videos/" + this.userId + "/" + this.fileName,
        comentarios: "",
        fecha: this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD"),
        nombreArchivo: this.fileName,
      };
      await this.$store.dispatch("post", {
        path: "meca_media/new",
        data: data,
      });
      this.$isLoading(false);
    },
    startRecording() {
      navigator.mediaDevices
        .getDisplayMedia({ video: true })
        .then((videoStream) => {
          navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then((audioStream) => {
              const audioTracks = audioStream.getAudioTracks();
              videoStream.addTrack(audioTracks[0]);

              this.mediaRecorder = new MediaRecorder(videoStream);
              this.mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                  this.chunks.push(event.data);
                }
              };
              this.mediaRecorder.onstop = () => {
                // const blob = new Blob(this.chunks, { type: "video/webm" });
                // this.uploadVideo(blob);

                // const blob = new Blob(this.chunks, { type: "video/webm" });
                const blob = new Blob(this.chunks, { type: "video/mp4" });
                const url = URL.createObjectURL(blob);

                const a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = url;
                a.download = "video.mp4";
                a.click();
                window.URL.revokeObjectURL(url);
              };
              this.mediaRecorder.start();
              this.recording = true;
            })
            .catch((error) => {
              console.error("Error al acceder al dispositivo de audio:", error);
            });
        })
        .catch((error) => {
          console.error("Error al acceder al dispositivo de video:", error);
        });
    },
    stopRecording() {
      if (this.mediaRecorder && this.recording) {
        this.mediaRecorder.stop();
        this.recording = false;
      }
    },
    uploadVideo(blob) {
      console.log(blob);
      const formData = new FormData();
      this.fileName = Math.random() + "_screen_record.webm";
      formData.append("video", blob, this.fileName);

      fetch(this.$store.state.url + "uploadVideo/" + this.userId, {
        method: "POST",
        body: formData,
      })
        .then(async (response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          console.log("Archivo enviado exitosamente.");
          //comentado temporalmente
          // await this.newVideo();
        })
        .catch((error) => {
          console.error("Error al enviar el archivo:", error);
        });
    },
  },
};
</script>
<style scoped lang="scss" >
.card-body{
  padding: 0%;
}

.bg-generico{
  background-image: url(/images/fondo-generico-02.svg);
  // background-image: url("/w3images/photographer.jpg");
  // background-color: #cccccc;
  // height: 80% !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
  justify-content: center;

  
}
.bg-generico img{
  width: 200px !important;
  text-align: center !important;
  padding: 20px;
  margin: auto;

}
.card-img-top{
  height: 200px !important;
  object-fit: cover;
  border-top-left-radius:10px !important; 
  border-top-right-radius:10px !important; 
}
.img-flotante{
  // font-size: 70px;
  width: 50%;
  position:absolute;
  margin-top: -180px;
  z-index: 0; 
  text-align: center;
  // margin-left: 50px;
  transform:rotate(-10deg);
  color:var(--primary-dark-color);
  align-items: center !important;
  // display: flex;
  // justify-content: center;
}
// .icon-img-2{
//   font-size: 25px;
//   position:absolute;
//   margin-top: -190px;
//   z-index: 1; 
//   margin-left: 50px;
//   transform:rotate(-20deg);
  
  
// }
// .icon-img-3{
//   font-size: 25px;
//   position:absolute;
//   margin-top: -100px;
//   z-index: 1; 
//   margin-left: 20px;
//   transform:rotate(20deg);

// }

// .icon-img-5{
//   font-size: 18px;
//   position:absolute;
//   margin-top: -150px;
//   z-index: 1; 
//   margin-left: 10px;
//   transform:rotate(-20deg);
  
  
// }





// .icon-img-2-n{
//   font-size: 25px;
//   position:absolute;
//   margin-top: -130px;
//   z-index: 1; 
//   margin-left: 200px;
//   transform:rotate(20deg);
  
  
// }
// .icon-img-3-n{
//   font-size: 25px;
//   position:absolute;
//   margin-top: -60px;
//   z-index: 1; 
//   margin-left:170px;
//   transform:rotate(-20deg);
  

// }

// .icon-img-5-n{
//   font-size: 18px;
//   position:absolute;
//   margin-top: -80px;
//   z-index: 1; 
//   margin-left: 220px;
//   transform:rotate(20deg);
  
  
// }


















.bg-icon-video{
  background-color: var(--primary-opacity-color-rgba);
  
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  padding: 20px;
  z-index: 0;
  border: solid 1px var(--primary-color);
  box-shadow: -1px -1px 41px 14px var(--primary-opacity-color-rgba);
  -webkit-box-shadow: -1px -1px 41px 14px var(--primary-opacity-color-rgba);
  -moz-box-shadow: -1px -1px 41px 14px var(--primary-opacity-color-rgba);}


.loader{
  -webkit-animation: spin 0.7s linear infinite; /* Safari */
}
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}
</style>