var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"body-header d-flex",class:{
    'py-lg-3 py-2': _vm.currentLayout == 'layout-f' || _vm.currentLayout == 'layout-h',
    'text-light': _vm.currentLayout == 'layout-f',
    '': _vm.currentLayout != 'layout-f' && _vm.currentLayout != 'layout-h',
    'bg-primary py-1 py-lg-2 px-2 px-md-3 px-lg-4': _vm.currentLayout == 'layout-b',
    'px-lg-5 px-md-4': _vm.currentLayout == 'layout-h',
  }},[_c('div',{class:_vm.currentLayoutStyle == 'style-6' ||
      _vm.currentLayoutStyle == 'style-8' ||
      _vm.currentLayoutStyle == 'style-10' ||
      _vm.currentLayoutStyle == 'style-11' ||
      _vm.currentLayoutStyle == 'style-12'
        ? 'container'
        : 'container-fluid'},[_c('div',{staticClass:"row",class:{ 'align-items-center': _vm.currentLayout == 'layout-b' || _vm.currentLayout == 'layout-a' }},[(!['chat'].includes(_vm.$route.name))?_c('bread-crumb'):_vm._e(),_c('div',{staticClass:"col-12",class:{ 'd-none': _vm.currentLayout != 'layout-b' }},[_c('b-navbar',{staticClass:"py-md-1 py-0",attrs:{"toggleable":"md"}},[_c('b-navbar-toggle',{staticClass:"navbar-toggler",attrs:{"target":"navbarSupportedContent"}},[_c('span',{staticClass:"fa fa-bars"})]),_c('b-collapse',{attrs:{"id":"navbarSupportedContent","is-nav":""}},[_c('b-navbar-nav',{staticClass:"me-auto"},[_c('b-nav-item',{attrs:{"href":"Javascript:void(0);","link-classes":"text-light pl-0"}},[_vm._v("Apps")]),_c('b-nav-item',{attrs:{"href":"Javascript:void(0);","link-classes":"text-light"}},[_vm._v("Features")]),_c('b-nav-item-dropdown',{attrs:{"text":"More pages","variant":"transparent","toggle-class":"text-light","menu-class":"shadow border-0 dropdown-animation"}},[_c('b-dropdown-item',[_vm._v("Profile")]),_c('b-dropdown-item',[_vm._v("Timeline")]),_c('b-dropdown-item',[_vm._v("Image Gallery")]),_c('b-dropdown-item',[_vm._v("Invoices")]),_c('b-dropdown-item',[_vm._v("Pricing")]),_c('b-dropdown-item',[_vm._v("Image Gallery")]),_c('b-dropdown-item',[_vm._v("Teams Board")]),_c('b-dropdown-divider'),_c('b-dropdown-item',[_vm._v("Something else here")])],1),_c('b-nav-item',{attrs:{"href":"Javascript:void(0);","link-classes":"text-light"}},[_vm._v("Setting")])],1),_c('b-form',{staticClass:"d-flex"},[_c('b-form-input',{staticClass:"mr-2",attrs:{"placeholder":"Search"}}),_c('b-button',{attrs:{"variant":"white","type":"submit"}},[_vm._v("Search")])],1)],1)],1)],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }