<template>
  <div class="col-12">
    <b-modal title="Selecciona color" id="modal-color" size="sm">
      <div class="row">
        <color-panel style="width: 100%" v-model="myEventColor"></color-panel>
      </div>
      <template #modal-footer>
        <div class="w-100">
          <b-button variant="secondary" size="sm" class="float-right" @click="$bvModal.hide('modal-color')"> Cerrar </b-button>
        </div>
      </template>
    </b-modal>

    <div class="card">
      <div class="card-body" id="my_calendar">
        {{ $moment(new Date()).format("DD-MM-YYYY") }}
        <form @submit.prevent="saveData()">
          <div v-for="it of lTareas" :key="it.id" class="card mb-3 p-4">
            <!-- {{ it }} -->
            <h4 class="text-white">Tablero: {{ it.NombreTablero }}</h4>
            <p>
              {{ $moment(it.fecha).format("DD-MM-YYYY") + " | " + $moment(it.fecha_fin).format("DD-MM-YYYY") }}
              <span class="text-white ml-3">{{ "Estado: " + it.nombre }}</span>
            </p>
            <h5>{{ it.titulo }}</h5>

            <label class="text-red">{{ comparaFechas(it.fecha_fin) }}</label>
            <template v-if="it.checklist.length > 0">
              <div v-for="element of it.checklist" :key="element.id" class="row p-1 m-1" style="border: 1px solid gray">
                <!-- {{ "CheckList: " + element.titulo }} -->
                <div class="col-md-10">
                  <!-- <span class="text-white">{{ indexf + 1 }}.</span> -->
                  <label style="text-wrap: wrap !important" class="" type="text" name="nombre" id="nombre" cols="50" rows="1">{{ element.titulo }}</label>
                </div>
                <div class="col-md-2">
                  <button class="btn btn-primary" @click.prevent="saveItemChecklist(element)">Finalizar</button>
                  <!-- <b-form-checkbox
                    @input="saveItemChecklist(element)"
                    size="lg"
                    :id="'checkbox-1' + indexf + '-' + i"
                    v-model="element.terminado"
                    :name="'checkbox-1' + indexf + '-' + i"
                    value="1"
                    unchecked-value="0"
                  >
                    <span for="terminado" class="h6">Terminada</span>
                  </b-form-checkbox> -->
                </div>
              </div>
            </template>
            <template v-else>
              <label for="">No tiene checklist</label>
            </template>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import esLocale from "@fullcalendar/core/locales/es";

import { ColorPicker, ColorPanel } from "one-colorpicker";
import Vue from "vue";

Vue.use(ColorPanel);
Vue.use(ColorPicker);

import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import {
  INITIAL_EVENTS,
  // , createEventId
} from "@/js/event-utils.js";
import moment from "moment";

export default {
  name: "CalendarComponent",

  data() {
    // let allDayEvent = moment(new Date()).format("YYYY-MM");
    return {
      calendarOptions: {
        contentHeight: "auto",
        // aspectRatio: "1",
        headerToolbar: { left: "dayGridMonth,dayGridWeek,timeGridDay", center: "title", right: "prev,today,next" },

        locale: esLocale,
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
        ],
        events: [
          //   {
          //     title: "Long Event",
          //     date: allDayEvent + "-22",
          //   },
          //   {
          //     title: "Long Event",
          //     start: allDayEvent + "-07",
          //     end: allDayEvent + "-10",
          //   },
          //   {
          //     title: "Conference",
          //     start: allDayEvent + "-17",
          //     end: allDayEvent + "-19",
          //   },
        ],
        initialView: "dayGridMonth",
        // initialView: "dayGridWeek",
        initialEvents: INITIAL_EVENTS, // alternatively, use the `events` setting to fetch from a feed
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: false,
        weekends: true,
        // select: this.handleDateSelect,
        eventClick: this.clickEvent,
        dateClick: this.addEvent,
        // eventsSet: this.handleEvents,
      },
      currentEvents: [],
      showDate: new Date(),
      myItems: [],
      lTareas: [],
      lAgenda: [],
      isShow: false,
      title: "",
      titulo: "",
      descripcion: "",
      fecha_inicio: "",
      fecha_fin: "",
      tipo: 0,
      botonModal: "",
      evento_id: 0,
      cKey: 0,
      user: {},
      usuario_id: 0,
      empresa_id: 0,
      tituloModal: "",
      estado: "Activa",
      estados: [{ value: "Activa" }, { value: "Finalizada" }, { value: "Atención" }],
      id: 0,
      lProyectos: [],
      myEventColor: "",
      cambioColor: false,
      tipoEvento: "",
      recurrente: 0,
      frecuencia: "",
      frecuencias: ["Día", "Semana", "Mes", "Año"],
    };
  },
  async mounted() {
    this.usuario_id = localStorage.getItem("user_id");
    this.user = JSON.parse(localStorage.getItem("user"));
    this.empresa_id = this.user.empresa_id;
    console.log("empresa id ", this.empresa_id);
    await this.updateCalendar();
  },
  methods: {
    async saveItemChecklist(data) {
      let indexMain = this.lTareas.findIndex((x) => x.checklist.includes(data));
      let indexItem = this.lTareas[indexMain].checklist.findIndex((x) => x == data);
      this.lTareas[indexMain].checklist.splice(indexItem, 1);
      delete data.idtmp;
      let id = data.id;

      console.log(data);
      delete data.id;
      data.terminado = 1;
      data.fecha_terminada = this.$moment.tz(new Date(), "America/Costa_Rica").format("YYYY-MM-DD HH:mm:ss");
      await this.$store.dispatch("put", { path: "meca_kanban_checklist/modify/" + id, data: data });

      this.$toast.success("Finalizada correctamente!", {
        timeout: 1000,
      });

      //this.updateCalendar();

      //this.$forceUpdate();
      // await this.getChecklist(this.row.id);
    },
    saveItemTmeout(i) {
      console.log(i);
    },
    comparaFechas(fechaf) {
      console.log(fechaf);
      if (fechaf != null) {
        let hoy = this.$moment(new Date());
        fechaf = this.$moment(new Date(fechaf));
        let esigual = hoy.isSame(fechaf, "day");
        if (esigual) {
          return "Vence hoy!";
        }
        let compara = hoy.isAfter(fechaf, "day");
        if (compara) {
          console.log(hoy);
          console.log(fechaf);
          console.log(compara);
          return "Vencida!";
        }
      } else {
        return "";
      }
    },
    addEvent(event) {
      this.tituloModal = "Agregar evento";
      console.log("aqui", event.dateStr);
      this.botonModal = "Guardar";
      this.myEventColor = "#a3d6f7";
      this.cambioColor = false;

      this.tipo = 2;
      this.id = 0;
      //let data = new Date(data1.event._instant.start);

      this.fecha_inicio = moment(event.dateStr).format("YYYY-MM-DD");
      this.fecha_fin = moment(event.dateStr).format("YYYY-MM-DD");
      this.isShow = true;
      this.$bvModal.show("bv-modal");
      this.titulo = "";
      this.descripcion = "";
      this.estado = "Activa";
    },
    addDays(date, days) {
      var result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    },

    async updateCalendar() {
      this.$isLoading(true);
      this.lProyectos = [];
      this.lTareas = [];
      this.lAgenda = [];
      this.calendarOptions.events = [];
      this.myItems.length = 0;
      // await this.obtenerProyectos();
      console.log("empresa: ", this.empresa_id);
      console.log("tareas -->", this.lTareas);
      for (let index = 0; index < this.lProyectos.length; index++) {
        const element = this.lProyectos[index];
        let temp = {
          start: moment(element.fecha_inicio).utc(true).format("YYYY-MM-DD"),
          end: this.$moment.tz(new Date(this.addDays(element.fecha_fin, 1)), "America/Costa_Rica").format("YYYY-MM-DD"),
          title: "P: " + element.titulo + " " + (element.porterminada ? element.porterminada + "%" : ""),
          descripcion: element.descripcion,
          id: element.id,
          color: element.color,
          myEventColor: element.color,
        };
        console.log(temp);
        this.calendarOptions.events.push(temp);
      }
      await this.obtenerItemsTableros();
      // console.log("empresa: ", this.empresa_id);
      // console.log("tareas -->", this.lTareas);

      for (let index = 0; index < this.lTareas.length; index++) {
        const element = this.lTareas[index];
        let temp = {
          start: moment(element.fecha_inicio).utc(true).format("YYYY-MM-DD"),
          end: this.$moment.tz(new Date(this.addDays(element.fecha_fin, 1)), "America/Costa_Rica").format("YYYY-MM-DD"),
          title: "T: " + element.titulo + " " + element.porterminada + "%",
          descripcion: element.descripcion,
          id: element.id,
          color: element.color,
          myEventColor: element.color,
        };
        console.log(temp);
        this.calendarOptions.events.push(temp);
      }

      // await this.obtenerAgenda();
      // for (let index = 0; index < this.lAgenda.length; index++) {
      //   const element = this.lAgenda[index];
      //   let temp = {
      //     start: moment(element.fecha_inicio).utc(true).format("YYYY-MM-DD"),
      //     end: this.$moment.tz(new Date(this.addDays(element.fecha_fin, 1)), "America/Costa_Rica").format("YYYY-MM-DD"),
      //     title: "A: " + element.titulo,
      //     descripcion: element.descripcion,
      //     id: element.id,
      //     color: element.color,
      //     estado: element.estado,
      //     myEventColor: element.color,
      //     recurrente: element.recurrente,
      //     frecuencia: element.frecuencia,
      //   };
      //   console.log(temp);
      //   this.calendarOptions.events.push(temp);
      // }
      //////////////////////////// agregando recordatorios
      // await this.agregarRecordatorios();

      this.$isLoading(false);
    },
    async agregarRecordatorios() {
      let recordatorios = this.lAgenda.filter((x) => x.recurrente == 1);
      console.log("lista recordatorios", recordatorios);
      let listarecordatorios = [];
      for (let indexa = 0; indexa < recordatorios.length; indexa++) {
        // si recordatorio es diario:
        const elemento = recordatorios[indexa];
        let limite = 365 * 10;
        let diasFrecuencia = 1;
        //alert(JSON.stringify(elemento));
        if (elemento.frecuencia == "Día") {
          diasFrecuencia = 1;
          // limite = 365;
        }
        if (elemento.frecuencia == "Semana") {
          diasFrecuencia = 7;
          // limite = 365;
        }
        if (elemento.frecuencia == "Mes") {
          diasFrecuencia = 30;
          // limite = 365;
        }
        if (elemento.frecuencia == "Año") {
          diasFrecuencia = 365;
          // limite = 365 * 5;
        }

        let fechanueva = null;
        for (let itm = 0; itm < limite; itm++) {
          let element = elemento;
          if (itm == 0) fechanueva = this.$moment(element.fecha_inicio);
          else fechanueva = this.$moment(fechanueva).add(diasFrecuencia, "days").format("YYYY-MM-DD");

          let temp = {
            start: fechanueva,
            end: fechanueva,
            title: "R: " + element.titulo,
            descripcion: element.descripcion,
            id: element.id,
            color: element.color,
            estado: element.estado,
            myEventColor: element.color,
            recurrente: element.recurrente,
            frecuencia: element.frecuencia,
          };
          listarecordatorios.push(temp);
          //diasFrecuencia++;
        }
      }

      console.log("listarecordatorios ", listarecordatorios);
      this.calendarOptions.events = this.calendarOptions.events.concat(listarecordatorios);
    },
    async deleteEvent() {
      this.$swal({
        title: "Eliminar evento?",
        text: "Deseas eliminar este evento?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Cancelar",
        confirmButtonText: "Si, Eliminar Evento!",
      }).then(async (result) => {
        if (result.value) {
          let data = {
            eliminado: 1,
          };
          let ruta;
          //let usuario_id = localStorage.getItem("usuarioId");
          ruta = "agenda/" + this.id;
          //let response = await api.request("put", ruta, data);
          let response = await this.$store.dispatch("put", { path: ruta, data: data });

          if (response.error) {
            console.log(response.error);
            this.$swal("Error!", "Intentalo nuevamente!", "error");
            return;
          } else {
            this.$swal("Listo!", "Evento Eliminado!", "success");
            this.$bvModal.hide("bv-modal");
            await this.updateCalendar();

            this.isShow = false;
            this.cKey += 1;
            // await t+his.updateCalendar()
            //return response.data;
          }
        }
      });
    },
    async saveData() {
      console.log("sadevada");
    },
    async obtenerProyectos() {
      try {
        // ruta = "tareasEmpleado/" + this.usuario_id;
        let res = await this.$store.dispatch("get", { path: "proyectos/getAllByUser/" + this.usuario_id });

        // let response = await this.$store.dispatch("get", { path: ruta });
        console.log("lista tareas in func ", res);
        this.lProyectos = await res;
      } catch (error) {
        console.log("error", error);
      }
    },
    async obtenerItemsTableros() {
      try {
        let ruta;
        ruta = "meca_kanban_checklist/getAllBykanbanItemNotfinishId/" + this.usuario_id;
        let response = await this.$store.dispatch("get", { path: ruta });
        console.log("lista tareas in func ", response);
        this.lTareas = await response;
      } catch (error) {
        console.log("error", error);
      }
    },
    async obtenerAgenda() {
      try {
        let ruta;
        ruta = "agenda/" + this.usuario_id;
        let response = await this.$store.dispatch("get", { path: ruta });
        this.lAgenda = await response;
      } catch (error) {
        console.log(error);
      }
    },
    clickEvent(data1) {
      this.cambioColor = false;

      console.log("aqui", data1.event.extendedProps);
      this.botonModal = "Guardar";
      this.tituloModal = "Ver evento / tarea";
      this.tipo = 1;

      //let data = new Date(data1.event._instant.start);
      let f = moment(data1).utc().format("DD-MM-YYYY"); //data.getFullYear() + "/" + this.addZero(data.getMonth() + 1) + "/" + this.addZero(data.getDate());
      //this.$store.commit("formatoFecha", f);
      this.fecha_inicio = moment(data1.event.start).format("YYYY-MM-DD");
      this.fecha_fin = moment(this.addDays(data1.event.end, -1)).format("YYYY-MM-DD");
      this.isShow = true;
      this.$bvModal.show("bv-modal");
      console.log(f);
      let dt = data1.event.title.trim();
      this.tipoEvento = dt.substring(0, 1);
      this.titulo = dt.substring(3, dt.length);
      //alert(this.titulo)
      this.descripcion = data1.event.extendedProps.descripcion == null || data1.event.extendedProps.descripcion == "null" ? "" : data1.event.extendedProps.descripcion;
      this.id = data1.event.id;
      this.estado = data1.event.extendedProps.estado == null || data1.event.extendedProps.estado == "null" ? "Activa" : data1.event.extendedProps.estado;
      this.myEventColor = data1.event.extendedProps.myEventColor;
      this.recurrente = data1.event.extendedProps.recurrente;
      this.frecuencia = data1.event.extendedProps.frecuencia;
    },
    addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    },
    ///////////////////////////////////

    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends; // update a property
    },

    // handleDateSelect(selectInfo) {
    //   let title = prompt("Please enter a new title for your event");
    //   let calendarApi = selectInfo.view.calendar;

    //   calendarApi.unselect(); // clear date selection

    //   if (title) {
    //     calendarApi.addEvent({
    //       id: createEventId(),
    //       title,
    //       start: selectInfo.startStr,
    //       end: selectInfo.endStr,
    //       allDay: selectInfo.allDay,
    //     });
    //   }
    // },

    // handleEventClick(clickInfo) {
    //   if (confirm(`Are you sure you want to delete the event '${clickInfo.event.title}'`)) {
    //     clickInfo.event.remove();
    //   }
    // },

    handleEvents(events) {
      this.currentEvents = events;
    },
  },
};
</script>
<style scoped>
.theme-default .cv-header,
.theme-default .cv-header-day {
  background-color: #000000;
}

.theme-default .cv-header .periodLabel {
  font-size: 1.5em;
}

.theme-default .cv-header button {
  color: #7f7f7f;
}

.theme-default .cv-header button:disabled {
  color: #ccc;
  background-color: #f7f7f7;
}

/* Grid */

.theme-default .cv-day.past {
  background-color: rgba(26, 24, 24, 0.6);
}

.theme-default .cv-day.outsideOfMonth {
  background-color: rgba(26, 24, 24, 0.6);
}

.theme-default .cv-day.today {
  background-color: rgb(250, 250, 153);
  /* background: rgb(66,68,43);
    background: linear-gradient(0deg, rgba(66,68,43,1) 0%, rgba(218,227,32,1) 79%, rgba(244,255,11,1) 100%); */

  /* min-height: 85px !important; */
}

/* Events */

.theme-default .cv-event {
  border-color: #e0e0f0;
  border-radius: 0.5em;
  /* background-color: #e7e7ff; */
  /* background-color: seagreen;  */
  text-overflow: ellipsis;
}

.theme-default .cv-event.purple {
  background-color: #f0e0ff;
  border-color: #e7d7f7;
}

.theme-default .cv-event.orange {
  background-color: #ffe7d0;
  border-color: #f7e0c7;
}

.theme-default .cv-event.continued::before,
.theme-default .cv-event.toBeContinued::after {
  content: " \21e2 ";
  color: #999;
}

.theme-default .cv-event.toBeContinued {
  border-right-style: none;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.theme-default .cv-event.isHovered.hasUrl {
  text-decoration: underline;
}

.theme-default .cv-event.continued {
  border-left-style: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

/* Event Times */

.theme-default .cv-event .startTime,
.theme-default .cv-event .endTime {
  font-weight: bold;
  color: #666;
}

/* Drag and drop */

.theme-default .cv-day.draghover {
  box-shadow: inset 0 0 0.2em 0.2em yellow;
}

.task {
  color: white !important;
  /* background-color: rgb(15, 145, 71) !important; */
  background: rgb(72, 119, 73);
  background: linear-gradient(0deg, rgba(72, 119, 73, 1) 0%, rgba(72, 119, 73, 1) 51%, rgba(125, 199, 124, 1) 100%);
  font-size: 0.9em !important;
}
.finished-work {
  background: rgb(6, 153, 9);
  background: linear-gradient(0deg, rgba(72, 119, 73, 1) 0%, rgba(72, 119, 73, 1) 51%, rgba(38, 219, 41, 1) 100%);
  font-size: 0.9em !important;
  color: white !important;
}
.pending-task {
  background: rgb(219, 38, 38);
  background: linear-gradient(0deg, rgba(136, 23, 23, 1) 0%, rgb(223, 100, 100) 51%, rgb(240, 92, 92) 100%);
  font-size: 0.9em !important;
  color: white !important;
}
.task-in-progress {
  background: rgb(192, 96, 7);
  background: linear-gradient(0deg, rgba(172, 96, 26, 1) 0%, rgba(172, 96, 26, 1) 51%, rgba(240, 132, 32, 1) 100%);
  font-size: 0.9em !important;
  color: white !important;
}

.schedule {
  color: white !important;
  /*background-color: rgb(175, 96, 6) !important; */
  background: rgb(13, 74, 131);
  background: linear-gradient(0deg, rgba(76, 86, 146, 0.9) 0%, rgb(45, 53, 97) 40%, rgb(151, 163, 235) 100%);
  font-size: 0.9em !important;
}
.textinput {
  float: left;
  width: 100%;
  min-height: 75px;
  outline: none;
  resize: none;
  border: 1px solid rgb(237, 237, 237);
}
</style>
