<template>
  <div
    class="sidebar px-4 py-2 py-md-3 bg-sidebar"
    :class="[
      arrowToggle ? 'sidebar-mini' : '',
      isGradient || currentLayoutStyle == 'style-13' ? '' : '',
      currentLayoutStyle == 'style-1' ? '' : '',
      currentLayoutStyle == 'style-2' ? 'sidebar-mini' : '',
      currentLayoutStyle == 'style-3' ? 'rounded no-scrollbar bg-dark my-3' : '',
      currentLayoutStyle == 'style-11' ? 'no-scrollbar' : '',
      currentLayoutStyle == 'style-12' || currentLayoutStyle == 'style-13' ? 'scrollbar-secondary' : '',
      isSidebarToggle ? 'open' : '',
    ]"
    v-if="isSidebarVisible"
  >
    <div class="d-flex flex-column h-100">
      <h4
        class="sidebar-title text-center"
        :class="{
          'mt-2': currentLayoutStyle !== 'style-4' && currentLayoutStyle !== 'style-13',
          'mb-1': currentLayoutStyle !== 'style-3' && currentLayoutStyle !== 'style-4' && currentLayoutStyle !== 'style-13',
          'my-3 mb-2 rounded': currentLayoutStyle == 'style-3',
          'mb-1 pb-3 mt-1 text-center': currentLayoutStyle === 'style-4' || currentLayoutStyle === 'style-13',
        }"
      >
        <img v-if="!arrowToggle" class="img-fluid" style="margin-left: auto !important; margin-right: auto !important; max-height: 90px" src="/images/logo3T.png" alt="" />
        <!-- <span class="">SISCOPER </span> -->
      </h4>
      <form
        :class="{ 'mb-3 mt-0': currentLayoutStyle == 'style-3', 'mb-2 mt-1': currentLayoutStyle != 'style-3' }"
        v-if="currentLayoutStyle !== 'style-4' && currentLayoutStyle !== 'style-13'"
      >
        <div class="input-group">
          <!-- <input type="text" class="form-control border-0" placeholder="Search..." :class="{'form-control-sm':currentLayoutStyle == 'style-3'}"> -->
        </div>
      </form>
      <!-- <div class="mb-lg-5 mb-md-4 mb-2 smt-2 text-center" v-if="currentLayoutStyle === 'style-4' || currentLayoutStyle === 'style-13'">
                <a href="Javascript:void(0);"><img src="../../../../assets/images/profile_av.png" alt="User" class="rounded-circle img-thumbnail w90"></a>
                <h6 class="mb-0 mt-2">Robert Hammer</h6>
                <small class="text-muted">UI UX Designer</small>
                <div class="mt-3">
                    <a class="p-1 mr-2" :class="currentLayoutStyle === 'style-4' ? 'text-primary' : 'text-secondary' " href="javascript:void(0);" title="facebook"><i class="fa fa-facebook"></i></a>
                    <a class="p-1 mr-2" :class="currentLayoutStyle === 'style-4' ? 'text-primary' : 'text-secondary' " href="javascript:void(0);" title="twitter"><i class="fa fa-twitter"></i></a>
                    <a class="p-1 mr-2" :class="currentLayoutStyle === 'style-4' ? 'text-primary' : 'text-secondary' " href="javascript:void(0);" title="instagram"><i class="fa fa-instagram"></i></a>
                </div>
            </div> -->
      <!-- Menu: main ul -->
      <!-- <span class="text-center text-primary" style="font-weight: bold">{{
        (userId.nombres != null ? userId.nombres : "") + " " + (userId.apellidos ? userId.apellidos : "")
      }}</span> -->
      <layout-a v-if="currentLayoutStyle !== 'style-3' && currentLayoutStyle !== 'style-4' && currentLayoutStyle !== 'style-13'"></layout-a>

      <!-- <layout-c v-if="currentLayoutStyle == 'style-3'"></layout-c> -->

      <!-- Menu: menu collepce btn -->
      <button type="button" class="btn btn-link sidebar-mini-btn text-light" v-if="currentLayoutStyle == 'style-1' || currentLayoutStyle == 'style-2'" v-on:click="miniSidebar()">
        <span class="ms-2"><i class="fa fa-arrow-left"></i></span>
      </button>

      <layout-g v-if="currentLayoutStyle === 'style-4' || currentLayoutStyle === 'style-13'"></layout-g>

      <div class="px-3" v-if="currentLayoutStyle === 'style-4' || currentLayoutStyle === 'style-13'">
        <template v-for="menuItem in menuItems">
          <router-link
            :key="menuItem.name"
            :to="`${menuItem.routerLink}`"
            class="py-2 d-flex align-items-center text-muted justify-content-between"
            v-if="!menuItem.isCategory && !menuItem.isApp && menuItem.children.length == 0"
          >
            <span>{{ menuItem.name }}</span>
            <i class="fa fa-long-arrow-right ml-3" v-if="!menuItem.bedge"></i>
            <span class="badge bg-primary rounded-pill ml-auto" v-if="menuItem.bedge">{{ menuItem.bedge }}</span>
          </router-link>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import LayoutAComponent from "@/components/admin/layouts/sidebar/LayoutAComponent.vue";
// import LayoutCComponent from "@/components/admin/layouts/sidebar/LayoutCComponent.vue";
import LayoutGComponent from "@/components/admin/layouts/sidebar/LayoutGComponent.vue";
import menu from "@/components/admin/data/menu.json";
import { EventBus } from "@/js/event-bus.js";

export default {
  name: "SidebarComponent",
  components: {
    "layout-a": LayoutAComponent,
    // "layout-c": LayoutCComponent,
    "layout-g": LayoutGComponent,
  },
  data() {
    return {
      arrowToggle: false,
      isSidebarToggle: false,
      isGradient: true,
      currentLayoutStyle: "style-1",
      menuItems: menu.menu,
      currentLayout: "layout-a",
      userId: {},
    };
  },
  methods: {
    miniSidebar() {
      this.arrowToggle = !this.arrowToggle;
    },
  },
  computed: {
    currentRoutePath() {
      return this.$route.path;
    },
    isSidebarVisible() {
      return this.currentLayout !== "layout-d" && this.currentLayout !== "layout-d-sub-header" && this.currentLayout !== "layout-f";
    },
  },
  mounted() {
    this.userId = JSON.parse(localStorage.getItem("user"));

    EventBus.$on("Gradient", (Gradient) => {
      this.isGradient = Gradient;
    }),
      EventBus.$on("activeLayout", (activeLayout) => {
        this.currentLayout = activeLayout.currentLayout;
        this.currentLayoutStyle = activeLayout.currentLayoutStyle;
      }),
      EventBus.$on("isSidebarToggle", (isSidebarToggle) => {
        this.isSidebarToggle = isSidebarToggle;
      });
    EventBus.$on("defaultLayoutSToggle", (defaultLayoutSToggle) => {
      this.isSidebarToggle = defaultLayoutSToggle;
    });
    EventBus.$on("isToogle", (value) => {
      this.arrowToggle = value;
    });
  },
  beforeDestroy() {
    EventBus.$off("Gradient");
    EventBus.$off("activeLayout");
    EventBus.$off("isSidebarToggle");
    EventBus.$off("defaultLayoutSToggle");
  },
};
</script>
<style scoped>
.bg-sidebar {
  z-index: 5;
  color: #74000000;
  /* border-color: var(--border-color); */
  /* background-color: rgba(8, 137, 243, 0.979)255, 255, 255); */

  /* background: url('/images/waterfall2.jpg') !important;  */
  /* filter: opacity(0.5) !important; */
}
.bg-sidebar::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--primary-gradient);
  /* background: linear-gradient(0deg, var(--primary-color)) 0%, rgba(33,70,255,1) 100%);; */
  /* background: url("/images/sidebar-2.png") !important; */
  
  /* background-color: rgba(8, 137, 243, 0.979)255, 255, 255); */
  /* background-color: rgba(8, 137, 243, 0.979); */
  /* opacity: 0.2; */
  /* filter: blur(5px); */
}
</style>
