<template>
  <div :id="`${currentLayout}`" :class="theme">
    <!-- Navigation -->
    <NavigationComponent v-if="!['login', 'register', 'fpassword', 'verification', '404', 'asia'].includes($route.name) && usuario.mapa.split(',')[39] == 1" />
    <!-- header -->
    <!-- <HeaderComponent v-show="currentLayout != 'layout-h'" v-if="!['login', 'register', 'fpassword', 'verification', '404', 'asia'].includes($route.name)" /> -->

    <!-- sidebar -->
    <SidebarComponent v-if="!['login', 'register', 'fpassword', 'verification', '404', 'asia'].includes($route.name)" />
    <component :is="layout">
      <router-view></router-view>
    </component>
  </div>
</template>
<script>
import { EventBus } from "@/js/event-bus.js";
import { eventBus } from "./main";

import NavigationComponent from "@/components/admin/layouts/NavigationComponent.vue";
import SidebarComponent from "@/components/admin/layouts/sidebar/SidebarComponent.vue";
import HeaderComponent from "@/components/admin/layouts/header/HeaderComponent.vue";
const default_layout = "default";

export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
  components: {
    NavigationComponent,
    SidebarComponent,
    HeaderComponent,
  },
  data() {
    return {
      currentLayoutStyle: "style-1",
      currentLayout: "layout-a",
      theme: "theme-blue",
      usuario: {
        mapa: [0, 0],
      },
    };
  },
  created() {
    this.usuario = JSON.parse(localStorage.getItem("user")) || { mapa: [0, 0] };
    eventBus.$on("updatemap", () => {
      //console.log("************************/***********************", data);
      //console.log("data en eventbus", data);
      this.usuario = JSON.parse(localStorage.getItem("user"));
      this.$forceUpdate();
    });
  },
  mounted() {
    this.$store.watch(
      (state) => [state.theme].join(),
      () => {
        let dataTheme = JSON.parse(localStorage.getItem("themecompany")) || {
          themecolor: null,
          darkMode: null,
        };
        this.theme = dataTheme.themecolor;
        //this.scrollFix(this.$store.state.ancla);
      }
    );

    let dataTheme = JSON.parse(localStorage.getItem("themecompany")) || {
      themecolor: null,
      darkMode: null,
    };
    const html = document.documentElement; // returns the html tag
    if (dataTheme.themecolor != null) {
      this.theme = dataTheme.themecolor;
    }

    if (dataTheme.darkMode == true || dataTheme.darkMode == 1) {
      html?.setAttribute("data-theme", "dark");
    } else {
      html?.removeAttribute("data-theme");
    }
    // alert("app " + dataTheme.themecolor);
    EventBus.$on("activeLayout", (activeLayout) => {
      this.currentLayout = activeLayout.currentLayout;
      this.currentLayoutStyle = activeLayout.currentLayoutStyle;
    });
  },
  beforeDestroy() {
    EventBus.$off("activeLayout");
  },
};
</script>
<style lang="scss">
// Bootstrap 5.0.0-beta1
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "node_modules/bootstrap-vue/src/index.scss";
@import "../src/assets/scss/main.scss";

.zoom {
  transition: all 300ms;
  cursor: pointer;
}

.zoom:hover {
  transform: scale(1.05);
}

.btn-orange {
  background-color: rgb(255, 155, 118);
  color: white;
}
.btn-orange:hover {
  background-color: rgb(242, 104, 54);
  color: white;
}
</style>
